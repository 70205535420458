import * as React from 'react';
// Material
import { Typography } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


function findMeasures(fields, mapping) {
  let result=[];
  for (let col=0; col < fields.length; col++) {
    if (mapping[col] >=0 ) {
      result.push(fields[col].name);
    }
  }
  return result;
}


// Calculate the maximum for each row
function maximumOfRows(matrix) {
  let result=[];
  let n=matrix.length;
  for (let row=0; row < n; row++) {
    let max=0.0;
    let mrow=matrix[row];
    for (let col=0; col < n; col++) {
      if (row !== col) {
        let value=mrow[col];
        if (isFinite(value)) {
          max=Math.max(max, Math.abs(value));  
        }
      }
    }
    result.push(max);
  }
  return result;
}

// Calculate the maximum for each row
function sumAbsOfRows(matrix) {
  let result=[];
  let n=matrix.length;
  for (let row=0; row < n; row++) {
    let total=0.0;
    let mrow=matrix[row];
    for (let col=0; col < n; col++) {
      if (row !== col) {
        let value=mrow[col];
        if (isFinite(value)) {
          total+= Math.abs(value);  
        }
      }
    }
    result.push(total);
  }
  return result;
}


function selectMeasures(measureNames, showColumns, matrix, viewAll) {
  let measures=[];
  //let rowMax=maximumOfRows(matrix);
  let rowMax=sumAbsOfRows(matrix);
  for (let i=0; i<measureNames.length; i++) {
    const name = measureNames[i];
    if (viewAll || showColumns.has(name)) {
      measures.push({name: measureNames[i], value: rowMax[i]});
    }
  }
  measures.sort((x,y) => y.value-x.value);
  const top = viewAll ? measures.slice(0,300) : measures.slice(0, 7);
  const selection = new Set(top.map((m) => m.name));

  let result = [];
  for (let name of measureNames) {
    result.push(selection.has(name));
  }
  return result;
}


function CorrelationHeader(props) {
  const measures=props.measures;
  const show=props.show;
  let selection=measures.filter((x,index) => show[index]);
  let result = selection.map(name => <TableCell key={name} align="right">{name}</TableCell>);
  return result;
}

function CorrelationData(props) {
  const measureNames=props.measures;
  const matrix=props.matrix;
  const show=props.show;
  const onClickHandler=props.onClick;

  let result=[];
  for (let row=0; row < measureNames.length; row++) {
    if (show[row]) {
      result.push(
        <TableRow key={measureNames[row]}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell key="title" align="right" variant='head'>{measureNames[row]}</TableCell>
          <CorrelationRow 
            key={row} 
            row={matrix[row]}
            show={show} 
            rowIndex={row}
            labels={measureNames}
            onClick={onClickHandler}
            />
        </TableRow>
      );        
    }
  }
  return result;
}


function CorrelationRow(props) {
  const row=props.row;
  const rowIndex=props.rowIndex;
  const show=props.show;
  const labels=props.labels;
  const onClickHandler=props.onClick;

  var locale = 'en';
  var options = {style: 'decimal', maximumFractionDigits: 2, useGrouping:false};
  var f = new Intl.NumberFormat(locale, options);

  let result=[];
  for (let col=0; col<row.length; col++) {
    if (show[col]) {
      let x=row[col];
      let c = Math.abs(x)>0.5 ? (x>0 ? "green" : "red") : (Math.abs(x)>0.2 ? "gray" : "lightgray");
      let w = Math.abs(x) > 0.5 ? "bold" : "normal";
      if (col===rowIndex) {
        c="lightgray";
        w="normal";
      }
      result.push(
        <TableCell 
          key={col} 
          sx={{ color: c, fontWeight: w, cursor: 'pointer' }}
          align="right" 
          onClick={() => onClickHandler(labels[rowIndex], labels[col])}>
          {isFinite(x) ? f.format(x) : "--"}
        </TableCell>
      );  
    }
  }
  return result;
}


export default class CorrelationMatrix extends React.Component {

  handleViewAll = (event) => {
    console.log("viewAll:"+event.target.checked);
    this.setState({"viewAll": event.target.checked});
  };

  renderCorrelationToolbar() {
    return(
      <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 }
      }}
    >
      <Typography component="h2" variant="h6" color="primary"
        sx={{ flex: '1 1 100%' }}
        id="correlationTableTitle"
      >
        Correlation Matrix
      </Typography>
      <FormGroup>
        <FormControlLabel 
          control={<Checkbox checked={this.state.viewAll} onClick={this.handleViewAll} />} 
          label="Show&nbsp;All" />
      </FormGroup>
    </Toolbar>
    );
  }


  render() {
    const md=this.props.metadata;
    const onClickHandler=this.props.onClick;
    const viewAll=this.props.viewAll;
    const showColumns=this.props.showColumns;

    if (md != null) {
      const correlation=md.correlation;
      const matrix=correlation.matrix;
      const measureNames=findMeasures(md.fields, correlation.mapping);
      //const show=topCorrelations(matrix, viewAll);
      const show=selectMeasures(measureNames, showColumns, matrix, viewAll);
      //console.log(show);
  
      return(
        <div>
          <TableContainer sx={{ pt: 1, maxHeight: 440 }}>
            <Table sx={{ minWidth: 650 }} aria-label="Correlation" stickyHeader={true} size='small'	>
              <TableHead>
                <TableRow key="tableHeader">
                  <TableCell variant='head'>Column</TableCell>
                  <CorrelationHeader measures={measureNames} show={show} />
                </TableRow>
              </TableHead>
              <TableBody>
                <CorrelationData
                  measures={measureNames} 
                  matrix={matrix} 
                  show={show} 
                  onClick={onClickHandler}/>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    }
  }
}