import * as React from 'react';
// mui components
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
// rechart
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
// Application components
import config from '../Configuration';
import Condition from '../model/Condition';
import BackTitle from '../components/BackTitle';
import ChartDialog from '../components/ChartDialog';
import { scaleDomain, magnitude, abbrevMag } from '../Utils';


function AggregationSmall(props) {
  const measure=props.measure;
  const label=props.column;
  const clickHandler=props.onClick;
  const fnField=props.fnField;
  const n = Math.min(props.data.length, 20);
  const data=props.data.slice(0,n)
    .map(x => ({
      l: x.l,
      [measure]: x[fnField]}))
      .sort((a,b) => b[measure]-a[measure]);
  const values=data.map(f => f[measure]);

  const domain=scaleDomain(values);
  const extreme=Math.max(Math.abs(domain[0]), Math.abs(domain[1]))
  const digits = (extreme > 1000) ? 0 : 3;

  const options = {style: 'decimal', maximumFractionDigits: digits, useGrouping:true};
  const locale = 'en';
  const formatDouble = new Intl.NumberFormat(locale, options);
  const mag=magnitude(extreme);
  const unit=abbrevMag(mag);

  return(
    <ResponsiveContainer width="100%" height={200}>
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        onClick={() => clickHandler(label)}>
        <XAxis dataKey="l" type="category" tick={false} />
        <YAxis type="number" domain={domain} tickFormatter={(x) => (x/mag)+unit} />
        <Tooltip
          labelFormatter={(l) => (label+" : "+l)}
          formatter={(value) => formatDouble.format(value)}/>
        <Bar dataKey={measure} fill={config.getChartColor()} />
      </BarChart>
    </ResponsiveContainer>
  );
}


function AggregationBig(props) {
  const measure=props.measure;
  const label=props.column;
  const clickHandler=props.onClick;
  const fnField=props.fnField;
  const data=props.data
    .map(x => ({
      l: x.l,
      [measure]: x[fnField]}))
    .sort((a,b) => b[measure]-a[measure]);
  const values=data.map(f => f[measure]);

  const domain=scaleDomain(values);
  const extreme=Math.max(Math.abs(domain[0]), Math.abs(domain[1]))
  const digits = (extreme > 1000) ? 0 : 3;

  const options = {style: 'decimal', maximumFractionDigits: digits, useGrouping:true};
  const locale = 'en';
  const formatDouble = new Intl.NumberFormat(locale, options);
  const mag=magnitude(extreme);
  const unit=abbrevMag(mag);

  return(
    <div>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5}}
          isAnimationActive={true}
          onClick={clickHandler}>
          <XAxis dataKey="l" type="category"  />
          <YAxis type="number" domain={domain} tickFormatter={(x) => (x/mag)+unit}  />
          <Tooltip
            labelFormatter={(l) => (label+" : "+l)} 
            formatter={(value) => formatDouble.format(value)}/>
          <Bar dataKey={measure} fill={config.getChartColor()} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}


export default class CategoryAggregation extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      "graph": null,
      "column": null,
    }
  }

  handleSelectColumn = (selected) => {
    this.setState({
      "column": selected
    });
  }

  handleCloseSingle = () => {
    this.setState({
      "graph": null,
      "column": null
    });
  };

  handleOpenFilterMenu = (graph) => {
    if (this.props.filter) {
      this.setState({
        "graph": graph 
      });
    }
  };

  handleCloseFilterMenu = () => {
    this.setState({
      "graph": null
    });
  };

  addHandler = (graph) => {
    const label=graph.activeLabel;
    const labels = [ label ];
    const column=this.state.column;
    const filter=this.props.filter.copy();

    const condition = new Condition(column, "eq", labels);
    filter.addConditon(condition);
    this.props.onUpdateFilter(filter);
    this.setState({
      "graph": null
    });
  }

  renderGridCells(aggreations, fnField) {
    return aggreations.map( (column) => {
      const label = column.groupBy;
      return(
        <Grid item xs={3} key={column.measure+"-"+column.groupBy}>
          <div style={{ width: '100%' }}>
            <Typography align="center"><b>{label}</b></Typography>
            <AggregationSmall 
              measure={column.measure} 
              column={column.groupBy}
              data={column.totals}
              fnField={fnField}
              onClick={this.handleSelectColumn}/>
          </div>
        </Grid>
      );
    });
  }

  renderSingleChart(column, fnField, filter) {
    return(
      <Grid container spacing={2}>
        <Grid item xs={9} key={column.measure+"-"+column.groupBy}>
          <BackTitle onClick={this.handleCloseSingle}>
            {column.measure} grouped by {column.groupBy}
          </BackTitle>
          <AggregationBig 
            measure={column.measure}
            column={column.groupBy} 
            data={column.totals}
            fnField={fnField}
            onClick={this.handleOpenFilterMenu}/>
          <ChartDialog
            graph={this.state.graph}
            filter={filter}
            onClose={this.handleCloseFilterMenu}
            onAddFilter={this.addHandler}/>
        </Grid>
        <Grid item xs={3} key={"Info"}>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <ListItem>
              <ListItemText primary={column.measure} secondary="Y-Axis" />
            </ListItem>
            <ListItem>
              <ListItemText primary={column.groupBy} secondary="X-Axis" />
            </ListItem>
          </List>
        </Grid>
      </Grid>    
    );
  }


  render() {
    const filter=this.props.filter;
    const fnField=this.props.fnField;
    const aggregations=this.props.aggregations
      .filter((field) => field.type==='String')
      .sort((a,b) => (a.groupBy > b.groupBy ? 1 :(a.groupBy < b.groupBy ? -1 :0)));
    const selected=this.state.column;
    if (selected===null) {
      return (
        <Grid container spacing={2}>
          {this.renderGridCells(aggregations, fnField)}
        </Grid>  
      );
    } else {
      const columns=aggregations.filter(field => (field.groupBy===selected));
      if (columns.length >= 1) {
        const column=columns[0];
        return this.renderSingleChart(column, fnField, filter);  
      } else {
        return null;
      }
    }
  }
}