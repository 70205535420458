import * as React from 'react';
// mui components
// rechart
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// Application
//import { scalePercent } from '../Utils';
import { scaleDomain, magnitude, abbrevMag, maxRow } from '../Utils';

//const colors=["#99ccff","#8884d8","#82ca9d","#ffc658"];
const colors=["#b30000", "#7c1158", "#4421af", "#1a53ff", "#0d88e6", "#00b7c7", "#5ad45a", "#8be04e", "#ebdc78"];


function sortedValues(labels) {
  const type=labels.dataType;
  const values=labels.values.slice();
  if (type==="String") {
    console.log("String Sort");
    values.sort((a,b) => a.name.localeCompare(b.name));
  } else if (type==="Integer") {
    console.log("Integer Sort");
    values.sort((a,b) => (a.value-b.value));
  } else {
    console.log("Not sorting - unknown datatype: "+type);
  }
  return values;
}

function createChartData(rowLabels, colLabels, values) {
  const n=rowLabels.length;
  const data=rowLabels.slice(0, n).map((rowLabel) => { 
    const row = values[rowLabel.index];
    let result= { l: rowLabel.name };
    for (let i=0; i<colLabels.length; i++) {
      const colLabel=colLabels[i];
      const col=colLabel.index;
      result[colLabel.name] = row[col];
    }
    return result;
  });
  return data;
}

export default function PivotLineChart(props) {
  console.log("Rendering Line Bar Chart");
  const pivot = props.data;
  if (pivot !== null) {
    const rowName=pivot.rowLabels.name;
    //const colName=pivot.columnLabels.name;
    const colLabels = sortedValues(pivot.columnLabels);
    const rowLabels = sortedValues(pivot.rowLabels);
    const values = pivot.values;    
    const data=createChartData(rowLabels, colLabels, values);

    const rowTotals=maxRow(values);
    const domain=scaleDomain(rowTotals);
    const extreme=Math.max(Math.abs(domain[0]), Math.abs(domain[1]))
    const digits = (extreme > 1000) ? 0 : 3;
    const options = {style: 'decimal', maximumFractionDigits: digits, useGrouping:true};
    const locale = 'en';
    const formatDouble = new Intl.NumberFormat(locale, options);
    const mag=magnitude(extreme);
    const unit=abbrevMag(mag);
  
    return(
      <ResponsiveContainer width="100%" height={480}>
        <LineChart  
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          isAnimationActive={true}>
          <XAxis dataKey="l" type="category" />
          <YAxis type="number" domain={domain} tickFormatter={(x) => (x/mag)+unit} />
          {colLabels.map( (label, index) => {
            const color=colors[index % colors.length];
            return <Line key={index} type="linear" dataKey={label.name} stroke={color} />;
          })}
          <Tooltip
            labelFormatter={(l) => (rowName+": "+l)}
            formatter={(value) => formatDouble.format(value)}/>
          <Tooltip />
          <Legend />
        </LineChart>
      </ResponsiveContainer>
    );  
  } else {
    return null;
  }
}
