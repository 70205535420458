import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';



export default class ImportDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state={ 
      "error": null,
      "model": "ACCESS-CM2",
      "scenario": "ssp585",
      "variable": "tas",
      "decade": 2030,
      "source": "cmip6"
    }
  }

  variables(model, source) {
    if (model==="CESM2" && source==="gddp") {
      return([
        {value: "pr", label: "Precipitation [mm/day]" },
        {value: "tas", label: "Near-Surface Air Temperature [C]" }
      ]);  
    }
    else {
      return([
        {value: "pr", label: "Precipitation [mm/day]" },
        {value: "tas", label: "Near-Surface Air Temperature [C]" },
        {value: "tasmin", label: "Daily Minimum Near-Surface Air Temperature [C]" },
        {value: "tasmax", label: "Daily Maximum Near-Surface Air Temperature [C]" }
      ]);  
    }
  }

  decades() {
    return([
      {value: "2005", label: "2005-2015" },
      {value: "2020", label: "2020-2029" },
      {value: "2030", label: "2030-2039" },
      {value: "2040", label: "2040-2049" },
      {value: "2050", label: "2050-2059" },
      {value: "2060", label: "2060-2069" },
      {value: "2070", label: "2070-2079" },
      {value: "2080", label: "2080-2089" },
      {value: "2090", label: "2090-2099" }
    ]);
  }

  scenarios() {
    return([
      {value: "historical", label: "Historical Data" },
      {value: "ssp245", label: "Middle of the road 2-4.5" },
      {value: "ssp585", label: "Fossil fuel development 5-8.5" }
    ])
  }

  models(source) {
    if (source === "gddp") {
      return([
        {value: "ACCESS-CM2", label: "ACCESS-CM2 - Commonwealth Scientific and Industrial Research Organisation, Australia", en: true},
      ]);
    } else {
      return([
        {value: "ACCESS-CM2", label: "ACCESS-CM2 - Commonwealth Scientific and Industrial Research Organisation, Australia", en: true},
        {value: "CanESM5", label: "CanESM5 - The Canadian Earth System Model", en: false},
        {value: "CESM2", label: "CESM2 - National Center for Atmospheric Research, USA", en: true},
        {value: "CNRM-CM6-1", label: "CNRM-CM6-1 - National Center for Meteorological Research, Météo-France", en: false},
        {value: "GFDL-ESM4", label: "GFDL-ESM4 - National Oceanic and Atmospheric Administration, GFDL, Princeton", en: false},
        {value: "MIROC6", label: "MIROC6 - Japan Agency for Marine‐Earth Science and Technology et al.", en: true},
        {value: "NorESM2-MM", label: "NorESM2-MM - NorESM Climate modeling Consortium, Norway", en: false}
      ]);       
    }
  }

  handleSubmit= () => {
    console.log("Import Dialog handleSubmit called")
    let start = this.state.decade;
    let stop = this.state.decade+9;
    let gddp = this.state.source === "gddp"
    if (this.validate()) {
      const query = {
        model: this.state.model,
        scenario: this.state.scenario,
        variable: this.state.variable,
        start: start,
        stop: stop,
        gddp: gddp
      }
      this.props.onSubmit(query);  
    }
  };

  handleCancel= () => {
    this.props.onSubmit(null);
  };

  handleVariableSelect = (event) => {
    const value=event.target.value;
      this.setState({
        "variable": value
      })  
  };

  handleSourceSelect = (event) => {
    const value=event.target.value;
    const model= (value==="gddp") ? "ACCESS-CM2" : this.state.model;
    this.setState({
      "source": value,
      "model": model
    })  
  };

  handleModelSelect = (event) => {
    const value=event.target.value;
    const variable = (value==="CESM2" ) ? "tas" : this.state.variable;
    this.setState({
      "model": value,
      "variable": variable
    })  
  };

  handleScenarioSelect = (event) => {
    const scenario=event.target.value;
    let decade = this.state.decade;
    if (scenario==="historical") {
      decade=2005;
    } else if (decade===2005) {
      decade=2030;
    }
    this.setState({
      "scenario": scenario,
      "decade": decade
    })  
  };

  handleDecadeSelect = (event) => {
    const value=event.target.value;
    const decade=parseInt(value, 10);
    let scenario = this.state.scenario;
    if  (decade===2005) {
      scenario =  "historical";
    } else if (scenario==="historical") {
      scenario = "ssp585";
    } 
    if (scenario)
      this.setState({
        "scenario": scenario,
        "decade": decade
      })  
  };

  validate() {
    return true;
  }

  render() {
    const permissons=this.props.permissions;
    const open=this.props.open;
    const decade=this.state.decade;
    const gddp=permissons.has("gddp");
    console.log("GDDP Permission:" + gddp);
    return (
      <Dialog open={open} onClose={this.handleCancel}>
        <DialogTitle>Import Climate</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ m: 1 }}>
            Aggregate and import CMIP6 climate data
          </DialogContentText>
          <FormControl sx={{ m: 1 }} variant="standard">
            <InputLabel id="variable">Data Source</InputLabel>
            <Select 
              labelId="variable-label"
              id="variable"
              label="variable"
              value={this.state.source}
              variant="standard"
              sx={{ width: '50ch' }}
              onChange={this.handleSourceSelect}>
              <MenuItem key="cmip6" value="cmip6">Original CMIP6 data</MenuItem>
              <MenuItem key="gddp" value="gddp" disabled={!gddp}>Global Daily Downscaled Projections (NEX-GDDP-CMIP6)</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1 }} variant="standard">
            <InputLabel id="variable">Variable</InputLabel>
            <Select 
              labelId="variable-label"
              id="variable"
              label="variable"
              value={this.state.variable}
              variant="standard"
              sx={{ width: '50ch' }}
              onChange={this.handleVariableSelect}
            >
              {this.variables(this.state.model, this.state.source).map((item) =>
                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1 }} variant="standard">
            <InputLabel id="decade">Decade</InputLabel>
            <Select 
              labelId="decade-label"
              disabled={this.state.scenario==="historical"}
              id="decade"
              label="decade"
              value={decade}
              variant="standard"
              sx={{ width: '20ch' }}
              onChange={this.handleDecadeSelect}
            >
              {this.decades().map((item) =>
                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1 }} variant="standard">
            <InputLabel id="scenario">Scenario</InputLabel>
            <Select 
              labelId="scenario-label"
              id="scenario"
              label="Scenario"
              value={this.state.scenario}
              variant="standard"
              sx={{ width: '50ch' }}
              onChange={this.handleScenarioSelect}
            >
              {this.scenarios().map((item) =>
                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1 }} variant="standard">
            <InputLabel id="model">Model</InputLabel>
            <Select 
              labelId="model-label"
              id="model"
              label="model"
              value={this.state.model}
              variant="standard"
              sx={{ width: '50ch' }}
              onChange={this.handleModelSelect}
            >
              {this.models(this.state.source).map((item) =>
                <MenuItem key={item.value} value={item.value} disabled={!item.en}>{item.label}</MenuItem>
              )}
            </Select>
          </FormControl>

        </DialogContent>

        <DialogActions>
          <Button onClick={this.handleCancel}>Cancel</Button>
          <Button onClick={this.handleSubmit} disabled={!this.validate()} >Import</Button>
        </DialogActions>
      </Dialog>
    );  
  }
}
