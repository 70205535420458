import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export default class FilterDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state={ 
      "error": null,
      "dataset": "",
    }
  }

  handleDataset = (event) => {
    const name=event.target.value;
    this.setState({
      "dataset": name
    })  
  };

  handleCancel= () => {
    this.props.onSubmit(null);
  };

  handleSubmit= () => {
    this.props.onSubmit(this.state.dataset);
  };

  render() {
    const open=this.props.open;
    return(
      <Dialog open={open} onClose={this.handleCancel}>
        <DialogTitle>Save View</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Save the selected columns of the filtered view
          </DialogContentText>
          <TextField sx={{ m: 1, width: '25ch' }}
            autoFocus
            required
            fullWidth
            error={this.state.dataset===""}
            margin="dense"
            id="name"
            label="Dataset Name"
            variant="standard"
            onChange={this.handleDataset}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel}>Cancel</Button>
          <Button onClick={this.handleSubmit}>Save</Button>
        </DialogActions>
      </Dialog>
    );
  }
}