import * as React from 'react';
// mui components
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
// Application components
//import Stats from '../model/Stats';
import config from '../Configuration';
import ScatterPlotView from './ScatterPlotView';
// rechart
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';


function ScatterPlot(props) {
  const xAxis=props.xAxis;
  const yAxis=props.yAxis;
  const data=props.data;
  const cor=props.correlation;
  const clickHandler=props.onClick;

  let col="lightgray";
  let xAxisTitle=xAxis
  if (isFinite(cor) && xAxis!==yAxis) {
    col = Math.abs(cor)>0.5 ? (cor>0 ? config.getChartColor() : "red") : (Math.abs(cor)>0.2 ? "gray" : "lightgray");
    xAxisTitle=xAxis+" - cor "+cor.toFixed(2);
  }

  return(
    <ResponsiveContainer width="100%" height={200}>
      <ScatterChart
        isAnimationActive={false}
        margin={{ top: 20, right: 20, bottom: 10, left: 10 }}
        onClick={() => clickHandler(xAxis)}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="x" type="number"  name={xAxis} 
          label={xAxisTitle} tick={false} 
          domain={['dataMin', 'dataMax']} />
        <YAxis dataKey="y" type="number" name={yAxis}
          label={{ value: yAxis, angle: -90, position: 'center' }}
          tick={false} 
          domain={['dataMin', 'dataMax']} />
        <Tooltip cursor={{ strokeDasharray: '3 3' }} />
        <Scatter name={xAxis} data={data} fill={col} />
      </ScatterChart>
    </ResponsiveContainer>
  );
}


export default class ScatterPlotSamples extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      "column": null,
    }
  }

  componentDidMount() {
    console.log("ScatterPlotSamples did mount");
  }


  chartCloseHandler= () => {
    this.setState({
      "column": null,
    });
  }


  renderGridCells(plots, stats, viewAll) {
    const selected=plots.filter((plot) => {
      const cor=stats.getCorrelation(plot.xAxis, plot.yAxis);
      return viewAll || (isFinite(cor) && (Math.abs(cor) > 0.2) && (plot.xAxis !== plot.yAxis));
    }).sort((plot1, plot2)=> {
        const cor1 = stats.getCorrelation(plot1.xAxis, plot1.yAxis);
        const cor2 = stats.getCorrelation(plot2.xAxis, plot2.yAxis);
        return (Math.abs(cor2) - Math.abs(cor1));
    });
    if (selected.length > 0) {
      return selected.map( (plot) => {
        const cor = stats.getCorrelation(plot.xAxis, plot.yAxis);
        if (viewAll || (isFinite(cor) && (Math.abs(cor) > 0.2) && (plot.xAxis !== plot.yAxis))) {
          return(
            <Grid item xs={3} key={plot.xAxis+"-"+plot.yAxis}>
              <ScatterPlot 
                xAxis={plot.xAxis} 
                yAxis={plot.yAxis}
                data={plot.data}
                correlation={cor}
                viewAll={viewAll}
                onClick={this.props.onClick}
              />
            </Grid>
          );
        } else {
          return null;
        }   
      });  
    } else {
      const message= viewAll 
        ? "No scaterplot found!"
        :  "No scatterplot with correlation above the threshold found. You may want to check 'Show All'.";
      return(
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column'}}>
          <Alert severity="info">
            <AlertTitle>Information</AlertTitle>
            {message}
          </Alert>
        </Paper>
      )
    }
  }


  render() {
    console.log("Rendering ScatterplotSamples");
    const stats=this.props.stats;
    const data=this.props.data;
    const showColumns=this.props.showColumns;
    const viewAll=this.props.viewAll;
    const selected=this.state.column;

    if (stats.valid()) {
      if (selected===null) {
        const plots=data.plots.filter(plot => (showColumns.has(plot.xAxis)));
        return (
          <Grid container spacing={2}>
            {this.renderGridCells(plots, stats, viewAll)}
          </Grid>  
        );
      } else {
        const plots=data.plots.filter(plot => (plot.xAxis===selected));
        if (plots.length >= 1) {
          const plot=plots[0];
          //return this.renderSingleChart(plot, stats, viewAll);
          return(<ScatterPlotView 
            stats={stats}
            plot={plot} 
            xAxis={selected} 
            filter={this.props.filter}
            onUpdateFilter={this.props.onUpdateFilter}  
            onClose={this.chartCloseHandler}/>
          );
        } else {
          console.log("Column not found");
          return null;
        }
      }
  
    } else {
      console.log("md or data is null")
      return null;
    }
  }
}