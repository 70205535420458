import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

// Icons
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

// Application components
import Condition from '../model/Condition';
import SelectColumn from './SelectColumn';
import SelectOperator from './SelectOperator';
import ValueField from './ValueField';
import { listValues } from '../model/Columns';


function findColumn(name, columns) {
  const columnMatch = columns.filter((field) => (field.name === name));
  return (columnMatch.length === 1) ? columnMatch[0] : null;
}


export default class FilterDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      "conditions": []
    }
  }

  componentDidMount() {
    const conditions=this.props.conditions;
    this.setState({
      "conditions": conditions
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const conditions=this.props.conditions;
    if (conditions !== prevProps.conditions) {
      console.log("Updating state");
      this.setState({
        "conditions": conditions.slice()
      });  
    }
  }
 
  handleClose = () => {
    this.props.onSubmit(this.state.conditions);
  };
 
  handleCancel = () => {
    this.props.onCancel();
  };

  handleAdd = () => {
    let conditions=this.state.conditions.slice();
    conditions.push(new Condition("", "contains", []))
    this.setState({
      "conditions": conditions
    });
  }

  validate(conditions) {
    let count=0;
    for (let i=0; i<conditions.length; i++) {
      const condition=conditions[i];
      if (!condition.valid()) {
        count++;
      }
    }
    return count===0;
  }


  handleValueChange(index, value) {
    console.log("handle valueChange called: "+index+" value="+JSON.stringify(value));
    // Value maybe single value or array - convert into array
    const values = Array.isArray(value) ? value : [value];

    let conditions=this.state.conditions.slice();
    const cond=conditions[index].copy();
    cond.setValues(values);
    conditions[index] = cond;
    this.setState({
      "conditions": conditions
    });
  }


  handleFieldChange(index, columnName, columns) {
    console.log("handleFieldChange called: "+index+" - "+columnName);
    let conditions=this.state.conditions.slice();
    let cond=conditions[index];
    // Default operator depending on column selected
    let op = cond.op;
    let dataType="String";
    if (columnName === "") {
      op = "contains";
    } else {
      const column=findColumn(columnName, columns);
      dataType=column.dataType;
      const values=listValues(column);
      op = (values !== null) ? "eq" : cond.getOp();
    }
    let update = new Condition(columnName, op, []);
    update.setDataType(dataType);
    conditions[index] = update;
    this.setState({
      "conditions": conditions
    });
  }


  handleOperationChange(index, operation, columns) {
    console.log("handleOperationChange called: " + index + " - " + operation);
    let conditions = this.state.conditions.slice();
    const cond = conditions[index];
    // Default value depending on column selected
    const field=cond.getColumn();
    let values =  cond.getValues();

    if  (operation === "null" || operation === "not_null") {
      // Remove values
      values = [];
    } else if (operation === "eq") {
      // If LOV remove value
      const column=findColumn(field, columns);
      const lov=listValues(column);
      if (lov !== null) {
        values=[];
      }  
    }
    let update = cond.copy();
    update.setOp(operation);
    update.setValues(values);
    conditions[index] = update;
    this.setState({
      "conditions": conditions
    });
  }


  handleClear(selected) {
    console.log("handleClear called: "+selected);
    let conditions=this.state.conditions.filter((val, index) => index !== selected);
    this.setState({
      "conditions": conditions
    });
  }


  renderGrid(conditions, columns) {
    const columnNames=columns.map(field => field.name).sort();
    return(
      <Box sx={{ flexGrow: 1}}>
        <Grid container spacing={2} sx={{mb: 4}}>
          {conditions.map((condition, index) => {
            //console.log(JSON.stringify(condition));
            const columnName=condition.getColumn();
            const operator=condition.getOp();
            const opError = condition.validOperator() ? null : "Relation not supported for column";
            const column = findColumn(columnName,columns);

            return(
              <React.Fragment key={"frag"+index}>
                <Grid item xs={3} key={"col"+index}>
                  <SelectColumn
                    columns={columnNames}
                    value={columnName} 
                    onChange={(col) => this.handleFieldChange(index, col, columns)}/>
                </Grid>
                <Grid item xs={2} key={"op"+index}>
                  <SelectOperator
                    value={operator}
                    error={opError}
                    onChange={(operation) => this.handleOperationChange(index, operation, columns)}/>
                </Grid>
                <Grid item xs={6} key={"val"+index}>
                  <ValueField
                    condition={condition}
                    column={column}
                    onChange={(value) => this.handleValueChange(index, value)}/>
                </Grid>
                <Grid item xs={1} key={"action"+index}>
                  <IconButton 
                    sx= {{m: 2 }}
                    aria-label="clear-condition"
                    label="Delete"
                    size="small"
                    onClick={()=> this.handleClear(index)}>
                    <DeleteIcon/>
                  </IconButton>
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
        <Button
            sx={{ ml: 1, mt: 2 }} 
            aria-label="filter"
            variant="outlined"
            size="small" 
            startIcon={<AddIcon/>}
            onClick={this.handleAdd}>Add Condition
          </Button>
      </Box>
    );
  }


  render() {
    const open = this.props.open;
    const conditions=this.state.conditions;
    const columns=this.props.columns;

    return (
      <Dialog open={open} 
        onClose={this.handleCancel}
        fullWidth={true}
        maxWidth="md">
        <DialogTitle>Filters</DialogTitle>
        <DialogContent>
          {(conditions.length === 0) ?
          <DialogContentText>
            No Filters added. Add filters with <i>Add Search to Filter</i> first.
          </DialogContentText>
          : null}
          {this.renderGrid(conditions, columns)}
        </DialogContent>
        <DialogActions>
          <Button 
            variant="outlined"
            sx={{ m: 1 }} 
            size="small"
            onClick={this.handleCancel}>
            Cancel
          </Button>
          <Button 
            variant="outlined"
            sx={{ m: 1 }} 
            size="small"
            onClick={this.handleClose} disabled={!this.validate(this.state.conditions)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
