import React from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
// Material
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
// Application Components
import config from '../Configuration';
import Title from '../components/Title';
import RowDetails from '../components/RowDetails';
import {Map, Marker} from '../map/Map';
import { getCenter, findLatitude, findLongitude } from '../map/Geo';


// Code to catch loading phase
const render = (status) => {
  console.log("MapView: render called with status: " + status);
  if (status === Status.LOADING) 
    return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) 
    return <h3>{status} ...</h3>;
  return null;
};


export default class MapPane extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      "data": null,
      "selection": null,
      "complete": false,
      "bounds": null,
      "center": null,
      "zoom": 5,
      "waiting": true,
      "error": null
    }
  }

  componentDidMount() {
    console.log("MapPane mounting");
    const stats=this.props.stats;
    if (stats.valid()) {
      this.getMapped(stats.getDatasetId(), stats.getDataset(), null, null, null, this.props.filter);
    }
  }
  
  componentDidUpdate(prevProps, prevState) {
    console.log("MapPane did update");
    const updated=!this.props.filter.isEqual(prevProps.filter);
    if (updated) {
      console.log("Filter has changed -> reloading");
      const stats=this.props.stats;
      const data=this.state.data;
      if (stats.valid() && (data !== null)) {
        const columns=data.cols;
        const latCol=findLatitude(columns);
        const lngCol=findLongitude(columns);
        this.getMapped(stats.getDatasetId(), stats.getDataset(), latCol, lngCol, this.state.bounds, this.props.filter);
      }
    }
  }


  async getMapped(datasetId, dataset, latCol, lngCol, bounds, filter) {
    console.log("Calling getMapped: "+dataset);
    const conditions=filter.getActiveConditions();
    const host=config.getAppCtx()
    const url=host+"/services/analytics/get-mapped";
    this.setState({waiting: true});

    var data = {
      "datasetId": datasetId,
      "dataset": dataset,
      "filter": {
        "conditions": conditions
      }
    }
    if (latCol !== null && lngCol != null) {
      data["view"]={
        "latCol": latCol,
        "lngCol": lngCol,
        "bounds": bounds
      }
    }
    const response = await fetch(url, config.getRequestSettingsJson(data)).catch(err => {
      return null;
    });
    if (response !== null) {
      if (response.ok) {
        const message= await response.json();
        if (message.status_code===0) {
          const body=message.body;
          const data=body.view;
          const complete=body.complete;
          const result= {
            "data": data,
            "complete": complete,
            "selection": null,
            "waiting": false,
            "error": null,
          }
          if (this.state.center===null) {
            result["center"]=getCenter(data);
          }
          this.setState(result);  
        } else {
          this.setState({"error": response.status_text, waiting: false});
        }
      } else if (response.status===401) {
        console.log("Authentication expired");
        this.setState({"waiting": false})
        this.props.onAuth();
      } else {
        console.log("getMapped error: "+response.status_text);
        this.setState({"error": "HTTP Response: "+response.status_text, waiting: false});
      }
    } else {
      this.setState({
        "error": "Can't connect to the server. Please check your internet connection", waiting: false });
    }
  }

  
  dataAvailable(center, zoom) {
    const centerPrev=this.state.center;
    const zoomPrev=this.state.zoom;

    // Check if complete data loaded
    if (this.state.complete) {
      console.log("Complete data already loaded");
      return true;
    }
    // Check if center has changed - requires reload
    if (center.lat===centerPrev.lat && center.lng===centerPrev.lng) {
      if (zoom===zoomPrev) {
        console.log("View has not changed");
        return true;
      } else if (zoom > zoomPrev) {
        // Zooming in - Check if data loaded and complete
        const data=this.state.data;
        const dataComplete=((data !== null) && (data.totalCount === data.rowCount));
        console.log("Zooming in: "+ (dataComplete ? "Data complete." : "Data incomplete."))
        return dataComplete;
      }
    } 
    return false;
  }

  getBounds(map) {
    const bounds= map.getBounds();
    const ne=bounds.getNorthEast();
    const sw=bounds.getSouthWest();
    return [ sw.lat(), ne.lat(), sw.lng(), ne.lng()];
  }


  handleIdle = (map) => {
    console.log("handleIdle called");
    const mapZoom=map.getZoom();
    const mapCenter={ lat: map.getCenter().lat(), lng: map.getCenter().lng()};
    const mapBounds=this.getBounds(map);
    // get previous center and zoom
    const load=!this.dataAvailable(mapCenter, mapZoom);
    this.setState({
      "zoom": mapZoom, 
      "center": mapCenter,
      "bounds": mapBounds,
      "waiting": load
    });

    if (load) {
      console.log("View has changed - Reload data");
      const stats=this.props.stats;
      const data=this.state.data;
      if ((data !== null) && stats.valid()) {
        console.log(JSON.stringify(mapBounds));
        const columns=data.cols;
        const latCol=findLatitude(columns);
        const lngCol=findLongitude(columns);
        this.getMapped(stats.getDatasetId(), stats.getDataset(), latCol, lngCol, mapBounds, this.props.filter);
      }  
    } else {
      console.log("View has NOT change - SKIP reloading data");
    }
  };


  handleSelect = (event) => {
    const coord=event.latLng;
    const row=this.find(coord);
    console.log(JSON.stringify(row));
    this.setState({ selection: row});
  }


  handleUnselect = () => {
    this.setState({ selection: null});
  }


  find(coord) {
    const data=this.state.data;
    if (data !== null) {
      const columns=data.cols;
      const latCol=findLatitude(columns);
      const lngCol=findLongitude(columns);

      const rows= data.rows.filter(row => (row[latCol] != null & row[lngCol] != null));
      if (rows.length > 0) {
        const lat=coord.lat();
        const lng=coord.lng();

        function distance(row) {
          let dLat=lat-row[latCol];
          let dLng=lng-row[lngCol];
          return Math.sqrt(dLat*dLat + dLng*dLng);
        }
        let match=rows[0];
        let minimum=distance(match);
        for (let i=0; i<rows.length; i++) {
            const row=rows[i];
            let dist=distance(row);
            if (dist < minimum) {
              match=row;
              minimum=dist;
            }
        }
        return match;
      }
    }
    return null;
  }


  renderNoMap(columnsMissing) {
    const message= columnsMissing 
      ? "No columns with the names 'latitude' and 'longitude' were found."
      : "Latitude and longitude columns were found, but the columns contain no complete coordinate.";
    return(
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column'}}>
        <Title>Map</Title>
        <Alert severity="info">
          <AlertTitle>No Map Data Found</AlertTitle>
          {message}
        </Alert>
      </Paper>
    );
  }


  render() {
    console.log("MapPane rendering");
    const showColumns=this.props.showColumns;
    const data=this.state.data;
    const selection=this.state.selection;

    if (data !== null) {
      const columns=data.cols;
      const latCol=findLatitude(columns);
      const lngCol=findLongitude(columns);

      // Find rows with latitude and longitude
      const rows= data.rows.filter(row => (row[latCol] != null & row[lngCol] != null));
      console.log("Rows: "+rows.length);
      if ((latCol.length > 0) && (lngCol.length > 0)) {
        // create subtitle
        const totalCount=data.totalCount;
        const rowCount=data.rowCount;
        const subtitle= (rowCount < totalCount)
        ? "Showing a sample of "+rowCount+" locations out of "+totalCount+" locations" 
        : "Showing all "+rowCount+" locations";

        return (
          <div style={{ height: 650, width: '100%' }}>
            <Grid container spacing={2} sx={{pt:2, pb: 1}}>
              <Grid item xs={(selection===null) ? 12 : 9} key="map">
                <Box sx={{ p: 2, display: 'flex', flexDirection: 'column'}}>
                  <Typography variant="caption" gutterBottom display="block" sx={{pl:1, pt:1, pb: 2}}>{subtitle}</Typography>
                  <Wrapper  apiKey={config.getApiKey()} render={render}>
                    <Map center={this.state.center} zoom={this.state.zoom} minZoom={2}
                      onClick={this.handleUnselect}
                      onIdle={this.handleIdle}>
                      {rows.map((row, index) => {
                        const latitude=row[latCol];
                        const longitude=row[lngCol];
                        const coord ={ lat: latitude, lng: longitude}
                        return(<Marker key={index} position={coord}  onClick={this.handleSelect} />);
                      })}
                    </Map>
                  </Wrapper>
                </Box>
              </Grid>
              <Grid item xs={(selection!==null) ? 3 : 0} key={"Info"}>
                <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', visibility: (this.state.selection===null) ? 'hidden' : 'visible'}}>
                  <Title>Selection</Title>
                  <Box sx={{height: 600, overflow: 'auto'}}>
                    <RowDetails data={this.state.data} 
                      selection={this.state.selection} 
                      showColumns={showColumns}/>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        );  
      } else {
        return this.renderNoMap(latCol==="" | lngCol==="");
      }
    } else {
      console.log("no data available");
      return null;
    }
  }
}