import * as React from 'react';
// Material 
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// Application Components
import config from '../Configuration';
import LicenseDialog from './LicenseDialog';


export default class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state={ 
      "email": null,
      "org": null,
      "error": null,
      "accepted": false
    }
  }

  
  async message(type, email, content) {
    console.log("Calling message: ");
    const host=config.getAppCtx()
    const url=host+"/auth/message";
    const message = {
      "type": type,
      "email": email,
      "content": content
    }
    console.log(JSON.stringify(message));
    const response = await fetch(url, config.getRequestSettingsJson(message)).catch(err => {
      return null;
    });
    console.log("message returned");
    if (response !== null) {
      if (response.ok) {
        const message= await response.json();
        if (message.status_code===0) {
          this.props.close();
        } else {
          this.setState({"error": response.status_text});
        }
      } else if (response.status===401) {
        console.log("Authentication expired");
        this.props.onLoginClicked("login");
      } else {
        this.setState({"error": "HTTP Response: "+response.status_text});
      }
    } else {
      this.setState({"error": "Can't connect to the server. Please check your internet connection"});
    }
  }

  submitHandler = () => {
    console.log(this.state.email);
    this.message("register", this.state.email, this.state.org);
  }

  closeHandler = () => {
    this.props.close();
  }

  changeValue = (name, value) => {
    this.setState({[name]: value});
  }

  handleAccept = () => {
    this.setState({"accepted": true});
  };

  render() {
    const open=this.props.open;
    const accepted=this.state.accepted;
    return (
      <div>
        <LicenseDialog open={open && !accepted} 
          acceptHandler={this.handleAccept}
          rejectHandler={this.closeHandler}
        />
        <Dialog open={open && accepted} onClose={this.closeHandler}>
          <DialogTitle>Register</DialogTitle>
          <DialogContent>
            <DialogContentText>
            Thanks for your interest! To register and receive a login to please enter your email address here 
            and click the register button below. 
            You will hear from us in a day or two with login credentials.
            </DialogContentText>
            <TextField
              autoFocus
              label="Organization"
              id="org"
              margin="dense"
              fullWidth
              variant="standard"
              onChange={(event) => this.changeValue("org", event.currentTarget.value)}
            />
            <TextField
              label="Email Address"
              id="email"
              type="email"
              required
              margin="dense"
              fullWidth
              variant="standard"
              onChange={(event) => this.changeValue("email", event.currentTarget.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeHandler}>Cancel</Button>
            <Button onClick={this.submitHandler}>Register</Button>
          </DialogActions>
        </Dialog>        
      </div>
    );  
  }
}