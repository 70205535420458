import * as React from 'react';
import { payments } from '@square/web-sdk';
// Application Components
import config from '../Configuration';

// Test data provided
const verificationDetailsTest = {
  amount: '1.0',
  billingContact: {
    addressLines: ['123 Main Street', 'Apartment 1'],
    familyName: 'Doe',
    givenName: 'John',
    email: 'jondoe@gmail.com',
    country: 'GB',
    phone: '3214563987',
    region: 'LND',
    city: 'London',
  },
  currencyCode: 'USD',
  intent: 'CHARGE',
};

export default class SquareForm extends React.Component {
  constructor(props) {
    super(props);
    // Test 
    // this.appId = "sandbox-sq0idb-ItfBjsV3edLO0N2zKq0KCw";
    // Data Lumen Sandbox
    //this.appId = "sandbox-sq0idb-2iEf9weKQcsb_4St5GYn7w";
    const env = config.getEnv();
    console.log(env);
    this.appId = config.getSquareAppId();
    // this.locationId= (env === "prod") ? "LQA13TMQF7Z8T" : "LVTYBMS8ADJV3";
    this.locationId= (env === "prod") ? "LBP2FZZ02M8W0" : "LYE1PD8MGEECG";
    console.log(this.locationId);
    this.myRef = React.createRef();
    this.state={
      "instance": null,
      "card": null
    }
  }

  componentDidMount() {
    console.log("SquareForm did mount");
    this.onLoad();
  }

  getStyle() {
    const darkModeCardStyle = {
      '.input-container': {
        borderColor: '#2D2D2D',
        borderRadius: '6px',
      },
      '.input-container.is-focus': {
        borderColor: '#006AFF',
      },
      '.input-container.is-error': {
        borderColor: '#ff1600',
      },
      '.message-text': {
        color: '#999999',
      },
      '.message-icon': {
        color: '#999999',
      },
      '.message-text.is-error': {
        color: '#ff1600',
      },
      '.message-icon.is-error': {
        color: '#ff1600',
      },
      input: {
        backgroundColor: '#2D2D2D',
        color: '#FFFFFF',
        fontFamily: 'helvetica neue, sans-serif',
      },
      'input::placeholder': {
        color: '#999999',
      },
      'input.is-error': {
        color: '#ff1600',
      },
    };
  return(darkModeCardStyle);
  }

  async onLoad() {
    console.log("Loading Payments")
    let instance = await payments(this.appId, this.locationId);
    if (instance == null) {
        //throw new Error('Square Web Payments SDK failed to load');
        console.log("Loading Payments FAILED with instance null");
        return;
    }
    console.log("Loading Payments successful");
    let card = await this.initializeCard(instance);
    this.setState({
      "instance": instance,
      "card": card
    });
  }

  async initializeCard(instance) {
    //const card = await instance.card({"style": this.getStyle()});
    const card = await instance.card();
    if (card !== null) {
      console.log("Card is not null");
      //await card.attach('#card-container');
      await card.attach(this.myRef.current);
    }
    return card;
  }


  async tokenize(paymentMethod) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult;
    } else {
      let errorMessage = "Tokenization failed with status: " + tokenResult.status;
      if (tokenResult.errors) {
        errorMessage += " and errors: " + JSON.stringify(tokenResult.errors);
      }
      console.log(errorMessage);
    }
  }

  async initiatePayment() {
    const payHandler = this.props.onPay;
    const card = this.state.card;
    if (card != null) {
      const tokenResult = await this.tokenize(card);
      console.log(JSON.stringify(tokenResult));
      const verificationToken = await this.verifyBuyer(this.state.instance, tokenResult.token);
      console.log(JSON.stringify(verificationToken));
      payHandler(tokenResult, verificationToken);
    }
  }

  async verifyBuyer(payments, token) {
    var amount = this.props.amount.toFixed(2);
    const address= this.props.address;

    console.log("Amount: " + amount);

    const verificationDetails = {
      amount: amount,
      currencyCode: 'USD',
      intent: 'CHARGE',
      billingContact: address
    };

    console.log("verifyBuyer called:" + JSON.stringify(verificationDetails));
    const verificationResults = await payments.verifyBuyer(token, verificationDetails);
    console.log(JSON.stringify(verificationResults))
    return verificationResults.token;
  }



  buttonHandler= () => {
    console.log("Payment Button pressed");
    this.initiatePayment();
  };


  render() {
    console.log("SquareForm called - payment button rendered");
    return (
      <div style={{ paddingTop: 12}}>
        <div ref={this.myRef} id="card-container"></div>
        <button
          id="card-button"
          onClick={this.buttonHandler}
          style={{
            backgroundColor: '#006aff',
            color: '#ffffff',
            borderStyle: "none",
            outline: "none",
            borderRadius: 5,
            boxShadow: 1,
            fontSize: 16,
            width: "100%",
            padding: 12,
            cursor: 'pointer'
          }}
          type="button">Pay
        </button>
        <div id="payment-status-container"></div>
      </div>
    );
  }
}