import * as React from 'react';
// Material
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';


export default class AttributeView extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      "orderBy": "Column",
      "direction": "asc",
    }
  }

  sort(columns, field, direction) {
    console.log("Sort called: "+field+" "+direction)
    if (field !== "") {
      switch (field) {
        case "Column":
          if (direction==="asc") {
            columns.sort((a, b) => a.name.localeCompare(b.name));
          } else {
            columns.sort((a, b) => b.name.localeCompare(a.name));  
          }
          break;
        case "Sample Value":
          if (direction==="asc") {
            columns.sort((a, b) => a.value.localeCompare(b.value));
          } else {
            columns.sort((a, b) => b.value.localeCompare(a.value));  
          }
          break;
        case "Data Type":
          if (direction==="asc") {
            columns.sort((a, b) => a.dataType.localeCompare(b.dataType));
          } else {
            columns.sort((a, b) => b.dataType.localeCompare(a.dataType));  
          }
          break;
        case "Length":
          if (direction==="asc") {
            columns.sort((a, b) => a.maxLength - b.maxLength);
          } else {
            columns.sort((a, b) => b.maxLength - a.maxLength);  
          }
          break;
        case "Distinct":
          if (direction==="asc") {
            columns.sort((a, b) => isFinite(a.distinct) ? (isFinite(b.distinct) ? a.distinct - b.distinct : -1) : 1);
          } else {
            columns.sort((a, b) => isFinite(a.distinct) ? (isFinite(b.distinct) ? b.distinct - a.distinct : 1) : -1);  
          }
          break;
        case "Missing":
          if (direction==="asc") {
            columns.sort((a, b) => a.missing - b.missing);
          } else {
            columns.sort((a, b) => b.missing - a.missing);  
          }
          break;
        default:
          console.log("Missing Sort case")
        }
          
    }
  }

  sortHandler = (selected) => {
    console.log("sortHandler passed: "+selected);
    const directionBefore=this.state.direction;
    let direction="";
    let field=selected;

    // State transition based on previous order by column and previous sort direction
    if (selected !== this.state.orderBy) {
      direction="asc";
    } else {
      console.log("Order before: "+directionBefore);
      if (directionBefore==="asc") {
        direction="desc";
      } else if (directionBefore==="desc") {
        direction="asc";
      }
    }
    console.log("sortHandler sort by: "+field+" - "+direction);
    this.setState({ 
      "orderBy": field,
      "direction": direction
    });
  }


  renderTable(rows, showColumns, onShowChange, locale) {
    console.log("RenderTablee called");
    const orderBy=this.state.orderBy;
    const direction=this.state.direction;
    this.sort(rows, orderBy, direction);
    // Select all support
    const rowCount=rows.length;
    const columnNames=rows.map((column)=> column.name);
    const selectedColumns=columnNames.filter((category) => showColumns.has(category));
    const selectedCount=selectedColumns.length;
    // Formatting
    const options = {style: 'decimal', maximumFractionDigits: 0, useGrouping:true};
    const f = new Intl.NumberFormat(locale, options);  

    var labels = [
      {name: "Column", align: "left", width: 300},
      {name: "Data Type", align: "left", width: 150},
      {name: "Sample Value", align: "left", width: 600},
      {name: "Length", align: "right", width: 100},
      {name: "Distinct", align: "right", width: 100},
      {name: "Missing", align: "right", width: 100}
    ];

    return(
      <TableContainer
        sx={{ maxHeight: 500}}>
        <Table 
          sx={{ minWidth: 650 }}
          stickyHeader
          size="small" 
          aria-label="Table of Attribute Columns">
          <TableHead>
            <TableRow>
            <TableCell padding="checkbox" align="left">
              <Tooltip placement="left" title="Show checked columns">
                <Checkbox color="default"
                  indeterminate={(selectedCount > 0) && (selectedCount < rowCount)}
                  checked={selectedCount === rowCount}
                  onChange={() =>  onShowChange(columnNames, (rowCount !== selectedCount))}
                  inputProps={{ 'aria-label': 'show all categories'}}/>
              </Tooltip>
            </TableCell>
              {labels.map((label)=> (
              <TableCell key={label.name} align={label.align}>
                <TableSortLabel
                  active={orderBy === label.name}
                  direction={orderBy === label.name ? direction : "asc" }
                  onClick={() => { this.sortHandler(label.name)}}>
                  {label.name}
                  {orderBy === label.name ? (
                    <Box component="span" sx={visuallyHidden}>
                      {direction === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return(
                <TableRow
                  hover
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell  padding="checkbox" align="left">
                    <Tooltip placement="left" title={"Show checked column"}>
                      <Checkbox color="default"
                        checked={showColumns.has(row.name)}
                        inputProps={{'aria-labelledby': row.name}}
                        onClick={(event) => onShowChange([row.name], !showColumns.has(row.name))}/>
                      </Tooltip>
                    </TableCell>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.dataType}</TableCell>
                  <TableCell align="left">{row.value}</TableCell>
                  <TableCell align="right">{(row.maxLength !== undefined) ? f.format(row.maxLength) : ""}</TableCell>
                  <TableCell align="right">{(row.distinct !== undefined) ? f.format(row.distinct) : ">60000"}</TableCell>
                  <TableCell align="right">{f.format(row.missing)}</TableCell>
                </TableRow>
              );    
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  render() {
    const rows=this.props.rows;
    const showColumns=this.props.showColumns;
    const onShowChange=this.props.onShowChange;
    const locale = 'en';
    return this.renderTable(rows, showColumns, onShowChange, locale);
  }
}