// Application 
import Condition from './Condition';

export default class Filter {
  enabled: boolean;
  conditions: Condition[];

  constructor() {
    this.enabled=true;
    this.conditions=[];
  }

  copy(): Filter {
    const filter: Filter = new Filter();
    filter.enabled=this.enabled;
    filter.conditions = this.conditions.slice();
    return filter;
  }

  getState(): string {
    if (this.conditions.length===0) {
      return "empty";
    } else if (this.enabled) {
      return "active";
    } else {
      return "disabled";
    }
  }

  setEnabled(value: boolean) {
    console.log("setEnabled: "+value);
    this.enabled=value;
  }

  isDisabled(): boolean {
    return !this.enabled;
  }

  setConditions(aConditionList: Condition[]): void {
    this.conditions=aConditionList;
  }

  addConditon(condition: Condition): void {
    const cond: Condition[] = this.conditions;
    for (let i:number = 0; i<cond.length; i++) {
      if (cond[i].isEqual(condition)) {
        return;
      }
    }
    this.conditions.push(condition);
  }

  getActiveConditions(): Condition[] {
    if (this.enabled) {
      return this.conditions;
    } else {
      return [];
    }
  }

  getConditions(): Condition[] {
    return this.conditions;
  }


  isEqual(aFilter: Filter): boolean {
    if (this.enabled !== aFilter.enabled) {
      console.log("Conditon enabled not same");
      return false;
    } else {
      const cond1: Condition[] = this.conditions;
      const cond2: Condition[] = aFilter.conditions;
      if (cond1.length !== cond2.length) {
        console.log("Condition count not same");
        return false;
      }
      for (let i:number = 0; i<cond1.length; i++) {
        let c1: Condition = cond1[i];
        let c2: Condition = cond2[i];
        if (!c1.isEqual(c2)) {
          console.log("Conditions not same");
          return false;
        }
      }
    }
    return true;
  }

  // Return new Filter - so react notices change
  addSearch(text: string) {
    //console.log("Filter addSearch");
    let conditions=this.conditions.slice();
    if (text !== null && text!=="") {
      conditions.push(new Condition("", "contains", [text]));
    }
    let result: Filter = this.copy();
    result.setConditions(conditions);
    return result;
  }

  log() {
    console.log("Filter: "+this.enabled);
    console.log(" Conditions: "+this.conditions.length);
    console.log(JSON.stringify(this));
  }
}