import * as React from 'react';
// Material 
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export default function LicenseDialog(props) {  
  const open=props.open;
  const handleAccept=props.acceptHandler;
  const handleReject=props.rejectHandler;
  return (
    <Dialog
      open={open}
      onClose={handleReject}
      aria-labelledby="License Dialog"
      aria-describedby="License Agreement"
    >
      <DialogTitle id="license-dialog-title">
        {"Sign up for Upgrata Data Lumen?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="license-dialog-description">
          Uploading regulated personal data 
          - in the sense of the General Data Protection Regulation (GDPR) -
          is a violation of the usage agreement. <br/> <br/>
          THE PROGRAM IS PROVIDED ON AN "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, 
          EITHER EXPRESS OR IMPLIED INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OR CONDITIONS OF TITLE, 
          NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReject}>Disagree</Button>
        <Button onClick={handleAccept} autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );  
}