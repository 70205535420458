import * as React from 'react';
// Material
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
// Application components
import CategoryDetails  from './CategoryDetails';


export default class CategoryView extends React.Component {
  // Constructor initialize state
  constructor(props) {
    super(props);
    this.state={
      "column": null,
      "orderBy": "Column",
      "direction": "asc"
    }
  }


  handleClick = (column) => {
    console.log("Handle Click called: "+column);
    this.setState({
      "column": column
    })  
  }

  closeHandler = () => {
    console.log("CategoryDetails closehandler called");
    this.setState({
      "column": null
    })  
  }


  sortCategories(categories, field, direction) {
    console.log("Sort called: "+field+" "+direction)
    if (field !== "") {
      switch (field) {
        case "Column":
          if (direction==="asc") {
            categories.sort((a, b) => a.name.localeCompare(b.name));
          } else {
            categories.sort((a, b) => b.name.localeCompare(a.name));  
          }
          break;
          case "Values":
            if (direction==="asc") {
              categories.sort((a, b) => a.values.localeCompare(b.values));
            } else {
              categories.sort((a, b) => b.values.localeCompare(a.values));  
            }
            break;
          case "Distinct":
            if (direction==="asc") {
              categories.sort((a, b) => a.distinct - b.distinct);
            } else {
              categories.sort((a, b) => b.distinct - a.distinct);  
            }
            break;
          case "Missing":
            if (direction==="asc") {
              categories.sort((a, b) => a.missing - b.missing);
            } else {
              categories.sort((a, b) => b.missing - a.missing);  
            }
            break;
          default:
          console.log("Missing Sort case")
        }
          
    }
  }

  sortHandler = (selected) => {
    console.log("sortHandler passed: "+selected);
    const directionBefore=this.state.direction;
    let direction="";
    let field=selected;

    // State transition based on previous order by column and previous sort direction
    if (selected !== this.state.orderBy) {
      direction="asc";
    } else {
      console.log("Order before: "+directionBefore);
      if (directionBefore==="asc") {
        direction="desc";
      } else if (directionBefore==="desc") {
        direction="asc";
      }
    }
    console.log("sortHandler sort by: "+field+" - "+direction);
    this.setState({ 
      "orderBy": field,
      "direction": direction
    });
  }


  renderTable(categories, showColumns, onShowChange, locale) {
    console.log("RenderTablee called");
    const orderBy=this.state.orderBy;
    const direction=this.state.direction;
    this.sortCategories(categories, orderBy, direction);
    // Select all support
    const rowCount=categories.length;
    const categoryNames=categories.map((category)=> category.name);
    const selectedCategories=categoryNames.filter((category) => showColumns.has(category));
    const selectedCount=selectedCategories.length;
    // Formatting
    const options = {style: 'decimal', maximumFractionDigits: 0, useGrouping:true};
    const f = new Intl.NumberFormat(locale, options);  
    var labels = [
      {name: "Column", align: "left"},
      {name: "Values", align: "left"},
      {name: "Distinct", align: "right"},
      {name: "Missing", align: "right"}
    ];
    return(
      <TableContainer
        sx={{ maxHeight: 500}}>
        <Table 
          sx={{ minWidth: 650 }} 
          stickyHeader
          size="small" 
          aria-label="Table of Category Columns">
          <TableHead>
            <TableRow>
            <TableCell padding="checkbox" align="left">
              <Tooltip placement="left" title="Show checked columns">
                <Checkbox color="default"
                  indeterminate={(selectedCount > 0) && (selectedCount < rowCount)}
                  checked={selectedCount === rowCount}
                  onChange={() =>  onShowChange(categoryNames, (rowCount !== selectedCount))}
                  inputProps={{ 'aria-label': 'show all categories'}}/>
              </Tooltip>
            </TableCell>
              {labels.map((label)=> (
              <TableCell key={label.name} align={label.align}>
                <TableSortLabel
                  active={orderBy === label.name}
                  direction={orderBy === label.name ? direction : "asc" }
                  onClick={() => { this.sortHandler(label.name)}}>
                  {label.name}
                  {orderBy === label.name ? (
                    <Box component="span" sx={visuallyHidden}>
                      {direction === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((row) => {
              return(
                <TableRow
                  hover
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell  padding="checkbox" align="left">
                    <Tooltip placement="left" title={"Show checked column"}>
                      <Checkbox color="default"
                        checked={showColumns.has(row.name)}
                        inputProps={{'aria-labelledby': row.name}}
                        onClick={(event) => onShowChange([row.name], !showColumns.has(row.name))}/>
                    </Tooltip>
                  </TableCell>
                  <TableCell component="th" scope="row" 
                    sx={{ cursor: 'pointer' }} 
                    onClick={() => this.handleClick(row.name)}>
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.values}</TableCell>
                  <TableCell align="right">{f.format(row.distinct)}</TableCell>
                  <TableCell align="right">{f.format(row.missing)}</TableCell>
                </TableRow>
              );    
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }


  render() {
    console.log("Rendering CategoryView");
    const md=this.props.metadata;
    const categories=this.props.rows;
    const showColumns=this.props.showColumns;
    const onShowChange=this.props.onShowChange;
    const locale = 'en';

    if (this.state.column===null) {
      return this.renderTable(categories, showColumns, onShowChange, locale);
    } else {
      const column=this.state.column;
      const match=categories.filter((row) => row.name === column);
      //console.log(JSON.stringify(categories));
      if (match.length===1) {
        const category=match[0];
        return(
          <div style={{ height: 500, width: '100%' }}>
            <CategoryDetails
              metadata={md}
              category={category}
              filter={null}
              onUpdateFilter={this.props.onUpdateFilter}
              onClose={this.closeHandler}/>
          </div>
        );
      } else {
        return null;
      }
    }
  }
}