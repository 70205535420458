import * as React from 'react';
// MUI Components
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';


export default function SearchTable(props) {
  const columns=props.columns;
  const rows=props.rows;
  const onSort=props.onSort;
  const orderBy=props.orderBy;
  const direction=props.direction;
  const order = (direction === "a") ? "asc" : "desc";
  console.log("Direction:"+direction+" Sort Order: "+order);
  
  return(
    <Box sx={{ width: '100%', overflow: 'hidden'}}>
      <TableContainer
        sx={{ maxHeight: 500}}>
        <Table 
          sx={{ minWidth: 500 }}
          size="small" 
          stickyHeader
          aria-label="data table">
          <TableHead>
            <TableRow>
              {columns.map((col) => {
                const align=col.type==='number' ? "right" : "left";
                return(
                  <TableCell key={col.headerName} 
                    align={align}
                    sx={{whiteSpace: 'nowrap'}}
                  >
                    <TableSortLabel
                      active={orderBy === col.field}
                      direction={orderBy === col.field ? order : 'asc'}
                      onClick={() => { onSort(col.field)}}
                    >
                      {col.headerName}
                      {orderBy === col.field ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              return(
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                {columns.map((col) => {
                  const align=col.type==='number' ? "right" : "left";
                  const display=row[col.field];
                  return(
                    <TableCell key={col.field}
                      sx={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 400}}
                      align={align}
                    >{display}
                    </TableCell>
                  );
                  })}
                </TableRow>
              );          
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}