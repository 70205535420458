import * as React from 'react';
// Material
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// Application Components
import config from '../Configuration';
import requestCache from '../Cache';
import CategoryAggregation from './CategoryAggregation';
import DateAggregation from './DateAggregation';
import MeasureAggregation from './MeasureAggregation';
import FilterButton from '../filter/FilterButton';
import SelectAggregation from './SelectAggregation';
//import Stats from '../model/Stats';


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`column-type-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

// Translate function name into field name
const functionMap = {
  "sum": "s",
  "avg": "a",
  "count": "c",
  "median": "m",
  "distinct": "d"
}


export default class AggregationView extends React.Component {
  
  constructor(props) {
    super(props);
    this.state={
      "tab": 0,
      "function": "sum",
      "data": null,
      "waiting": false,
      "error": null
    }
  }

  componentDidMount() {
    console.log("AggregationView did mount");
    const stats=this.props.stats;  
    const measure=this.props.measure;
    const filter=this.props.filter;
    if (this.props.measure !== "") {
      console.log("fetch on component mount");
      this.setState({"waiting": true});
      this.getAggregations(stats.getDatasetId(), stats.getDataset(), measure, filter);        
    }
  }


  componentDidUpdate(prevProps, prevState) {
    const stats=this.props.stats;  
    const measure=this.props.measure;
    const filter=this.props.filter;
    if (this.props.measure !== prevProps.measure || !filter.isEqual(prevProps.filter)) {
      console.log(this.props.measure + " != " + prevProps.column);
      if (this.props.measure !== "") {
        console.log("fetch on component update");
        this.setState({"waiting": true});
        this.getAggregations(stats.getDatasetId(), stats.getDataset(),  measure, filter);        
      }
      }
  }

  
  async getAggregations(datasetId, dataset, measure, filter) {
    const conditions=filter.getActiveConditions();
    console.log("get-aggregations");
    const host=config.getAppCtx()
    const url=host+"/services/analytics/get-aggregations";
    const data = {
      "column": measure,
      "query": {
        "datasetId": datasetId,
        "dataset": dataset,
        "filter": {
          "conditions": conditions
        }
      }
    }
    const response = await requestCache.fetch(url, config.getRequestSettingsJson(data));
    console.log("get-aggregations returned");
    if (response !== null) {
      if (response.ok) {
        const message=response.data;
        if (message.status_code===0) {
          this.setState({
            "data": message.body,
            "waiting": false,
          })
        } else {
          this.props.onSelectColumn("");
          this.setState({"error": response.status_text, waiting: false});
        }
      } else if (response.status===401) {
        console.log("Authentication expired");
        this.setState({"waiting": false})
        this.props.onAuth();
      } else {
        this.setState({"error": "HTTP Response: "+response.status_text, waiting: false});
      }
    } else {
      this.props.onSelectColumn("");
      this.setState({
        "error": "Can't connect to the server. Please check your internet connection",
         waiting: false});
    }
  }


  selectColumn = (event) => {
    this.setState({"waiting": true});
    let measure=event.target.value;
    this.props.onSelectColumn(measure);
  };

  handleAddSearch() {
    // Dummmy should not happen.
    console.log("Error: addSearch called in aggregation view");
  }

  changeTab = (event, tabIndex) => {
    this.setState({
      "tab": tabIndex
    })
  };


  renderSelectColumn(columns, selected) {
    return(
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="select-column-label">Measure Column</InputLabel>
        <Select
          variant="standard"
          labelId="select-column-label"
          id="column-select"
          value={selected}
          onChange={this.selectColumn}
          label="Column"
        >
          <MenuItem value=""><em>None</em></MenuItem>
          { columns.map(name => <MenuItem key={name} value={name}>{name}</MenuItem>) }
        </Select> 
      </FormControl>
    );
  }

  selectFunction = (event) => {
    let fn=event.target.value;
    this.setState({"function": fn});
  };


  renderView(md, fields, data, showColumns, selectedColumn, func) {
    if (md !== null && data !== null) {
      const filter=this.props.filter;
      const handleUpdate=this.props.onUpdateFilter;
      const selectedTab=this.state.tab;
      const aggregations=data.aggregations.filter((agg) => showColumns.has(agg.groupBy));
      const measure_rows=fields.filter((row) => row.type==='measure');
      const category_rows=fields.filter((row) => row.type==='category');
      const datetime_rows=fields.filter((row) => row.type==='datetime');      
      const fieldArray=fields.filter(field => field.name===selectedColumn);
      const dataField=functionMap[func];
      if (fieldArray.length === 1) {
        return(
          <Box sx={{ display: 'flex', flexDirection: 'column'}}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={selectedTab} onChange={this.changeTab} aria-label="Aggregation Type">
              <Tab label={"Category ("+category_rows.length+")"}  disabled={category_rows.length===0} />
              <Tab label={"Date/Time ("+datetime_rows.length+")"} disabled={datetime_rows.length===0} />
              <Tab label={"Measure ("+measure_rows.length+")"} disabled={measure_rows.length===0} />
              </Tabs>
            </Box>
            <TabPanel value={selectedTab} index={0}>
            <CategoryAggregation
              aggregations={aggregations}
              fnField={dataField}
              filter={filter}
              onUpdateFilter={handleUpdate}/>
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <DateAggregation
                selectedColumn={selectedColumn}
                aggregations={aggregations}
                fnField={dataField}
                filter={filter}
                onUpdateFilter={handleUpdate}/>
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
              <MeasureAggregation 
                aggregations={aggregations}
                fnField={dataField}
                filter={filter}
                onUpdateFilter={handleUpdate}/>
            </TabPanel>
          </Box>
        );
      }
    }
    return(
      <Alert severity="info" color="primary">
        Select a measure column in the toolbar to begin your analysis
      </Alert>
    );
  }


  renderToolbar(stats, showColumns, column, filter) {
    const fields=stats.getFields();
    const measures=fields
      .filter((field) => ((field.type==='measure') && showColumns.has(field.name)))
      .map(field => field.name).sort();
    return(
      <Toolbar>
        <Typography component="h2" variant="h6" color="primary"
          sx={{ flex: '1 1 100%' }}
          id="Focus">
          Totals by Group
        </Typography>
        <SelectAggregation
          value={this.state.function}
          onChange={this.selectFunction}
          />
        {this.renderSelectColumn(measures, column)}
        <FilterButton mt={2}
          columns={fields}
          search={""}
          filter={filter}
          onUpdateFilter={this.props.onUpdateFilter}
          onAddSearch={this.handleAddSearch}/>
      </Toolbar>
    );
  }


  render() {
    const stats=this.props.stats;
    const measure=this.props.measure;
    const showColumns=this.props.showColumns;
    const filter=this.props.filter;
    if (stats.valid()) {
      const md=stats.getMetadata();
      const fields=stats.getFields();
      const selectedFields=fields.filter((field) => showColumns.has(field.name));
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column'}}>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={this.state.waiting}>
            <CircularProgress color="inherit" />
          </Backdrop>
          {this.renderToolbar(stats, showColumns, measure, filter)}
          <Divider/>
          {this.renderView(md, selectedFields, this.state.data, showColumns, measure, this.state.function)}
          <Snackbar open={this.state.error !== null}
            autoHideDuration={6000} 
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            variant="filled"  elevation={6}
            onClose={() => { this.setState({ "error": null});}}>
            <Alert severity="error" sx={{ width: '100%' }}
              onClose={() => { this.setState({ "error": null});}}
            >
              <AlertTitle>Error</AlertTitle>
              {this.state.error}
            </Alert>
          </Snackbar>
        </Box>
      );
    }
  }
}