import * as React from 'react';
// MUI Components
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';
// Icons
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SaveIcon from '@mui/icons-material/Save';

// Application Components
import FilterButton from '../filter/FilterButton';
//import Filter from '../model/Filter';
import SaveDialog from './SaveDialog';


export default class SearchBar extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      "search": "",
      "sortColumn": null,
      "saveDialog": false
    }
  }


  handleSearchChange = (event) => {
    const value=event.target.value;
    this.setState({'search': value});
  };

  handleSearchSubmit = (event) => {
    event.preventDefault();
    console.log("Submit: "+this.state.search)
    this.props.onSubmit(this.state.search, "");
  };

  handdleAddSearch = (event) => {
    this.props.onAddFilter(this.state.search);
    this.setState({
      "search": "",
    });
  };

  handleViewMenu = () => {
    const value= (this.props.view === "map") ? "table" : "map";
    this.props.onViewButton(value); 
  }

  handleViewChange = (event) => {
    this.props.onViewButton(event.target.value); 
  };

  handleDownload =  () => {
    this.props.onDownload();
  };

  handleSaveButton =  () => {
    this.setState({
      "saveDialog": true
    });
  };

  handleSave =  (name) => {
    console.log("handleSave called");
    this.setState({
      "saveDialog": false
    });
    if (name != null) {
      console.log("Saving: "+name);
      this.props.onSave(name);
    } else {
      console.log("Save canceled");
    }
  };


  renderViewSelect() {
    const value=this.props.view
    return(
      <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
        <InputLabel id="select-view-label">Visualization</InputLabel>
        <Select
          id="view-select"
          labelId="select-view-label"
          value={value}
          onChange={this.handleViewChange}
          label="Column"
          sx={{ width: 100 }}
        >
          <MenuItem value="table">Table</MenuItem>
          <MenuItem value="map" disabled={!this.props.hasMap}>Map</MenuItem>
        </Select> 
      </FormControl>
    );
  }


  renderSearch() {
    return(
      <FormControl sx={{ m: 1, width: '40ch' }} variant="standard">
        <InputLabel htmlFor="search-field">Search</InputLabel>
        <Input
          id="search-field"
          type="text"
          value={this.state.search}
          autoFocus
          onChange={this.handleSearchChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton type="submit"
                aria-label="search">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
        }/>
      </FormControl>
    );
  }


  render() {
    const columns=this.props.columns;
    const filter=this.props.filter;
    const signedIn=this.props.signedIn;

    return(
      <Box
        component="form" 
        onSubmit={this.handleSearchSubmit} 
        noValidate 
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "100%" }}>
        {this.renderSearch()}
        {this.renderViewSelect()}
        <FilterButton mt={2}
          columns={columns}
          search={this.state.search}
          filter={filter}
          onAddSearch={this.handdleAddSearch}
          onUpdateFilter={this.props.onUpdateFilter}/>
        <Tooltip title={signedIn ? "Save view as a new dataset" : "Login required to save dataset"}>
          <span>
            <Button
              disabled={!signedIn}
              sx={{ ml:1, mt: 2 }} 
              variant="text" 
              size="small" 
              startIcon={<SaveIcon />}
              onClick={this.handleSaveButton}>Save
            </Button>
          </span>
        </Tooltip>
        <Button
          sx={{ ml:1, mt: 2 }} 
          variant="text" 
          size="small" 
          startIcon={<FileDownloadIcon />}
          onClick={this.handleDownload}>Download
        </Button>
        <SaveDialog
          open={this.state.saveDialog}
          onSubmit={this.handleSave}/>
      </Box>
    );  
  }
}
