export default class TimeOut {
  
  constructor() {
    console.log("Timeout initializing");
    this.loginTime=Date.now();
    this.alive = Date.now();
    this.handleAuth = null;
    // defaults
    this.refresh=5*60;
    this.checkInterval = 60;
    this.timeoutInterval = 19*60;
    this.timer = null;
    this.events= ['click','scroll','load','keydown'];
  }

  start(authHandler) {
    console.log("Timeout - start called");
    this.alive = Date.now();
    this.init(authHandler)
  }

  // Doesn't reset alive time
  extend(authHandler) {
    console.log("Timeout - extend called");
    this.init(authHandler)
  }

  init(authHanlder) {
    this.handleAuth=authHanlder;
    this.loginTime=Date.now();
    this.addEvents();
    this.startTimer();
  }

  stop() {
    console.log("Timeout - stop called");
    this.handleAuth = null;
    this.removeEvents();
    if (this.timer != null) {
      clearTimeout(this.timer);
    }
  }

  handleAlive = () => {
    this.alive = Date.now();
  }

  startTimer = () => {
    const milliSeconds=this.checkInterval*1000;
    this.timer=setTimeout(this.timeoutHandler, milliSeconds);
  }

  timeoutHandler = () => {
    //console.log("Timeout Handler" (new Date()) +": last use: " + (new Date(this.alive)));
    const secondsIdle = (Date.now() - this.alive)/1000;
    const secondsAuthenticated =  (Date.now() - this.loginTime)/1000;
    console.log("Timeout Handler called - Idle: "+secondsIdle);
    if (secondsIdle > this.timeoutInterval) {
      console.log("Time out!");
      this.removeEvents();
      if (this.handleAuth != null) {
        this.handleAuth(false);
      } else {
        console.log("Logout function not defined!");
      }
      return;
    } 
    if (secondsAuthenticated > this.refresh) {
      this.removeEvents();
      if (this.handleAuth != null) {
        this.handleAuth(true);
      }
    } else {
      this.startTimer();
    }
  }

  addEvents = () => {
    this.events.forEach(eventName => {
      window.addEventListener(eventName, this.handleAlive)
    })
  }

  removeEvents = ()=>{
    this.events.forEach(eventName => {
        window.removeEventListener(eventName, this.handleAlive);
    })
};
}