import * as React from 'react';
// Material
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Toolbar from '@mui/material/Toolbar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// Component
import DateHistogramView from './DateHistogramView'
import CategoryFrequencyView from './CategoryFrequencyView'
import HistogramView from './HistogramView'
import FilterButton from '../filter/FilterButton';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


function renderToolbar(stats, filter, handleUpdate) {
  const fields=stats.getFields();
  return(
    <Toolbar>
      <Typography component="h2" variant="h6" color="primary"
        sx={{ flex: '1 1 100%' }}
        id="Focus">
        Histograms
      </Typography>
      <FilterButton mt={0}
        columns={fields}
        search={""}
        filter={filter}
        onUpdateFilter={handleUpdate}
        onAddSearch={() => { console.log("Error: addSearch called in frequency view");}}/>
    </Toolbar>
  );
}

export default function FrequencyView(props) {
  const stats = props.stats;
  const filter = props.filter;
  const showColumns = props.showColumns;
  const handleUpdate = props.onUpdateFilter;
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (stats.valid()) {
    const md=stats.getMetadata();
    let fields=md.fields.filter((field) => showColumns.has(field.name));
    let category_fields=fields.filter((field) => field.type==='category' && showColumns.has(field.name));
    let measure_fields=fields.filter((field) => field.type==='measure' && showColumns.has(field.name));
    let datetime_fields=fields.filter((field) => field.type==='datetime' && showColumns.has(field.name));
      return(
      <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column'}}>
          {renderToolbar(stats, filter, handleUpdate)}
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="Frquency Distribution">
              <Tab label={"Category ("+category_fields.length+")"}  disabled={category_fields.length===0} />
              <Tab label={"Date/Time ("+datetime_fields.length+")"} disabled={datetime_fields.length===0} />
              <Tab label={"Measure ("+measure_fields.length+")"} disabled={measure_fields.length===0} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <CategoryFrequencyView 
              metadata={md} 
              fields={category_fields}
              filter={filter}
              onUpdateFilter={handleUpdate}/>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DateHistogramView 
              metadata={md} 
              fields={datetime_fields} 
              filter={filter}
              onUpdateFilter={handleUpdate}/>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <HistogramView 
              metadata={md} 
              fields={measure_fields}
              filter={filter}
              onUpdateFilter={handleUpdate}/>
          </TabPanel>
      </Box>
    );
  }
}