import React from 'react';
import './App.css';
// Material design
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
// Application Component
import config from './Configuration';
import Main from './Main';
import Login from './login/Login';
import Register from './login/Register';
import TimeOut from './TimeOut';


// Constant
const timeout=new TimeOut();
// Theme
//const theme = createTheme();
const theme = createTheme({
  palette: {
    primary: {
      main: '#45716b'
    },
    secondary: {
      main: '#f5b21a'
    },
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 24,
        }
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          "&$selected": {
            backgroundColor: "red",
            "&:hover": {
              backgroundColor: "orange",
            },
          },
        },
        button: {
          "&:hover": {
            backgroundColor: "yellow",
          },
        },
      }  
    }
  }
});
config.setChartColor(theme.palette.primary.light);


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      "session": null,
      "view": "app",
      "email": null,
      "authenticated": false,
      "register": false,
      "error": null,
      "auth_error": null
    }
  }

  componentDidMount() {
    console.log("Apps did mount");
    this.createSession();
  }


  async createSession() {
    console.log("Creating Session ");
    const host=config.getAppCtx()
    const url=host+"/auth/create-session";
    let success=false;
    try {
      const response = await fetch(url, config.getRequestSettingsJson({}));
      console.log("create-session returned");
      if (response != null && response.ok) {
        const message= await response.json();
        // console.log(JSON.stringify(message));
        if (message.status_code===0) {
          success=true;
          const token=message.token;
          const apiKey=message.apiKey;
          const squareAppId = message.paymentVendorId;
          console.log("Square API: "+squareAppId);
          console.log("ApiKey"+apiKey);
          config.setToken(token);
          config.setEnv(message.env);
          config.setApiKey(apiKey);
          config.setSquareAppId(squareAppId);
          this.setState({
            "session": token,
            "error": null 
          })
        } else {
          console.log("Error: "+response.status)
        }  
      }  
    } catch (e) { }
    if (!success) {
      this.setState({"error": "Cannot connect to server!" });
    }
  }

      
  async login(email, password) {
    console.log("Calling Login");
    const host=config.getAppCtx()
    const url=host+"/auth/login";
    const data = {
      "login": email,
      "pass": password
    }
    try {
      const response = await fetch(url, config.getRequestSettingsJson(data));
      console.log("login returned");
      if (response != null && response.ok) {
        const message= await response.json();
        if (message.status_code===0) {
          config.setToken(message.token);
          config.setPermissions(message.permissions);
          console.log("Permissions: " + message.permissions);
          console.log("Authenticated!");
          timeout.start(this.timeoutHandler);
          this.setState({
            "authenticated" : true,
            "email": email,
            "view": "app",
          });
        } else {
          console.log("Error: "+message.status_text);
          this.setState({
            "authenticated" : false,
            "view": "app",
            "auth_error": message.status_text
          });
        }
        return;
      }  
    } catch (e) { }
    this.setState({"error": "Cannot connect to server!" });
  }


  async logout() {
    console.log("Calling Logout");
    const host=config.getAppCtx()
    const url=host+"/auth/logout";
    let success=false;
    try {
      const response = await fetch(url, config.getRequestSettingsJson({}));
      console.log("logout returned");
      if (response != null && response.ok) {
        success=true;
        const message= await response.json();
        if (message.status_code !== 0) {
          console.log("Error: "+response.status)
        }
        // Set token to annonymouse token
        config.setToken(message.token);
      } else if (response.status===401) {
        success=true;
      }
    } catch (e) { }

    if (success) {
      console.log("Logged out in server");
    } else {
      console.log("Error: Could not logout in server");
    }
    timeout.stop();
    this.setState({
      "authenticated" : false,
      "email": null,
      "view": "app",
    });
  }


  async extendSession() {
    console.log("Calling extend session");
    const host=config.getAppCtx()
    const url=host+"/auth/extend-session";
    let success=false;
    try {
      const response = await fetch(url, config.getRequestSettingsJson({}));
      console.log("extend session returned");
      if (response != null && response.ok) {
        const message= await response.json();
        if (message.status_code === 0) {
          success=true;
          timeout.extend(this.timeoutHandler);
        } else {
          console.log("Error: extend-session "+message.status_code);
        }
      }
    } catch (e) { }

    if (success) {
      console.log("Session extension completed in server");
    } else {
      console.log("Error: Could not logout in server");
    }
  }


  closeLoginHandler = () => {
    if (this.email !== null) {
      this.logout()
    } else {
      this.setState({
        "authenticated" : false,
        "email": null,
        "view": "app",
      });  
    }
  }
  
  
  loginHandler = (email, password) => {
    this.login(email, password);
  }

  timeoutHandler = (active) => {
    if (active) {
      console.log("Active");
      this.extendSession();
    }
    else {
      this.setState({
        "authenticated" : false,
        "view": "login",
      });  
    }
  }

  loginButtonHandler = (action) => {
    if (action==="login") {
      console.log("Logging in");
      this.setState({
        "authenticated" : false,
        "view": "login",
      });
    } else if (action==="logout") {
      console.log("Logging out");
      this.logout();
    }
  }

  registerButtonHandler = (action) => {
    this.setState({
      "register" : true
    });
  }

  closeRegister = (action) => {
    this.setState({
      "register" : false
    });
  }


  renderContent() {
    console.log("Rendering view: "+this.state.view);
    if (this.state.session !== null) {
      if (this.state.view === "app") {
        return (
        <div>
          <Main 
            onLoginClicked={this.loginButtonHandler}
            onSignupClicked={this.registerButtonHandler}
            email={this.state.email}/>
          <Register open={this.state.register} close={this.closeRegister} />
          <Snackbar open={this.state.auth_error !== null}
            autoHideDuration={6000} 
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            variant="filled"  elevation={6}
            onClose={() => { this.setState({ "auth_error": null});}}>
            <Alert severity="error" sx={{ width: '100%' }}
              onClose={() => { this.setState({ "auth_error": null});}}
            >
              <AlertTitle>Error</AlertTitle>
              {this.state.auth_error}
            </Alert>
          </Snackbar>
        </div>
        );
      } else {
        return(
          <Login 
            login={(email, password) => this.login(email, password)}
            close={this.closeLoginHandler}/>
          );
      }
    } else {
      if (this.state.error===null) {
        return(
          <Alert severity="info" color="primary">
            <AlertTitle>Info</AlertTitle>
            Waiting for Connection...
          </Alert>
        );
      } else {
        return(
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Cannot Connect to Server
          </Alert>
        );
      }
    }
  }

  render() {
    console.log("App render called");
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {this.renderContent()}
      </ThemeProvider>
    );
  }
}

export default App;
