import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';



export default function SelectColumn(props) {

  function handleSelect(event) {
    const val=event.target.value;
    const value=(val !== "*") ? val : "";
    props.onChange(value);
  };
  
  const columns=props.columns;
  const v=props.value;
  const value= (v !== "") ? v : "*";

  return(
    <FormControl variant="standard" sx={{ m: 1, minWidth: 110 }}>
      <InputLabel id="select-column-label">Column</InputLabel>
      <Select
        id="column-select"
        labelId="select-column-label"
        value={value}
        onChange={handleSelect}
        label="Column"
        size="small"
        sx={{ width: 170}}
      >
        <MenuItem value="*"><i>All Columns</i></MenuItem>
        { columns.map(name => <MenuItem key={name} value={name}>{name}</MenuItem>) }
      </Select> 
    </FormControl>
  );
}