import * as React from 'react';
// MUI Components
//import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

// Application Component
import config from '../Configuration';
import requestCache from '../Cache';
import SearchTable from './SearchTable';
import SearchBar from './SearchBar';
import MapPane from './MapPane';
import Filter from '../model/Filter';


export default class SearchView extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      "data": null,
      "search": "",
      "orderBy": "",
      "direction": "",
      "waiting": true,
      "error": null,
      "success": null,
      "showFilter": false,
      "showDetails": false,
    }
  }

  componentDidMount() {
    const stats=this.props.stats;
    if (stats.valid()) {
      console.log("SearchView did mount");
      this.runQuery(
        stats.getDatasetId(), stats.getDataset(), this.getFilter(this.state.search), 
        this.state.orderBy, this.state.direction);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const stats=this.props.stats;
    if (stats.valid()) {
      if (this.props.view==="table" && prevProps.view==="map") {
        console.log("View changed from map to table");
        if (this.state.data===null) {
          console.log("Search changed since page changed -> reloading data");
          this.runQuery(
            stats.getDatasetId(), stats.getDataset(), this.getFilter(this.state.search), 
            this.state.orderBy, this.state.direction);
        }
      }  
    }
  }

  
  async runQuery(datasetId, dataset, filter, orderBy, direction) {
    const conditions=filter.getActiveConditions();
    console.log("Called runQuery: "+datasetId);
    const host=config.getAppCtx()
    const url=host+"/services/analytics/query";

    const data = {
      "datasetId": datasetId,
      "dataset": dataset,
      "filter": {
        "conditions": conditions
      },
      "sort": {
        "orderBy": orderBy,
        "direction": direction  
      }
    }
    const response = await requestCache.fetch(url, config.getRequestSettingsJson(data));
    console.log("get-data returned");
    if (response !== null) {
      if (response.ok) {
        const message=response.data;
        if (message.status_code===0) {
          this.setState({
            "data": message.body,
            "waiting": false,
          })
        } else {
          this.setState({"error": response.status_text, waiting: false});
        }
      } else if (response.status===401) {
        console.log("Authentication expired");
        this.setState({"waiting": false})
        this.props.onAuth();
      } else {
        this.setState({"error": "HTTP Response: "+response.status_text, waiting: false});
      }
    } else {
      this.setState({
        "error": "Can't connect to the server. Please check your internet connection",
         waiting: false});
    }
  }

  
  async download(datasetId, dataset, filter, orderBy, direction, columns) {
    console.log("Calling download: "+dataset);
    console.log("Calling download: "+columns);

    const conditions=filter.getActiveConditions();
    const host=config.getAppCtx()
    const url=host+"/services/download/data";
    this.setState({waiting: true});
    const data = {
      "datasetId": datasetId,
      "dataset": dataset,
      "filter": {
        "conditions": conditions
      },
      "sort": {
        "orderBy": orderBy,
        "direction": direction  
      },
      "columns": columns
    }
    const response = await fetch(url, config.getRequestSettingsJson(data)).catch(err => {
      return null;
    });
    console.log("download/data returned");
    if (response !== null) {
      if (response.ok) {
        const data= await response.text();
        console.log("response.text() returned");
        var file = new Blob([data], {type: 'text/csv'});
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.setState({waiting: false});
        URL.revokeObjectURL(fileURL);
      } else if (response.status===401) {
        console.log("Authentication expired");
        this.setState({"waiting": false})
        this.props.onAuth();
      } else {
        this.setState({"error": "HTTP Response: "+response.status_text, waiting: false});
      }
    } else {
      this.setState({
        "error": "Can't connect to the server. Please check your internet connection",
         waiting: false});
    }
  }

  
  async saveQuery(resultName, datasetId, dataset, filter, orderBy, direction, columns) {
    console.log("Calling saveQuery: "+dataset);

    const conditions=filter.getActiveConditions();
    const host=config.getAppCtx()
    const url=host+"/services/data/save-query";
    this.setState({waiting: true});
    const data = {
      "resulDataset": resultName,
      "query": {
        "datasetId": datasetId,
        "dataset": dataset,
        "filter": {
          "conditions": conditions
        },
        "sort": {
          "orderBy": orderBy,
          "direction": direction  
        },
        "columns": columns  
      }
    }
    const response = await fetch(url, config.getRequestSettingsJson(data)).catch(err => {
      return null;
    });
    console.log("saveQuery returned");
    if (response !== null) {
      if (response.ok) {
        console.log("saveQuery OK");
        this.setState({
          success: resultName +" saved",
          waiting: false
        });
        this.props.refresh();
      } else if (response.status===401) {
        console.log("Authentication expired");
        this.setState({"waiting": false})
        this.props.onAuth();
      } else {
        this.setState({"error": "HTTP Response: "+response.status_text, waiting: false});
      }
    } else {
      this.setState({
        "error": "Can't connect to the server. Please check your internet connection",
         waiting: false});
    }
  }


  sortHandler = (selected) => {
    console.log("sortHandler passed: "+selected);
    const stats=this.props.stats;
    const directionBefore=this.state.direction;
    let direction="";
    let field=selected;
    
    // State transition based on previous order by column and previous sort direction
    if (selected !== this.state.orderBy) {
      direction="a";
    } else {
      console.log("Order before: "+directionBefore);
      if (directionBefore==="a") {
        direction="d";
      } else if (directionBefore==="d") {
        direction="";
        field="";
      } else {
        direction="a";
      }
    }
    this.setState({
      "orderBy": field,
      "direction": direction,
      "waiting": true,
    })
    console.log("sortHandler sort by: "+field+" - "+direction);
    this.runQuery(
      stats.getDatasetId(), stats.getDataset(), this.getFilter(this.state.search), 
      field, direction);
  }


  getFilter(text) {
    return this.constructFilter(this.props.filter, text);
  }

  constructFilter(filter, text) {
    console.log("constructFilter: "+JSON.stringify(filter));
    if (filter.isDisabled()) {
      let search=new Filter();
      return search.addSearch(text);; 
    } else {
      return filter.addSearch(text);
    }
  }


  handleSearch = (text) => {
    const stats=this.props.stats;
    console.log("handleSearch called: "+text+" in all columns");
    if (this.props.view==="table") {
      this.setState({
        "data": null,
        "search": text,
        "waiting": true
      });
      this.runQuery(stats.getDatasetId(), stats.getDataset(), this.getFilter(text), this.state.orderBy, this.state.direction);
    } else {
      this.setState({
        "data": null,
        "search": text
      });
    }
  }


  handleUpdateFilter = (filter, search) => {
    console.log("handleUpdateFilter");
    console.log(JSON.stringify(filter));
    const stats=this.props.stats;
    this.setState({
      "waiting": true,
      "search": search,
    });
    this.props.onUpdateFilter(filter);
    const runFilter=filter.addSearch(search);
    this.runQuery(stats.getDatasetId(), stats.getDataset(), runFilter, this.state.orderBy, this.state.direction);
  }

  
  handleAddFilter = (search) => {
    console.log("Add to search: "+search+" in all columns");
    if (search.trim().length > 0) {
      const update=(search !== this.state.search);
      this.setState({
        "search": "",
      });
      const filter = this.props.filter.addSearch(search);
      this.props.onUpdateFilter(filter);
      if (update) {
        const stats=this.props.stats;
        this.runQuery(stats.getDatasetId(), stats.getDataset(), filter, this.state.orderBy, this.state.direction);        
      }
    } else {
      console.log("Condition value is missing - not added")      
    }
  }


  handleViewSelect = (view) => {
    this.props.onChangeView(view);
  }


  handleDownload = () => {
    console.log("Download file");
    const stats=this.props.stats;
    const showColumns=this.props.showColumns;
    const data=this.state.data;
    const filter=this.getFilter(this.state.search);
    // Get columns in the same order as displayed
    const columns = data.cols.filter((col) => showColumns.has(col.name)).map((col)=> col.name);
    this.download(stats.getDatasetId(), stats.getDataset(), 
      filter, this.state.orderBy, this.state.direction, columns);
  }


  handleSave = (name) => {
    console.log("Save file");
    const stats=this.props.stats;
    const showColumns=this.props.showColumns;
    const data=this.state.data;
    const filter=this.getFilter(this.state.search);
    // Get columns in the same order as displayed
    const columns = data.cols.filter((col) => showColumns.has(col.name)).map((col)=> col.name);
    this.saveQuery(name, stats.getDatasetId(), stats.getDataset(), 
      filter, this.state.orderBy, this.state.direction, columns);
  }

  clearMessage = () => {
    this.setState({ 
      "error": null,
      "success": null
    });
  }


  renderTableView(data, showColumns) {
    console.log("rendering table view");
    // Show counts
    if (data!== null) {
      const rows=data.rows;
      const totalCount=data.totalCount;
      const rowCount=data.rowCount;
      const subtitle= (rowCount < totalCount) 
        ? "Showing the first "+rowCount+" rows out of "+totalCount+" rows" 
        : "Found "+rowCount+" matching rows";

      // Convert columns to Datagrid format - not used anymore but for compatibility
      const columns = data.cols.filter((col) => showColumns.has(col.name))
        .map((col, index) => {
          const numeric= (col.dataType==='LONG') || (col.dataType==='DOUBLE');
          if (showColumns.has(col)) {}
          return {
            field: col.name,
            headerName: col.name,
            type: numeric ? 'number' : '',
            width: numeric ? 100 : 150
          }
      });
      return(
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', mt: 2}}>
          <Typography variant="caption" gutterBottom display="block" sx={{pl:1, pt:1, pb: 2}}>{subtitle}</Typography>
          <SearchTable rows={rows} columns={columns}
            orderBy={this.state.orderBy}
            direction={this.state.direction}
            onSort={this.sortHandler}
          />
        </Box>
      );
    } else {
      console.log("renderTableView: data is null");
      return null;
    }
  }


  renderView(view, data, showColumns) {
    const stats=this.props.stats;    
    switch(view) {
      case "table":
        return(
          this.renderTableView(data, showColumns)
        );
      case "map":
        const filter=this.getFilter(this.state.search);
        return(
          <MapPane 
            onAuth={this.props.onAuth}
            stats={stats}
            filter={filter}
            showColumns={this.props.showColumns}/>
        );
      default:
        console.log("Error: Unsupported pane "+view);
        return null;
    }
  }


  render() {
    const stats=this.props.stats;
    const filter=this.props.filter;
    const signedIn=this.props.signedIn;
    const showColumns=this.props.showColumns;

    const success=this.state.success;
    const error=this.state.error;
    const data=this.state.data;
    const hasMap=stats.hasCoordinates();


    if (stats.valid()) {
      // find column names for column selector
      const columns=stats.getFields();
      return (
        <div style={{ height: 650, width: '100%' }}>
          <SearchBar 
            columns={columns}
            onSubmit={this.handleSearch}
            hasMap={hasMap}
            view={this.props.view}
            filter={filter}
            signedIn={signedIn}
            onViewButton={this.handleViewSelect}
            onDownload={this.handleDownload}
            onAddFilter={this.handleAddFilter}
            onUpdateFilter={this.handleUpdateFilter}
            onSave={this.handleSave}/>
          {this.renderView(this.props.view, data, showColumns)}
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={this.state.waiting}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Snackbar open={this.state.success !== null} 
            autoHideDuration={3000} 
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            variant="filled"  elevation={6}
            onClose={this.clearMessage}>
            <Alert onClose={this.clearMessage} severity="success" sx={{ width: '100%' }}>
              <AlertTitle>Success</AlertTitle>
              {success}
            </Alert>
          </Snackbar>
          <Snackbar open={error !== null}
            autoHideDuration={6000} 
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            variant="filled"  elevation={6}
            onClose={this.clearMessage}>
            <Alert onClose={this.clearMessage} severity="error" sx={{ width: '100%' }}>
              <AlertTitle>Error</AlertTitle>
              {error}
            </Alert>
          </Snackbar>
        </div>
      );
    } else {
      return null;
    }
  }
}