import * as React from 'react';
// mui components
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
// rechart
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
// Application
import config from '../Configuration';
import Condition from '../model/Condition';
import BackTitle from '../components/BackTitle';
import ChartDialog from '../components/ChartDialog';
import { scalePercent } from '../Utils';

function HistogramMeasure(props) {
  const width=props.width;
  const rows=props.rows;
  const label=props.column;
  const clickHandler=props.onClick;

  const data=props.data.map((e) => {
    const label = (props.width === 1) ? e.start.toFixed(0) : e.start;
    const formattedValue = (e.c*100.0/rows).toFixed(2);
    return {v: label, Percent: formattedValue } 
  });
  const maxY=scalePercent(props.data, rows);

  return(
    <ResponsiveContainer width="100%" height={480}>
      <BarChart
        width={300}
        height={500}
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        isAnimationActive={true}
        onClick={clickHandler}>
        <XAxis dataKey="v" type="category"/>
        <YAxis unit="%" domain={[0, maxY]}/>
        <Tooltip labelFormatter={(start) => {
            // start is passed as String
            if (width === 1) {
              return label+": "+start;
            } else {
              const top=parseFloat(start)+width;
              return label+": [" + start + "," + top + ")";  
            }
        }} />
        <Bar dataKey="Percent" fill={config.getChartColor()} />
      </BarChart>
    </ResponsiveContainer>
  );
}


export default class MeasureDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      "graph": null
    }
  }

  componentDidMount() {
    console.log("MeasureDetails did mount");
  }

  addHandler = (graph) => {
    const label=graph.activeLabel;
    const filter=this.props.filter.copy();
    const measure=this.props.measure;
    const dataType = measure.dataType;
    const measureName=measure.name;
    const hist=measure.histogram;
    const width=hist.binWidth;

    if (width === 1)  {
      // Categorical
      const labels = [ label ];
      const condition = new Condition(measureName, "eq", labels);
      condition.setDataType(dataType);
      filter.addConditon(condition);
    } else {
      // Continous
      const start = [ label.toString() ];
      const stop = [ (label+width).toString() ];
      const condition1 = new Condition(measureName, "ge", start);
      condition1.setDataType(dataType);
      filter.addConditon(condition1);
      const condition2 = new Condition(measureName, "lt", stop);
      condition2.setDataType(dataType);
      filter.addConditon(condition2);
    }
    this.props.onUpdateFilter(filter);
    this.setState({
      "graph": null
    });
  }

  handleOpenFilterMenu = (graph) => {
    if (this.props.filter) {
      this.setState({
        "graph": graph 
      });
    }
  };

  handleCloseFilterMenu = () => {
    this.setState({
      "graph": null
    });
  };


  render() {
    // properties
    const measure=this.props.measure;
    const clickHandler=this.props.onClick;
    const filter=this.props.filter;
    // format
    var locale = 'en';
    var options = {style: 'decimal', maximumFractionDigits: 3, useGrouping:false};
    var f = new Intl.NumberFormat(locale, options);
    if (measure.histogram !== undefined) {
      // convenience
      const hist=measure.histogram;
      const data=hist.bins;
      const width=hist.binWidth;
      const range="["+measure.min+", "+measure.max+"]";
      const meanFormatted= (measure.mean !== null) ? (f.format(measure.mean) +" (+/-"+f.format(measure.stdDev)+")") : " - ";
      const medianFormatted = (measure.median !== null) ? f.format(measure.median) : " - ";
  
      return(
        <div>
          <BackTitle onClick={clickHandler}>
            Details {measure.name}
          </BackTitle>
          <Grid container spacing={2}>
            <Grid item xs={9} key={measure.name}>
              <HistogramMeasure 
                column={measure.name} 
                data={data} 
                rows={hist.count} 
                width={width}
                onClick={this.handleOpenFilterMenu}/>
              <ChartDialog
                graph={this.state.graph}
                filter={filter}
                onClose={this.handleCloseFilterMenu}
                onAddFilter={this.addHandler}/>
            </Grid>
            <Grid item xs={3} key={"Info"}>
              <List dense sx={{ height: '100%', width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <ListItem>
                  <ListItemText primary={hist.count} secondary="Total Rows" />
                </ListItem>
                <ListItem>
                  <ListItemText primary={measure.missing} secondary="Missing Values" />
                </ListItem>
                <ListItem>
                  <ListItemText hidden={meanFormatted===null} primary={medianFormatted} secondary="Median" />
                </ListItem>
                <ListItem>
                  <ListItemText primary={range} secondary="Range" />
                </ListItem>
                <ListItem >
                  <ListItemText hidden={meanFormatted===null} primary={meanFormatted} secondary="Mean (+/- Standard Deviaton" />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </div>
      );  
    } else {
      return(
        <BackTitle onClick={clickHandler}>
          Details {measure.name}
      </BackTitle>
      );
    }
  }
}