import * as React from 'react';
// Material UI
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

// Icons
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';

// Application Component
import config from './Configuration';
import requestCache from './Cache';
import Stats from './model/Stats';
import Copyright from './Copyright';
import mainTabs from './mainTabs';
import About from './About';
// Application Views
import DataSetView from './datasets/DatasetView';
import SearchView from './search/SearchView';
import ColumnView from './column-view/ColumnView';
import FrequencyView from './frequency-view/FrequencyView';
import CorrelationView from './correlation/CorrelationView';
import AggregationView from './aggregation-view/AggregationView';
import TimeSeriesView from './aggregation-view/TimeSeriesView';
import PivotView from './pivot/PivotView';
import PaymentView from './purchase/PaymentView';
import Filter from './model/Filter';
import MessageDialog from './MessageDialog';

// Class constants
const drawerWidth = 240;


const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
 }) (({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


const AppDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);


export default class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state={ 
      "open": true,
      "view": "datasets",
      "datasets": null,
      "datasetId": -1,
      "dataset": "",
      "displayName": "",
      "metadata": null,
      "statsFiltered": null, 
      "selectedMeasure": "", 
      "showColumns": new Set(),
      "filter": new Filter([]),
      "error": null,
      "waiting": false,
      "environment": "Development",
      "messages": [],
      "pane": "table",
      "showMessageDialog": false,
    }
    this.refreshTimer = 0;
  }

  componentDidMount() {
    console.log("Main did mount");
    this.getDatasets();
    this.periodicRefresh();
    this.refreshTimer = setInterval(() => { this.periodicRefresh() }, 60*1000);
  }

  
  componentDidUpdate(prevProps, prevState) {
    console.log("Main did update");
    if (this.props.email !== prevProps.email) {
      requestCache.clear();
      console.log("User changed reloading datasets");
      this.setState({ 
        "view": "datasets",
        "datasets": null,
        "datasetId": -1,
        "dataset": "",
        "displayName": "",
        "metadata": null,
        "statsFiltered": null, 
        "selectedMeasure": "", 
        "showColumns": new Set(),
        "filter": new Filter([]),
        "messages": [],
        "error": null,
        "waiting": true
      });
      this.getDatasets();
    }
  }

  componentWillUnmount() {
    console.log("Refresh timer removed");
    clearInterval(this.refreshTimer);
  }

  periodicRefresh() {
    console.log("period refresh Called");
    if (this.props.email !== null) {
      this.getMessages();
    }
  }

  
  async getDatasets() {
    console.log("Calling getDatasets: ");
    const host=config.getAppCtx()
    const url=host+"/services/data/get-datasets";
    const response = await fetch(url, config.getRequestSettingsJson({})).catch(err => {
      this.setState({"error": "Can't connect to the server. Please check your internet connection"});
      return null;
    });
    console.log("get-datasets returned");
    if (response !== null) {
      if (response.ok) {
        const message= await response.json();
        if (message.status_code===0) {
          this.setState({
            "datasets": message.body,
            "waiting": false
          })
        } else {
          this.setState({"error": response.status_text, waiting: false});
        }  
      } else if (response.status===401) {
        console.log("Authentication expired");
        this.props.onLoginClicked("login");
      } else {
        this.setState({"error": "HTTP Response: "+response.status_text, waiting: false});
      }
    } else {
      this.setState({
        "error": "Can't connect to the server. Please check your internet connection",
        waiting: false
      });
    }
  }

  
  async getMessages() {
    console.log("Calling getMessages");
    const host=config.getAppCtx()
    const url=host+"/services/message/get-messages";
    const response = await fetch(url, config.getRequestSettingsJson({})).catch(err => {
      this.setState({"error": "Can't connect to the server. Please check your internet connection"});
      return null;
    });
    console.log("get-messages returned");
    if (response !== null) {
      if (response.ok) {
        const message= await response.json();
        if (message.status_code===0) {
          this.setState({
            "messages": message.messages
          })
        } else {
          this.setState({"error": response.status_text, waiting: false});
        }  
      } else if (response.status===401) {
        console.log("Authentication expired");
        this.props.onLoginClicked("login");
      } else {
        this.setState({"error": "HTTP Response: "+response.status_text, waiting: false});
      }
    } else {
      this.setState({
        "error": "Can't connect to the server. Please check your internet connection"
      });
    }
  }

  
  async markViewed(ids) {
    console.log("Calling markViewed");
    const host=config.getAppCtx()
    const url=host+"/services/message/mark-viewed";
    const body = { "ids": ids }
    const response = await fetch(url, config.getRequestSettingsJson(body)).catch(err => {
      this.setState({"error": "Can't connect. Please check your internet connection", "waiting": false});
      return null;
    });
    console.log("mark viewed returned");
    if (response !== null) {
      if (response.ok) {
        const message= await response.json();
        if (message.status_code ===0) {
          this.setState({
            "messages": [],
            waiting: false
          })
        } else {
          this.setState({"error": response.status_text, waiting: false});
        }  
      } else if (response.status===401) {
        console.log("Authentication expired");
        this.props.onLoginClicked("login");
      } else {
        this.setState({"error": "HTTP Response: "+response.status_text, waiting: false});
      }
    } else {
      this.setState({ "error": "Can't connect. Please check your internet connection", "waiting": false});
    }
  }

  
  async getStats(id, name) {
    console.log("Calling getMetadata: "+id);
    requestCache.clear();
    const host=config.getAppCtx()
    const url=host+"/services/analytics/get-stats";
    const data = {
      "datasetId": id,
      "datasetName": name,
    }
    const response = await fetch(url, config.getRequestSettingsJson(data)).catch(err => {
      return null;
    });
    console.log("get-metadata returned");
    if (response !== null) {
      if (response.ok) {
        const message= await response.json();
        if (message.status_code===0) {
          const md = message.body;
          const stats = new Stats(id, md);
          const pane = stats.hasCoordinates() ? "map" : "table";
          const columns = md.fields.map((field) => field.name);
          const showColumns = new Set(columns);
          this.setState({
            "datasetId": id,
            "dataset": name,
            "displayName": md.displayName,
            "metadata": md,
            "statsFiltered": null, 
            "view": "summary",
            "showColumns": showColumns,
            "filter": new Filter([]),
            "selectedMeasure": "", 
            "waiting": false,
            "pane": pane
          })
        } else {
          this.setState({"error": response.status_text, waiting: false});
        }
      } else if (response.status===401) {
        console.log("Authentication expired");
        this.handleAuth();
      } else {
        this.setState({"error": "HTTP Response: "+response.status_text, waiting: false});
      }
    } else {
      this.setState({
        "error": "Can't connect to the server. Please check your internet connection",
         waiting: false});
    }
  }

  
  async updateStats(id, filter) {
    console.log("Calling getMetadata: "+id);
    requestCache.clear();
    const host=config.getAppCtx()
    const url=host+"/services/analytics/get-stats";
    const conditions=filter.getActiveConditions();
    const data = {
      "datasetId": id,
      "filter": {
        "conditions": conditions
      },
    }
    const response = await requestCache.fetch(url, config.getRequestSettingsJson(data));
    console.log("update stats returned");
    if (response !== null) {
      if (response.ok) {
        const message=response.data;
        if (message.status_code===0) {
          const md = message.body;
          const stats = new Stats(id, md);
          this.setState({
            "filter": filter,
            "statsFiltered": stats,
            "waiting": false,
          })
        } else {
          this.setState({"error": response.status_text, waiting: false});
        }
      } else if (response.status===401) {
        console.log("Authentication expired");
        this.handleAuth();
      } else {
        this.setState({"error": "HTTP Response: "+response.status_text, waiting: false});
      }
    } else {
      this.setState({
        "error": "Can't connect to the server. Please check your internet connection",
         waiting: false});
    }
  }


  handleAuth=() => {
    console.log("Handle Authentiocation passed to main");
    this.props.onLoginClicked("login");
  }


  handleClearMessages = (ids) => {
    console.log("handle clearMessages called")
    this.setState({ 
      "showMessageDialog": false,
      "waiting": true
    })
    if (ids.length > 0) {
      this.markViewed(ids);
    }
  }


  toggleDrawer = () => {
    this.setState({"open": !this.state.open})
  };

  viewHandler = (v) => {
    this.setState({"view": v });
  }

  viewDatasetHandler = () => {
    this.setState({"view": "datasets"});
  }

  handleSelectDataset = (id, name) => {
    console.log("click file: "+name+" - "+ id);
    this.setState({waiting: true});
    this.getStats(id, name);
  }

  handleUpdateFilter = (filter) => {
    console.log("Main update filter");
    this.setState({ 
      "waiting": true
    });
    this.updateStats(this.state.datasetId, filter);
  }

  handleSelectColumn = (name) => {
    this.setState({ 
      "selectedMeasure": name, 
    });
  }

  handleShowChange = (columns, value) => {
    console.log("Show columns: "+columns+"="+value);
    const result = this.state.showColumns;
    for (const column of columns) {
      if (value) {
        result.add(column)
      } else {
        result.delete(column);
      }
    }
    this.setState({
      "showColumns": result,
    })
  }

  handlePane = (selection) => {
    this.setState({ 
      "pane": selection, 
    });
  }

  refresh = () => {
    requestCache.clear();
    this.getDatasets();
  }

  renderLogin(email, login, register) {
    const text=(email===null) ? "login" : "logout";
    if (email === null) {
      return(
        <React.Fragment>
          <Button
            color="secondary" 
            onClick={register}>Sign up
          </Button>
          <Button 
            color="inherit" 
            onClick={() => login(text)}>
            {text}
          </Button>
        </React.Fragment>
      );  
    } else {
      return(
        <Button 
          color="inherit" 
          onClick={() => login(text)}>{text}
        </Button>
      );  
    }
  }

  getHeader() {
    const name = "Data Lumen";
    const env=config.getEnv();
    switch (env) {
      case "prod":
        return name;
      case "dev":
        return name + " - Development";
      default:
        return name + " - Environment: "+this.env;
    }
  }


  renderView(email, stats) {
    console.log("Render Main View");
    const filter=this.state.filter;
    const signedIn= (email !== null);

    switch (this.state.view) {        
      case "summary":
      case "columns":
        if (stats.valid()) {
          return(
            <ColumnView 
              stats={stats} 
              showColumns={this.state.showColumns}
              filter={filter}
              onUpdateFilter={this.handleUpdateFilter}
              onShowChange={this.handleShowChange}/>
          );
        }
        break;

      case "histogram":
        if (stats.valid()) {
          const filtered = this.state.statsFiltered;
          const histogramStats = filtered != null ? filtered : stats;
          return(
            <FrequencyView
              stats={histogramStats} 
              showColumns={this.state.showColumns}
              filter={filter}
              onUpdateFilter={this.handleUpdateFilter}/>
          );
        }
        break;

      case "datasets":
        if (this.state.datasets !== null) {
          return(
            <DataSetView 
              onAuth={this.handleAuth}
              email={email}
              datasets={this.state.datasets} 
              selected={this.state.dataset}
              onClick={this.handleSelectDataset} 
              refresh={this.refresh}/>
          );  
        }
        return;

        case "search":
          if (stats.valid()) {
            return(
              <SearchView
                onAuth={this.handleAuth}
                signedIn={signedIn}
                stats={stats} 
                view={this.state.pane}
                showColumns={this.state.showColumns}
                filter={filter}
                onUpdateFilter={this.handleUpdateFilter}
                onChangeView={this.handlePane}
                refresh={this.refresh}/>  
            );
          }
          break;

      case "correlation":
        if (stats.valid()) {
          return(
            <CorrelationView
              stats={stats} 
              showColumns={this.state.showColumns}
              filter={filter}
              onUpdateFilter={this.handleUpdateFilter}
              onAuth={this.handleAuth}/>);
        }
        break;

      case "aggregation":
        if (stats.valid()) {
          return(
            <AggregationView
              onAuth={this.handleAuth}
              stats={stats}
              measure={this.state.selectedMeasure}
              showColumns={this.state.showColumns}
              filter={filter}
              onUpdateFilter={this.handleUpdateFilter}
              onSelectColumn={this.handleSelectColumn}/>);
        }
        break;

      case "timeseries":
        if (stats.valid()) {
          return(
            <TimeSeriesView
              onAuth={this.handleAuth}
              stats={stats}
              filter={filter}
              showColumns={this.state.showColumns}
              onUpdateFilter={this.handleUpdateFilter}/>);
        }
        break;

      case "pivot":
        if (stats.valid()) {
          return(
            <PivotView
              onAuth={this.handleAuth}
              stats={stats}
              filter={filter}
              onUpdateFilter={this.handleUpdateFilter}
              measure={this.state.selectedMeasure}
              showColumns={this.state.showColumns}
              onSelectColumn={this.handleSelectColumn}/>);
        }
        break;

      case "map":
        if (stats.valid()) {
          //return(<MapView metadata={this.state.metadata} />);
          return(<SearchView 
            onAuth={this.handleAuth}
            metadata={this.state.metadata} 
            showColumns={this.state.showColumns}
            pane="map" />);
        }
        break;

      case "purchase":
        return(<PaymentView
          email={email}
          onAuth={this.handleAuth}/>);
    
      case "about":
        return(<About/>);

      default:
        return(
          <Alert severity="info">
            <AlertTitle>Info</AlertTitle>
            Coming real soon, now...
          </Alert>
        );
    }  
    return(
      <Alert severity="info">
        <AlertTitle>Info</AlertTitle>
        Upload or click on a <i>Data Set</i> to see information in this view.
     </Alert>
    );
  };


  render() {
    const login=this.props.onLoginClicked;
    const register=this.props.onSignupClicked;
    const email=this.props.email;
    const md=this.state.metadata;
    const datasetId=this.state.datasetId;
    const displayName=this.state.displayName;
    const stats=new Stats(datasetId, md);
 
    return (
      <Box sx={{ display: 'flex' }}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.waiting}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <CssBaseline />
        <StyledAppBar position="absolute" open={this.state.open} >
          <Toolbar variant="dense"
            sx={{pr: '24px' }}>
            <IconButton edge="start" color="inherit" aria-label="open drawer"
              onClick={this.toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(this.state.open && { display: 'none' }),
              }}
            ><MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
              {this.getHeader()}
            </Typography>
            <Typography variant="subtitle1" component="span" sx={{pr: 4}}>
              {(this.props.email !== null) ? this.props.email : ""}
            </Typography>
            {this.renderLogin(email, login, register)}
            <IconButton color="inherit"
              onClick={() => this.setState({"showMessageDialog": true})}>
              <Badge badgeContent={this.state.messages.length} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </StyledAppBar>
        <AppDrawer variant="permanent" open={this.state.open}>
          <Toolbar variant="dense"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}>
            <IconButton onClick={this.toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          {mainTabs(this.state.view, this.viewHandler, stats, this.props.email)}
        </AppDrawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) => theme.palette.mode === 'light'? theme.palette.white : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}>
          <Toolbar />
          <Breadcrumbs aria-label="breadcrumb" sx={{marginLeft: 4}}>
            <Link underline="always" color="inherit" onClick={this.viewDatasetHandler}>
              Datasets
            </Link>
            <Link color="text.primary" underline="none" aria-current={this.state.dataset}>
              {displayName}
            </Link>
          </Breadcrumbs>
          <Container maxWidth="xl" sx={{ mt: 2, mb: 4 }}>
            {this.renderView(email, stats)}
            <Copyright sx={{ pt: 2 }} />
          </Container>
          <MessageDialog 
            open={this.state.showMessageDialog}
            messages={this.state.messages}
            onClose={() => { this.setState({ "showMessageDialog": false}); }}
            onClear={this.handleClearMessages}/>
          <Snackbar open={this.state.error !== null}
            autoHideDuration={6000} 
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            variant="filled"  elevation={6}
            onClose={() => { this.setState({ "error": null});}}>
            <Alert severity="error" sx={{ width: '100%' }}
              onClose={() => { this.setState({ "error": null});}}>
              <AlertTitle>Error</AlertTitle>
              {this.state.error}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    );  
  }
}
