import * as React from 'react';
// Material
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';


function PivotColumnHeader(props) {
  const formatter=props.labelFormatter;
  const labels=props.labels;
  let result = labels.map(entry => {
    const label= formatter(entry); 
    return <TableCell key={label} align="right">{label}</TableCell>
  });
  return result;
}


function PivotData(props) {
  const rowLabels=props.rowLabels;
  const colLabels=props.colLabels;
  const values=props.values;
  const format=props.valueFormat;
  const labelFormatter=props.labelFormatter;

  const colOrder=colLabels.map(label => label.index);
  let result=[];
  for (let i=0; i < rowLabels.length; i++) {
    const rowLabel = rowLabels[i];
    const row = rowLabel.index;
    const label = labelFormatter(rowLabel); 
    result.push(
      <TableRow key={i}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell key={label} align="left">{label}</TableCell>
        <PivotDataRow
          key={i}
          order={colOrder}
          row={values[row]}
          valueFormat={format}/>
      </TableRow>
    );        
  }
  return result;
}


function PivotDataRow(props) {
  const row=props.row;
  const f=props.valueFormat;
  const order=props.order;

  let result=[];
  for (let i=0; i<row.length; i++) {
    const col=order[i];
    const value=row[col];
    const formattedValue = (value !== null) ? f.format(value) : "--";
    result.push(
      <TableCell 
        key={col} 
        align="right">
        {formattedValue}
      </TableCell>
    );  
  }
  return result;
}


export default class PivotTable extends React.Component {

  maxAbsValue(values) {
    let result=0;
    for (let row=0; row<values.length; row++) {
      for (let col=0; col<values[row].length; col++) {
        result = Math.max(result, Math.abs(values[row][col]));
       }
    }
    return result;
  }

  findFormat(values) {
    const maxValue = this.maxAbsValue(values);
    let locale = 'en';  
    const options= (maxValue >= 10000)
      ? {style: 'decimal', maximumFractionDigits: 0, useGrouping: true}
      : {style: 'decimal', maximumFractionDigits: 2, useGrouping:false};
    const format = new Intl.NumberFormat(locale, options); 
    return format;
  }

  makeFormatter(subType) {
    if (subType==="year-month") {
      return this.formatYearMonthLabel
    } else {
      return this.formatString;
    }
  }

  formatYearMonthLabel(entry) {
    const value=entry.value;
    const year=Math.round(value/100);
    const month=Math.round(value % 100);
    const sep = (month < 10) ? "-0" : "-";
    return year+sep+month;
  }

  formatString(entry) {
    const name = entry.name;
    return (name !== "") ? name : "#Missing"; 
  }


  sortedValues(labels) {
    const type=labels.dataType;
    const values=labels.values.slice();
    if (type==="String") {
      //console.log("String Sort");
      values.sort((a,b) => a.name.localeCompare(b.name));
    } else if (type==="Integer") {
      //console.log("Integer Sort");
      values.sort((a,b) => (a.value-b.value));
    } else {
      console.log("Not sorting - unknonw datatype: "+type);
    }
    return values;
  }

  render() {
    const data=this.props.data;
    const rowInfo=data.rowLabels;
    const colInfo=data.columnLabels;
    const rowLabels = this.sortedValues(rowInfo);
    const colLabels = this.sortedValues(colInfo);
    const values = data.values;

    if (data != null) {
      const format=this.findFormat(values);
      const rowLabelFormatter = this.makeFormatter(rowInfo.subType);
      const colLabelFormatter = this.makeFormatter(colInfo.subType);
      return(
        <div>
          <TableContainer component={Paper}  sx={{ maxHeight: 440 }}>
            <Table sx={{ minWidth: 650 }} aria-label="Pivot Table" stickyHeader={true} size='small'	>
              <TableHead>
                <TableRow key="tableHeader">
                  <TableCell key="title" align="left"></TableCell>
                  <PivotColumnHeader 
                    labels={colLabels}
                    labelFormatter={colLabelFormatter}/>
                </TableRow>
              </TableHead>
              <TableBody>
                <PivotData
                  rowLabels={rowLabels}
                  colLabels={colLabels}
                  values={values}
                  valueFormat={format}
                  labelFormatter={rowLabelFormatter}/>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    }
  }
}