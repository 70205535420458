// Utiltiy functions for conditions

export default class Condition {
  column: string;
  op: string;
  values: string[];
  dataType: string;

  constructor(aColumn: string, anOp: string, aValueArray: string[]) {
    this.column=aColumn;
    this.op=anOp;
    this.values=aValueArray;
    this.dataType="String";
  }

  copy(): Condition {
    const result: Condition = new Condition(this.column, this.op, this.values);
    result.dataType = this.dataType;
    return result;
  }

  getDataType(): string {
    return this.dataType;
  }

  setDataType(aDataType: string) {
    this.dataType = aDataType;
  }

  getOp(): string {
    return this.op;
  }

  setOp(anOp: string): void {
    this.op=anOp;
  }

  getColumn(): string {
    return this.column;
  }

  getValue(index: number): string|null {
    if (this.values.length > 0) {
      return this.values[index];
    }
    return null;
  }

  getValues(): string[] {
    return this.values;
  }

  setValues(aValueArray: string[]) : void {
    this.values =  aValueArray;
  }

  setColumn(aColumn: string, aDataType: string) : void {
    this.column = aColumn;
    this.dataType = aDataType;
  }

  isEqual(c: Condition): boolean {
    if ((this.column === c.column) && (this.op === c.op)) {
      const v1: string[] = this.values;
      const v2: string[] = c.values;
      if (v1.length === v2.length) {
        for (let i=0; i<v1.length; i++) {
          if (v1[i] !== v2[i]) {
            return false;
          }
        }
        return true;
      }
    }
    console.log("Conditions not same");
    return false;
  }

  validValue(): boolean {
    const op: string = this.op;
    const values: string[] = this.values;
    const value: string|null = (values !== null && values.length >= 1) ? values[0] : null;

    if ((op === "null") || (op === "not_null")) {
      return true;
    } 
    else if (this.dataType === "Date") {
      if (value != null) {
        return ((value.length === 10) && (/\d\d\d\d-\d\d-\d\d/.test(value)));
      } else {
        return false;
      }
    } else  {
      return value !== null;
    }
  }

  validColumn(): boolean {
    return ((this.column !== "") || (this.op === "contains") || (this.op === "null") || (this.op === "not_null"));
  }

  validOperator(): boolean {
    switch (this.op) {
      case "contains":
        return this.dataType === "String";
      case "in":
        return (this.dataType === "String") || (this.dataType === "Integer");
      case "eq":
      case "ne":
        return (this.column !== "");
      case "ge":
      case "le":
      case "gt":
      case "lt":
        return (this.dataType === "Integer") || (this.dataType === "Fractional") || (this.dataType === "Date");
      default:
        return true;
    }
  }  


  valid(): boolean {
    return  this.validValue() && this.validColumn() && this.validOperator();
  }

  getFormatted(): string {
    if (this.column === "") {
      switch (this.op) {
        case "contains":
          return "Search for " + this.getValue(0);
        case "null":
          return "Any column has missing value";
        case "not_null":
          return "No column has missing value";
        default:
          return "Unknown Filter";
        }
    } else {
      let filterText: string = this.column + " " + this.getFormattedOp();
       if ((this.values.length === 0) || (this.op === "null") || (this.op === "not_null")) {
        return filterText;
      } else if (this.values.length === 1) {
        return filterText + " " + this.getValue(0);
      } else {
        return filterText + " Multiple";
      }
    } 
  }

  getFormattedOp(): string {
    switch (this.op) {
      case "contains":
        return "contains";
      case "in":
        return "in";
      case "eq":
        return "=";
      case "ne":
        return "<>";
      case "gt":
        return ">";
      case "lt":
        return "<";
      case "ge":
        return ">=";
      case "le":
        return "<=";
      case "null":
        return "is missing";
      case "not_null":
        return "is not missing";
      default:
        return "";
    }
  }  
}