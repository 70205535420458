import * as React from 'react';
// Material
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
// Material Icons
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';


export default class DatasetTable extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      "orderBy": "Dataset Name",
      "direction": "asc"
    }
  }

  sortHandler = (selected) => {
    console.log("sortHandler passed: "+selected);
    const directionBefore=this.state.direction;
    let direction="";
    let field=selected;

    // State transition based on previous order by column and previous sort direction
    if (selected !== this.state.orderBy) {
      direction="asc";
    } else {
      console.log("Order before: "+directionBefore);
      if (directionBefore==="asc") {
        direction="desc";
      } else if (directionBefore==="desc") {
        direction="asc";
      }
    }
    console.log("sortHandler sort by: "+field+" - "+direction);
    this.setState({ 
      "orderBy": field,
      "direction": direction
    });
  }


  sortDatasets(datasets, field, direction) {
    console.log("Sort called: "+field+" "+direction)
    if (field !== "") {
      switch (field) {
        case "Dataset Name":
          if (direction==="asc") {
            datasets.sort((a, b) => a.displayName.localeCompare(b.displayName));
          } else {
            datasets.sort((a, b) => b.displayName.localeCompare(a.displayName));  
          }
          break;
        case "Owner":
          if (direction==="asc") {
            datasets.sort((a, b) => a.owner.localeCompare(b.owner));
          } else {
            datasets.sort((a, b) => b.owner.localeCompare(a.owner));  
          }
          break;
        case "Shared":
            if (direction==="asc") {
              datasets.sort((a, b) => a.shared.localeCompare(b.shared));
            } else {
              datasets.sort((a, b) => b.shared.localeCompare(a.shared));  
            }
            break;
        case "Rows":
          if (direction==="asc") {
            datasets.sort((a, b) => a.metadata.rowCount - b.metadata.rowCount);
          } else {
            datasets.sort((a, b) => b.metadata.rowCount - a.metadata.rowCount);  
          }
          break;
        case "Created":
          if (direction==="asc") {
            datasets.sort((a, b) => a.created - b.created);
          } else {
            datasets.sort((a, b) => b.created - a.created);  
          }
          break;
        case "Size":
          if (direction==="asc") {
            datasets.sort((a, b) => a.metadata.fileSize - b.metadata.fileSize);
          } else {
            datasets.sort((a, b) => b.metadata.fileSize - a.metadata.fileSize);  
          }
          break;
        default:
          console.log("Missing Sort case")
        }
          
    }
  }


  // renderTable(datasets, onClick, current, locale, signedIn) {
  render() {
    const datasets=this.props.datasets;
    const selected=this.props.selected;
    const locale=this.props.locale;
    const shared=this.props.shared;
    const onClick=this.props.onClick;
    const onDelete=this.props.onDelete;
    const onShare=this.props.onShare;
    const onEdit=this.props.onEdit;

    var options = {style: 'decimal', maximumFractionDigits: 0, useGrouping:true};
    var f = new Intl.NumberFormat(locale, options);
    const orderBy=this.state.orderBy;
    const direction=this.state.direction;
    this.sortDatasets(datasets, orderBy, direction);
  
    var labels = [
      {name: "Dataset Name", align: "left"}
    ];
    if (shared) {
      labels.push({name: "Owner", align: "left"})
    } else {
      labels.push({name: "Shared", align: "center"})
    }
    labels = labels.concat([
      {name: "Rows", align: "right"},
      {name: "Size", align: "right"},
      {name: "Created", align: "left"},
      {name: "Actions", align: "right"},

    ]);

    return(
      <TableContainer 
        sx={{ maxHeight: 500}}>
        <Table
          sx={{ minWidth: 650 }}
          stickyHeader
          size="small" 
          aria-label="Table of Datasets">
          <TableHead>
            <TableRow>
              {labels.map((label)=> (
              <TableCell key={label.name} align={label.align}>
                <TableSortLabel
                  active={orderBy === label.name}
                  direction={orderBy === label.name ? direction : "asc" }
                  onClick={() => { this.sortHandler(label.name)}}
                >
                  {label.name}
                  {orderBy === label.name ? (
                    <Box component="span" sx={visuallyHidden}>
                      {direction === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {datasets.map((row) => {
              const md=row.metadata;
              const created = (row.created < 9e12) ? new Date(row.created).toLocaleDateString("en-US"): "";
              const owner= (row.owner !== "") ? row.owner : "public";
              return(
                <TableRow
                  hover
                  key={row.id}
                  selected={(row.name === selected)}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    sx={{ cursor: 'pointer' }} 
                    component="th" scope="row" 
                    onClick={() => onClick(row.id, row.name)}>
                    {row.displayName}
                  </TableCell>
                  { (shared) 
                    ? <TableCell align="left">{owner}</TableCell> 
                    : <TableCell align="center">{row.shared==="READ" ? "Yes" : ""}</TableCell>
                  } 
                  <TableCell align="right">{f.format(md.rowCount)}</TableCell>
                  <TableCell align="right">{f.format(Math.ceil(md.fileSize/1024))+" KB"}</TableCell>
                  <TableCell align="left">{created}</TableCell>
                  <TableCell align="right">
                    <Tooltip title={shared ? "Dataset is shared" : "Share Dataset with Organization"}>
                      <span>
                        <IconButton
                          disabled={shared}
                          onClick={() => onShare(row.id, row.name, row.shared)}>
                          <ShareIcon/>
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title={shared ? "Can't Edit shared dataset" : "Editt Dataset Information"}>
                      <span>
                        <IconButton
                          disabled={shared}
                          onClick={() => onEdit(row.id, row.name)}>
                          <EditIcon/>
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title={shared ? "Can't delete shared dataset" : "Delete Dataset"}>
                      <span>
                        <IconButton
                          disabled={shared}
                          onClick={() => onDelete(row)}>
                          <DeleteIcon/>
                        </IconButton>
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );    
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}