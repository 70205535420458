import * as React from 'react';
// MUI Components
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';

import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
// Icons
//import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';


export default class ColumnSearch extends React.Component {

  handleSearchChange = (event) => {
    const value=event.target.value;
    // Update immediately on change
    this.props.onChange(value);
  };

  handleSearchClear = (event) => {
    event.preventDefault();
    const value="";
    this.props.onChange(value);
  };


  render() {
    const search=this.props.search;
    return(
      <Box
        component="form" 
        onSubmit={this.handleSearchClear} 
        noValidate 
        sx={{ display: 'flex', alignItems: 'left', width: "100%" }}>
        <FormControl sx={{ width: '40ch' }} variant="standard">
          <InputLabel htmlFor="search-field">Search Columns</InputLabel>
          <Input
            id="search-field"
            type="text"
            value={search}
            autoFocus
            onChange={this.handleSearchChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton type="submit"
                  aria-label="clear">
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
          }/>
        </FormControl>
      </Box>
    );  
  }
}
