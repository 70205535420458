import * as React from 'react';
// Material
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import MenuItem from '@mui/material/MenuItem';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// Application Components
import config from '../Configuration';
import requestCache from '../Cache';
import FilterButton from '../filter/FilterButton';
import ScatterPlotView from './ScatterPlotView';
import ScatterPlotSamples from './ScatterPlotSamples';
import CorrelationMatrix from './CorrelationMatrix';


export default class CorrelationView extends React.Component {
  // Constructor initialize state
  constructor(props) {
    super(props);
    this.state={
      "viewAll": false,
      "column": "*",
      "row": "",
      "data": null,
      "waiting": false,
      "error": null
    }
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("CorrelationView did update");
    const filter=this.props.filter;
    if (!filter.isEqual(prevProps.filter)) {
      const stats=this.props.stats;
      const column=this.state.column;
      console.log("CorrelationView filter change");
      if (column !== "*") {
        this.getPlots(stats.getDatasetId(), stats.getDataset(), column, column);
      }
    }
  }


  async getPlots(datasetId, dataset, column, selected) {
    console.log("Calling get-plots ");
    this.setState({
      "waiting": true,
    })
    const host=config.getAppCtx()
    const url=host+"/services/analytics/get-plots";
    const data = {
      "datasetId": datasetId,
      "dataset": dataset,
      "measureY": column,
      "filter": {
        "conditions": this.props.filter.getActiveConditions()
      }
    }
    const response = await requestCache.fetch(url, config.getRequestSettingsJson(data));
    console.log("get-plots returned");
    if (response !== null) {
      if (response.ok) {
        const message=response.data;
        if (message.status_code===0) {
          this.setState({
            "data": message.body,
            "column": selected,
            "waiting": false,
          })
        } else {
          this.setState({"error": response.status_text, waiting: false});
        }
      } else if (response.status===401) {
        console.log("Authentication expired");
        this.setState({"waiting": false})
        this.props.onAuth();
      } else {
        this.setState({"error": "HTTP Response: "+response.status_text, waiting: false});
      }
    } else {
      this.setState({
        "error": "Can't connect to the server. Please check your internet connection",
         waiting: false});
    }
  }


  renderToolbar(names) {
    const stats=this.props.stats;
    const fields=stats.getFields();
    const column = this.state.column;
    return(
      <Toolbar
      sx={{
        pl: { sm: 1 },
        pr: { xs: 1, sm: 1 }
      }}>
      <Typography component="h2" variant="h6" color="primary"
        sx={{ flex: '1 1 100%' }}
        id="Focus">
        Correlation
      </Typography>
      <FormControl sx={{ pr: 4}}>
        <FormControlLabel 
          control={<Checkbox checked={this.state.viewAll} onClick={this.handleViewAll} />} 
          label="Show&nbsp;All"
          labelPlacement='start' />
      </FormControl>
      {this.renderSelectColumn(names)}
      <FilterButton mt={2}
          columns={fields}
          search={""}
          filter={this.props.filter}
          supported={column !== "*"}
          onUpdateFilter={this.props.onUpdateFilter}
          onAddSearch={()=> console.log("addSearch")}/>
    </Toolbar>
    );
  }

  handleViewAll = (event) => {
    console.log("viewAll:"+event.target.checked);
    this.setState({"viewAll": event.target.checked});
  };


  selectColumn = (event) => {
    let column=event.target.value;
    const stats=this.props.stats;
    if (column !== "*") {
      this.setState({
        "waiting": true,
        "row": ""
      });
      this.getPlots(stats.getDatasetId(), stats.getDataset(), column, column);
    } else {
      this.setState({
        "column": "*",
        "data": null,
        "waiting": false,
        "error": null
      });
    }
  };


  matrixClickHandler=(row, column) => {
    this.setState({
      "waiting": false,
      "row": row,
      "column": column,
      "selected": "",
    });
  }


  chartClickHandler=(row) => {
    this.setState({
      "row": row
    });
  }

  chartCloseHandler=() => {
    this.setState({
      "row": "",
    });
  }

  renderSelectColumn(names) {
    return(
      <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
        <InputLabel id="select-column-label">Measure Column</InputLabel>
        <Select
          variant="standard"
          labelId="select-column-label"
          id="column-select"
          value={this.state.column}
          onChange={this.selectColumn}
          label="Column">
          <MenuItem key="*" value="*"><em>Matrix</em></MenuItem>
          { names.map(name => <MenuItem key={name} value={name}>{name}</MenuItem>) }
        </Select> 
      </FormControl>
    );
  }

  renderView(stats, data, showColumns) {
    const md=stats.getMetadata();
    const column = this.state.column;
    if ((column !== "*") && (data !== null)) {
      const xAxis=this.state.row;
      if (xAxis==="") {
        return(
          <ScatterPlotSamples 
            stats={stats}
            data={data} 
            showColumns={showColumns}
            filter={this.props.filter}
            onUpdateFilter={this.props.onUpdateFilter}
            onClick={this.chartClickHandler}
            viewAll={this.state.viewAll}/>);
      } 
    } else {
      console.log("Rendering Matrix");  
      return(
        <CorrelationMatrix 
          metadata={md} 
          viewAll={this.state.viewAll} 
          showColumns={showColumns}
          onClick={this.matrixClickHandler}/>
      ); 
    }
  }

  renderMain(stats, names, showColumns) {
    const selected=this.state.row;
    const data=this.state.data;
    if (selected === "") {
      // Show General view
      return(
        <React.Fragment>
          {this.renderToolbar(names)} 
          {this.renderView(stats, data, showColumns)}
        </React.Fragment>
      );
    } else {
      const plot = {
        "xAxis": this.state.row,
        "yAxis": this.state.column,
        "data": null,
      };
      return(
        <ScatterPlotView 
          stats={stats}
          plot={plot}
          showColumns={showColumns}
          filter={this.props.filter}
          onUpdateFilter={this.props.onUpdateFilter}
          onClose={this.chartCloseHandler}
          />
      );
    }
  }

  render() {
    const stats=this.props.stats;
    const showColumns=this.props.showColumns;
    if (stats.valid()) {
      const fields=stats.getFields();
      const names=fields
        .filter((field) => showColumns.has(field.name) && field.type==='measure')
        .map(field => field.name).sort();
      return (
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column'}}>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={this.state.waiting}>
            <CircularProgress color="inherit" />
          </Backdrop>
          {this.renderMain(stats, names, showColumns)}
        </Box>
      );
    }
  }
}