
import * as React from 'react';
// MUI Components
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
// Icons
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Check from '@mui/icons-material/Check';
// Application
//import Condition from '../model/Condition';
import FilterDialog from './FilterDialog';
import Filter from '../model/Filter';


export default class FilterMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      "showFilterDialog": false,
    }
  }

  handleFilterClear = () => {
    console.log("Filter clear");
    let filter=new Filter();
    this.props.onClose();
    this.props.onUpdate(filter, this.props.search)
  };


  handleFilterEdit = (conditions) => {
    this.setState({"showFilterDialog": false});
    let filter=this.props.filter.copy();
    filter.setConditions(conditions);
    this.props.onUpdate(filter, this.props.search);
  };


  handleShowFilterDialog = () => {
    this.props.onClose();
    this.setState({ 
      "showFilterDialog": true
    });
  };


  handleDisable= () => {
    const enabled=this.props.filter.isDisabled();
    console.log("Calling handle disable: " + enabled);
    let filter=this.props.filter.copy();
    filter.setEnabled(enabled);
    this.props.onClose();
    this.props.onUpdate(filter, this.props.search);
  }


  render() {
    const search=this.props.search;
    const filter=this.props.filter;
    const columns=this.props.columns;
    const menuAnchor=this.props.menuAnchor;
    const handleClose=this.props.onClose;
    const handleAdd=this.props.onAddSearch;
    //const updateHandler=this.props.onUpdate;

    const conditions=filter.getConditions();
    const hasFilter=conditions.length > 0;
    const showFilterDialog=this.state.showFilterDialog;
    const disabled=filter.isDisabled();

    return(
      <React.Fragment>
        <Menu
          anchorEl={menuAnchor}
          open={menuAnchor!= null}
          onClose={handleClose}
          id="filter-menu">
          { (search.trim().length > 0) ?
          <MenuItem key="#move"
            disabled={search.trim().length===0}
            onClick={handleAdd}>
            <ListItemIcon><AddIcon/></ListItemIcon>
            <ListItemText>Add Search to Filter</ListItemText>
          </MenuItem>
          : null
          }  
          <MenuItem key="#edit"
            onClick={this.handleShowFilterDialog}>
            <ListItemIcon><EditIcon/></ListItemIcon>
            <ListItemText>Edit Filter</ListItemText>
          </MenuItem>
          <MenuItem key="#clear"
            disabled={!hasFilter}
            onClick={this.handleFilterClear}>
            <ListItemIcon><DeleteIcon/></ListItemIcon>
            <ListItemText>Clear All Conditions</ListItemText>
          </MenuItem>
          <MenuItem key="#disable"
            disabled={!hasFilter}
            onClick={this.handleDisable}>
            <ListItemIcon>{disabled ? <Check/> : null}</ListItemIcon>            
            <ListItemText>Disable Filter</ListItemText>
          </MenuItem>
          {hasFilter ? <Divider /> : null}
          {conditions.map((condition, index) => {
            let filterText = condition.getFormatted();
            return(
              <MenuItem key={index} disabled={disabled}>
                <ListItemText>{filterText}</ListItemText>
              </MenuItem>
            );
          })}
        </Menu>
        <FilterDialog open={showFilterDialog}
          columns={columns}
          conditions={conditions}
          onCancel={() => this.setState({"showFilterDialog": false})}
          onSubmit={this.handleFilterEdit}/>
      </React.Fragment>
    );
  }
}

