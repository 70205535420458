import * as React from 'react';
// Material 
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';



export default class MessageDialog extends React.Component {

  closeHandler = () => {
    this.props.onClose();
  }

  clearHandler = () => {
    const messages =this.props.messages;
    const ids = messages.map(m => m.id);
    this.props.onClear(ids)
  }


  formatMessage(message) {
    const type = message.type;
    const content = message.content
    if (type === "delete") {
      const dataset = content.dataset
      return "File "+ dataset + " deleted"
    } else if (type === "import") {
      const dataset = content.dataset;
      const status_code = content.status_code;
      const error_message = content.status_text;
      if (status_code === 0) {
        return "Import of dataset "+ dataset + " successful";  
      } else {
        return "IMPORT ERROR for dataset " + dataset +": "+error_message;
      }
    } else {
      return type;
    }
}

  renderList(messages) {
    // Sort to show latest message on top
    if (messages !== null) {
      const messages_sorted = messages.slice();
      messages_sorted.sort((a,b) => (b.created - a.created));
      return(
        <List dense={true} sx={{ pt: 0 }}>
          {messages_sorted.map((message) => {
            const created = new Date(message.created).toLocaleString("en-US");
            return(
              <React.Fragment key={message.id}>
                <Divider component="li" />
                <ListItem key={message.id} disableGutters>
                  <ListItemButton>
                    <ListItemText primary={this.formatMessage(message)} secondary={created} />
                  </ListItemButton>
                </ListItem>
              </React.Fragment>
            );
          })}
        </List>
      );  
    } else {
      return null;
    }
  }

  render() {
    const open=this.props.open;
    const messages=this.props.messages;
    return (
      <Dialog 
        open={open}
        fullWidth={true}
        maxWidth="md" 
        onClose={this.closeHandler}>
        <DialogTitle>Messages</DialogTitle>
        <DialogContent>
          {this.renderList(messages)}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeHandler}>Cancel</Button>
          <Button onClick={this.clearHandler}>Clear All</Button>
        </DialogActions>
      </Dialog>        
    );  
  }
}