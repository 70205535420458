import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
// Material Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function BackTitle(props) {
  return (
    <Typography component="h2" variant="h6" color="primary" gutterBottom>
      <IconButton 
        sx={{ mr:1}} 
        color="primary" 
        aria-label="Back" 
        onClick={props.onClick}>
        <ArrowBackIcon/>
      </IconButton>
      {props.children}
    </Typography>
  );
}

BackTitle.propTypes = {
  children: PropTypes.node,
};

export default BackTitle;