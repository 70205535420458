import * as React from 'react';
// Material
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';


export default class JoinDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state={ 
      "error": null,
      "dataset": "",
      "joinType": "simple",
      "leftDatasetId": null,
      "leftColumns": [],
      "leftColumn": "",
      "rightDatasetId": null,
      "rightColumns": [],
      "rightColumn": "",
      "typeError": null,
    }
  }

  handleDataset = (event) => {
    const name=event.target.value;
    this.setState({
      "dataset": name
    })  
  };

  handleTypeSelect = (event) => {
    const value=event.target.value;
      this.setState({
        "joinType": value,
        "leftColumn": "",
        "rightColumn": ""
      })  
  };

  handleLeftSelect = (event) => {
    const id=event.target.value;
    console.log("Left Select: "+id);
    const columns=this.getForeignKeys(id);
    if (columns !== null) {
      this.setState({
        "leftDatasetId": id,
        "leftColumns": columns,
        "leftColumn": ""
      })  
    }
  };

  handleRightSelect = (event) => {
    const id=event.target.value;
    const columns=this.getKeys(id);
    if (columns !== null) {
      this.setState({
        "rightDatasetId": id,
        "rightColumns": columns,
        "rightColumn": ""
      })  
    }
  };

  handleLeftColumn = (event) => {
    const name=event.target.value;
    const error= this.validateTypes(name, this.state.rightColumn) ? null : "Column data types do not match!";
    console.log(error);
    this.setState({
      "leftColumn": name,
      "typeError": error
    })  
  };

  handleRightColumn = (event) => {
    const name=event.target.value;
    const error= this.validateTypes(this.state.leftColumn, name) ? null : "Column data types do not match!";
    this.setState({
      "rightColumn": name,
      "typeError": error
    })  
  };

  handleSubmit= () => {
    if (this.validate()) {
      const query = {
        dataset: this.state.dataset,
        leftDatasetId: this.state.leftDatasetId,
        rightDatasetId: this.state.rightDatasetId,
        leftColumn: this.state.leftColumn,
        rightColumn: this.state.rightColumn,
        geo: this.state.joinType==="geo"
      }
      this.props.onSubmit(query);  
    }
  };

  handleCancel= () => {
    this.props.onSubmit(null);
  };


  validate() {
    const dl=this.state.leftDataset;
    const dr=this.state.rightDataset;
    const cl=this.state.leftColumn;
    const cr=this.state.rightColumn;
    const geo=this.state.joinType==="geo";
    // required for all datasets
    if (this.state.name === "" || dl==="" || dr==="") {
      return false;
    }
    if (geo==="simple") {
      if (cl===""|| cr==="") {
        return false;
      }
    } 
    if (!this.validateTypes(cl, cr)) {
      return false;
    }
    return true;
  }


  // Check if types are compatible
  validateTypes(cl, cr) {
    const dl=this.state.leftDataset;
    const dr=this.state.rightDataset;
    const column1=this.getColumn(dl, cl);
    const column2=this.getColumn(dr, cr);
    if (column1 === null || column2 === null) {
      //console.log("Column not defined: " + (column1!==null ? column1 : column2));
      return true;
    } 
    if (column1.type !== column2.type) {
      //console.log(cl+" ("+column1.type+") <> "+cr+" ("+column2.type+")");
      return false;
    }
    // if all tests passed return true
    return true;
  }

  // Get the columns to select as join column for a dataset name
  getForeignKeys(id) {
    const datasets=this.props.datasets;
    const match=datasets.filter(d => d.id === id);
    const meta=(match.length===1) ? match[0].metadata : null;
    if (meta !== null) {
      const columns=meta.fields
        .filter(field => (field.type==="STRING" | field.type==="LONG"))
        .map( field => field.name);
      return columns;
    }
    return null;
  }

  // Get the columns to select as join column for a dataset name
  getKeys(id) {
    const datasets=this.props.datasets;
    const match=datasets.filter(ds => ds.id === id);
    const meta=(match.length===1) ? match[0].metadata : null;
    if (meta !== null) {
      const rows=meta.rowCount;
      const columns=meta.fields
        .filter(field => ((field.type==="STRING" | field.type==="LONG")) & (field.valueCount===rows | field.valueCount===-1))
        .map( field => field.name);
      return columns;
    }
    return null;
  }

  getColumn(dataset, column) {
    const datasets=this.props.datasets;
    const match=datasets.filter((ds) => (ds.name === dataset));
    const meta=(match.length===1) ? match[0].metadata : null;
    if (meta !== null) {
      const columns=meta.fields.filter(field => field.name===column);
      if (columns.length===1) {
        return columns[0];
      }
    }
    console.log("Column not found: "+column);
    return null;    
  }


  render() {
    const open =this.props.open;
    const datasets=this.props.datasets;
    const joinType=this.state.joinType;

    const datasetItems=datasets.map(dataset => {
      const id = dataset.id;
      const display=dataset.displayName;
      return (
        <MenuItem key={id} value={id}>{display}</MenuItem>
      );
    })

    return (
      <Dialog open={open} onClose={this.handleCancel}>
        <DialogTitle>Join</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ m: 1 }}>
            Create a new dataset based on the join of two datasets. All rows of the <em>base dataset</em> will be included,
            but rows of the <em>joined dataset</em> that do not match a row in the base table will be ommited (Left Outer Join).
            Therefore the right column has to have a unique key in every row.
          </DialogContentText>

          <FormControl sx={{ m: 1 }} variant="standard">
            <InputLabel id="join-type-label">Join Type</InputLabel>
            <Select 
              labelId="join-type-label"
              id="joinType"
              label="Join Type"
              value={this.state.joinType}
              variant="standard"
              sx={{ width: '25ch' }}
              onChange={this.handleTypeSelect}
            >
              <MenuItem key="simple" value="simple">Simple Join</MenuItem>
              <MenuItem key="geo" value="geo">Geographical Join</MenuItem>
            </Select>
          </FormControl>

          <TextField sx={{ m: 1, width: '25ch' }}
            autoFocus
            required
            error={this.state.dataset===""}
            margin="dense"
            id="name"
            label="Dataset Name"
            variant="standard"
            onChange={this.handleDataset}
          />

          <FormControl sx={{ m: 1 }} variant="standard">
            <InputLabel id="leftDataset-label">Base Dataset</InputLabel>
            <Select 
              labelId="leftDataset-label"
              id="leftDataset"
              label="Base Dataset"
              value={this.state.leftDataset}
              variant="standard"
              sx={{ width: '25ch' }}
              onChange={this.handleLeftSelect}
            >
              {datasetItems}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1 }} variant="standard">
            <InputLabel id="rightDataset-label">Join Dataset</InputLabel>
            <Select
              labelId="rightDataset-label"
              id="rightDataset"
              label="Join Dataset"
              value={this.state.rightDataset}
              variant="standard"
              sx={{ width: '25ch' }}
              onChange={this.handleRightSelect}
            >
              {datasetItems}
            </Select>
          </FormControl>

          <FormControl 
            sx={{ m: 1 }} 
            variant="standard" 
            error={this.state.typeError!==null}>
            <InputLabel id="left-column-label">Base Dataset Join Column</InputLabel>
            <Select
              labelId="left-column-label"
              id="leftColumn"
              label="Base Dataset Join Column"
              value={this.state.leftColumn}
              variant="standard"
              sx={{ width: '25ch' }}
              disabled={this.state.leftColumns.length===0 || joinType!=="simple"}
              onChange={this.handleLeftColumn}
            >
              {this.state.leftColumns.map(column =>
                <MenuItem key={column} value={column}>{column}</MenuItem>
              )}
            </Select>
            <FormHelperText>{this.state.typeError}</FormHelperText>
          </FormControl>

          <FormControl 
            sx={{ m: 1 }} 
            variant="standard" 
            error={this.state.typeError!==null}>
            <InputLabel id="right-column-label">Join Dataset Join Column</InputLabel>
            <Select
              labelId="right-column-label"
              id="rightColumn"
              label="Added Datast Join column"
              value={this.state.rightColumn}
              variant="standard"
              sx={{ m: 1, width: '25ch' }}
              disabled={this.state.rightColumns.length===0 || joinType!=="simple"}
              onChange={this.handleRightColumn}
            >
              {this.state.rightColumns.map(column =>
                <MenuItem key={column} value={column}>{column}</MenuItem>
              )}
            </Select>
            <FormHelperText>{this.state.typeError}</FormHelperText>
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button onClick={this.handleCancel}>Cancel</Button>
          <Button onClick={this.handleSubmit} disabled={!this.validate()} >Join</Button>
        </DialogActions>
      </Dialog>
    );  
  }
}
