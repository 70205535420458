import * as React from 'react';
// Material 
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
// Application Component
import config from '../Configuration';
import Copyright from '../Copyright';
import Register from './Register';


export default class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state={ 
      "register": false,
      "email": "",
      "missingEmail": false,
      "success": false
    }
  }

  
  async message(type, email, content) {
    console.log("Calling message: ");
    const host=config.getAppCtx()
    const url=host+"/auth/message";
    const message = {
      "type": type,
      "email": email,
      "content": content
    }
    console.log(JSON.stringify(message));
    const response = await fetch(url, config.getRequestSettingsJson(message)).catch(err => {
      return null;
    });
    console.log("message returned");
    if (response !== null) {
      if (response.ok) {
        const message= await response.json();
        if (message.status_code===0) {
          this.props.close();
        } else {
          this.setState({"error": response.status_text});
        }
      } else if (response.status===401) {
        console.log("Authentication expired");
        this.props.onLoginClicked("login");
      } else {
        this.setState({"error": "HTTP Response: "+response.status_text});
      }
    } else {
      this.setState({"error": "Can't connect to the server. Please check your internet connection"});
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const login=this.props.login;
    login(data.get('user'), data.get('password'));
  };

  closeRegister = () => {
    this.setState({
      "register": false
    });
    this.props.close();
  }

  saveEmail = (event) => {
    console.log(event.currentTarget.value);
    this.setState({"email": event.currentTarget.value})
  }

  handleForgottenPassword = (event) => {
    console.log("handleForgottenPassword Called");
    const email=this.state.email;
    if (email.trim().length > 5 && email.includes('@')) {
      this.message("password", email, "");
    } else {
      this.setState({"missingEmail": true});
    }
  }


  render() {
    return (
      <div>
        <Snackbar 
          open={this.state.missingEmail} 
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert severity="error" 
            sx={{ width: '100%' }}
            onClose={() => this.setState({"missingEmail": false})} >
            The email address is required!
          </Alert>
        </Snackbar>
        <Register open={this.state.register} close={this.closeRegister} />
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            <Box component="form" onSubmit={this.handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="user"
                label="Email"
                name="user"
                autoComplete="username"
                onChange={this.saveEmail}
                autoFocus/>
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"/>
              <Grid container spacing={2}>
                <Grid item xs>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={this.props.close}
                    sx={{ mt:3, mb:2 }}>Leave
                  </Button>
                </Grid>
                <Grid item xs>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    sx={{ mt:3, mb:2 }}>Login
                  </Button>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs>
                  <Link variant="body2"
                    underline="hover"
                    onClick={this.handleForgottenPassword}>
                    Forgot your password?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </div>
   );  
  }
}