import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import { listValues } from '../model/Columns';


export default function ValueField(props) {
  const condition=props.condition;
  const column=props.column;
  const onChange=props.onChange;

  const op=condition.getOp();
  const selectedList=condition.getValues();
  const selectedValue= (selectedList !== null && selectedList.length > 0) ? selectedList[0] : "";
  const dataType = (column !== null) ? column.dataType : "String";
  const error = !condition.validValue();


  // Check if we have a list of values
  const values=listValues(column);
  if (op === "contains") {
    return renderTextField(selectedValue, onChange, error);
  } else if (dataType === "Date") {
    return renderDateField(selectedValue, onChange, error);
  } else if (values == null) {
      console.log("")
      if (op === "in") {
        return renderMultipleTextField(selectedList, values, onChange, error);
      } else {
        return renderTextField(selectedValue, onChange, error);
      }
  } else  {
    if (op === "in") {
      return renderMultipleSelection(selectedList, values, onChange, error);
    } else { 
      return renderLOV(selectedValue, values, onChange, error);
    }
  }
}


function renderTextField(selectedValue, onChange, error) {
  const message= error ? "Missing Value" : null;
  return(
    <TextField id="value"
      fullWidth
      size="small"
      margin="dense"
      variant="standard"
      error={error}
      label="Value"
      value={selectedValue}
      helperText={message}
      onChange={(event) => onChange(event.currentTarget.value)}/>
  );
}


function renderDateField(selectedValue, onChange, error) {
  const message = error ? "Date in format 2022-12-31 required" : null;
  return(
      <TextField id="value"
        fullWidth
        size="small"
        margin="dense"
        variant="standard"
        error={error}
        label="Date"
        value={selectedValue}
        helperText={message}
        onChange={(event) => onChange(event.currentTarget.value)}/>
  );
}


function renderMultipleTextField(selectedValues, values, onChange, error) {
  const message = error ? "Missing Value" : null;
  const selectedValue = selectedValues.join("|");
  return(
    <TextField id="value"
      fullWidth
      size="small"
      margin="dense"
      variant="standard"
      error={error}
      label="Values (Separator is |)"
      value={selectedValue}
      helperText={message}
      onChange={(event) => {
        const valueArray = event.currentTarget.value.split("|");
        console.log(JSON.stringify(valueArray));
        onChange(valueArray);
      }}/>
  );
}


function renderLOV(selectedValues, values, onChange, error) {
  const message= error ? "Missing Value" : null;
  return(
    <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
    <InputLabel id="select-value-label">Value</InputLabel>
    <Select
      id="value-select"
      labelId="select-value-label"
      value={selectedValues}
      onChange={(event) => onChange(event.target.value)}
      size="small"
      fullWidth>
      {values.map((entry) =>
        <MenuItem key={entry.v} value={entry.v}>{entry.v}</MenuItem>
      )}
    </Select>
    <FormHelperText id="error-text" disabled={!error}>{message}</FormHelperText>
  </FormControl>
  );
}


function renderMultipleSelection(selectedValue, values, onChange, error) {
  const message= error ? "Missing Value" : null;
  return(
    <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
    <InputLabel id="select-value-label">Value</InputLabel>
    <Select
      sx={{ minWidth: 150, maxWidth: 400 }}
      id="value-select"
      multiple
      labelId="select-value-label"
      value={selectedValue}
      onChange={(event) => onChange(event.target.value)}
      size="small">
      {values.map((entry) =>
        <MenuItem key={entry.v} value={entry.v}>{entry.v}</MenuItem>
      )}
    </Select>
    <FormHelperText id="error-text" disabled={!error}>{message}</FormHelperText>
  </FormControl>
  );
}
