import React from "react";
// Material
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';


export default function RowDetails(props) {
  const data=props.data;
  const selection=props.selection;
  const showColumns=props.showColumns;

  //console.log(JSON.stringify(selection));
  if (data !== null && selection !== null) {
    const columns=data.cols.filter((col) => showColumns.has(col.name));
    return(
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        {columns.map(col => {
          const name=col["name"];
          const raw=selection[name];
          let value=raw;
          if (value !== null) {
            return(
              <ListItem key={name}>
                <ListItemText primary={value} secondary={name} />
              </ListItem>    
            );            
          } else {
            return null;
          }
        })}
      </List>
    );     
  }
  return null;
}
