import * as React from 'react';
// mui components
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
// recharts
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
// Application
import config from '../Configuration';
import Condition from '../model/Condition';
import BackTitle from '../components/BackTitle';
import ChartDialog from '../components/ChartDialog';
import { scalePercent } from '../Utils';


function DateHistogram(props) {
  console.log(props.width);
  const clickHandler=props.onClick;
  const rows=props.rows;
  const maxY=scalePercent(props.data, rows);

  const data=props.data.map((e) => {
    const label = (props.width===1) ? e.start.toFixed(0) : "["+e.start+","+e.end+")";
    const formattedValue = (e.c*100.0/rows).toFixed(2);
    return {v: label, Percent: formattedValue } 
  });
  return(
    <ResponsiveContainer width="100%" height={480}>
      <BarChart
        data={data}
        margin={{ top: 5,right: 30, left: 20, bottom: 5, }}
        isAnimationActive={true}
        onClick={clickHandler}
      >
        <XAxis dataKey="v" type="category" />
        <YAxis unit="%" domain={[0, maxY]} />
        <Tooltip />
        <Bar dataKey="Percent" fill={config.getChartColor()} />
      </BarChart>
    </ResponsiveContainer>
  );
}


export default class DateHistogramView extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      "graph": null
    }
  }

  componentDidMount() {
    console.log("DateDetails did mount");
  }


  addHandler = (graph) => {
    const column = this.props.column;
    const filter=this.props.filter.copy();

    const columnName=column.name;
    const hist = column.histogram; 
    const width= hist.binWidth;

    const label=graph.activeLabel;
    let start = "";
    let stop = "";
    if (width === 1) {
      const nextYear = Number(label)+1;
      start = label + "-01-01";
      stop = nextYear.toString() + "-01-01";
    } else {
      const values = label.slice(1,-1).split(",");
      start = values[0] + "-01-01";
      stop = values[1] + "-01-01";
    }
    const condition1 = new Condition(columnName, "ge", [start]);
    condition1.setDataType("Date");
    filter.addConditon(condition1);
    const condition2 = new Condition(columnName, "lt", [stop]);
    condition2.setDataType("Date");
    filter.addConditon(condition2);
    this.props.onUpdateFilter(filter);

    this.setState({
      "graph": null
    });
  }

  handleOpenFilterMenu = (graph) => {
    if (this.props.filter) {
      this.setState({
        "graph": graph 
      });  
    } 
  };

  handleCloseFilterMenu = () => {
    this.setState({
      "graph": null
    });
  };


  render() {
    // properties
    const column=this.props.column;
    const filter=this.props.filter;
    const clickHandler=this.props.onClick;
    const hist = column.histogram; 
    const rows=hist.count;
    const data=hist.bins;
    const width=column.histogram.binWidth;
    return(
      <div>
        <BackTitle onClick={clickHandler}>
          Details {column.name}
        </BackTitle>
        <Grid container spacing={2}>
          <Grid item xs={9} key={column.name}>
            <DateHistogram 
              column={column.name} 
              data={data} 
              rows={rows} 
              width={width} 
              onClick={this.handleOpenFilterMenu} />
            <ChartDialog
              graph={this.state.graph}
              filter={filter}
              onClose={this.handleCloseFilterMenu}
              onAddFilter={this.addHandler}/>
          </Grid>
          <Grid item xs={3} key={"Info"}>
            <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              <ListItem>
                <ListItemText primary={column.first} secondary="First Date" />
              </ListItem>
              <ListItem>
                <ListItemText primary={column.last} secondary="Last Date" />
              </ListItem>
              <ListItem>
                <ListItemText primary={rows} secondary="Total Rows" />
              </ListItem>
              <ListItem>
                <ListItemText primary={column.missing} secondary="Missing Values" />
              </ListItem>
            </List>
          </Grid>
        </Grid>    
      </div>
    ); 
  }
}