import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export default class EditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state={ 
      "error": null,
      "displayName": ""
    }
  }

  componentDidMount() {
    const info = this.props.info;
    if (info !== null) {
      console.log("EditDialog mount: "+info.displayName);
      this.setState({
        "displayName": info.displayName
      });    
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const infoNew = this.props.info;
    const infoOld = prevProps.info;
    if (infoNew !== null) {
      const idNew = infoNew.id;
      const idOld = (infoOld !== null) ? infoOld.id : -1;
      if (idNew !== idOld) {
        console.log("Component update: "+infoNew.displayName);
        this.setState({
          "displayName": infoNew.displayName
        });  
      }
    }
  }

  handleDisplayName = (event) => {
    const name=event.target.value;
    this.setState({
      "displayName": name
    })  
  };

  handleSubmit= () => {
    console.log("Edit Dialog handleSubmit called")
    const info = this.props.info;
    if (this.validate()) {
      const update = {
        "datasetId": info.id,
        "dataset": info.name,
        "displayName": this.state.displayName
      }
      this.props.onSubmit(update);  
    }
  };

  handleCancel= () => {
    this.props.onSubmit(null);
  };

  validate() {
    const name=this.state.displayName;
    return (name !== null) && (name.trim().length > 0);
  }

  render() {
    const open=this.props.open;
    console.log("render Edit Dialog");
    return (
      <Dialog open={open} onClose={this.handleCancel}>
        <DialogTitle>Edit Dataset</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ m: 1 }}>
            Edit Dataset Information
          </DialogContentText>
          <TextField sx={{ m: 1, width: '50ch' }}
            autoFocus
            margin="dense"
            id="name"
            label="Dataset Name"
            value={this.state.displayName}
            variant="standard"
            onChange={this.handleDisplayName}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel}>Cancel</Button>
          <Button onClick={this.handleSubmit} disabled={!this.validate()} >Save</Button>
        </DialogActions>
      </Dialog>
    );  
  }
}
