// Utitlity functions - mostly for scaling

function calcMaxPercent(data, rows) {
  const n=data.length;
  let max=0;
  for (let i=0; i<n; i++) {
    let value=data[i].c/rows;
    if (isFinite(value)) {
      max=Math.max(max, value);
    }
  }
  return max;
}

export function calcMax(data) {
  const n=data.length;
  let max=0;
  for (let i=0; i<n; i++) {
    let value=data[i];
    if (isFinite(value)) {
      max=Math.max(max, value);
    }
  }
  return max;
}

function calcMin(data) {
  const n=data.length;
  let min=0;
  for (let i=0; i<n; i++) {
    let value=data[i];
    if (isFinite(value)) {
      min=Math.min(min, value);
    }
  }
  return min;
}

// Row sum of a matrix
export function sumRow(data) {
  const n=data.length;
  let result = [];
  for (let row=0; row<n; row++) {
    let rowValues = data[row];
    let m = rowValues.length;
    let total=0.0;
    for (let col=0; col<m; col++) {
      let value = rowValues[col];
      if (isFinite(value)) {
        total += value;
      }
    }
    result.push(total);
  }
  return result;
}

// Row sum of a matrix
export function maxRow(data) {
  const n=data.length;
  let result = [];
  for (let row=0; row<n; row++) {
    let rowValues = data[row];
    let m = rowValues.length;
    let max=rowValues[0]
    for (let col=1; col<m; col++) {
      let value = rowValues[col];
      if (isFinite(value) && value > max) {
        max = value;
      }
    }
    result.push(max);
  }
  return result;
}

// Scale axis for percent
export function scalePercent(data, rows) {
  let max=calcMaxPercent(data, rows);
  return scalePercentMax(max);
}

// Scale axis for percent
export function scalePercentMax(max) {
  if (max > 0.5) return 100;
  else if (max > 0.2) return 50;
  else if (max > 0.1) return 20;
  else  return 10;
}

// Scale Y axis
export function scale(data) {
  let max=calcMax(data);
  return scaleMax(max);
}

// Scale Y axis
export function scaleMax(max) {
  let exp=Math.ceil(Math.log10(max));
  let magnitude=10**exp;
  if (max > 0.5*magnitude) {
    return magnitude;
  } else if (max > 0.2*magnitude) {
    return 0.5*magnitude;
  } else {
    return 0.2*magnitude;
  }
}


// Scale Y Axis - returns min and max
export function scaleDomain(data) {
  let min=calcMin(data);
  let max=calcMax(data);
  if (min >= 0 && max >= 0) {
    return [0, roundUp(max)]; 
  } else if ( min < 0 && max <= 0) {
    return [-roundUp(-min), 0]; 
  } else {
    // max > min =>  max >=0 and min<=0)
    const extreme=(Math.max(-min, max));
    let exp=Math.ceil(Math.log10(extreme));
    let magnitude=10**exp;
    let low=-roundUp2(-min, magnitude);
    let high=roundUp2(max, magnitude);
    return [low, high];
  }
}

function roundUp(value) {
  let exp=Math.ceil(Math.log10(value));
  let magnitude=10**exp;
  return roundUp2(value, magnitude);
}

// Find nearest magnitude
function roundUp2(max, magnitude) {
  if (max > 0.5*magnitude) {
    return magnitude;
  } else if (max > 0.3*magnitude) {
    return 0.5*magnitude;
  } else if (max > 0.2*magnitude) {
    return 0.3*magnitude;
  } else {
    return 0.2*magnitude;
  }
}


// returns formatter function
export function magnitude(maximum) {
  if (maximum > 1) {
    const l=Math.floor(Math.log10(maximum));
    const exp = Math.floor(l/3)*3;
    const magnitude=10**((exp<=15) ? exp : 15);
    return magnitude;
  } else {
    return 1;
  }
}

// Abbreviation
export function abbrevMag(magnitude) {
  switch (magnitude) {
    case 1:
      return "";
    case 1000:
      return "k";
    case 1000_000:
      return "m";
    case 1000*1000*1000:
      return "b";
    case 1000*1000*1000*1000:
      return "t";
    case 1000*1000*1000*1000*1000:
      return "q";
    default:
      return "x";
  }
}