
export default class Stats {

  // create from object tree
  constructor(datasetId, md) {
    //console.log("Stats Constructor: "+datasetId);
    this.datasetId=datasetId;
    this.md=md;
  }

  valid() {
    return this.md!==null;
  }

  invalid() {
    return this.md===null;
  }

  // Should be removed after not used anymore - eases using Stats instead of metatdata
  getMetadata() {
    return this.md;
  }

  getDataset() {
    return this.md.dataset;
  }

  getDatasetId() {
    return this.datasetId;
  }

  getFields() {
    return this.md.fields;
  }

  getMeasures(search) {
    return this.getFieldByFn(search, 'measure');
  }

  getCategories(search) {
    return this.getFieldByFn(search, 'category');
  }

  getDateTimes(search) {
    return this.getFieldByFn(search, 'datetime');
  }

  getAttributes(search) {
    return this.getFieldByFn(search, 'attribute');
  }

  getFieldByFn(search, type) {
    const fields=this.getFields();
    let result = fields;
    if (search != null) {
      let pattern=search.toLowerCase();
      result = fields.filter((field) => field.name.toLowerCase().includes(pattern));
    }
    return result.filter((field) => field.type===type)
  }


  getCorrelations() {
    return this.md.correlation;
  }
  
  getCorrelation(fieldName1, fieldName2) {
    const correlation=this.getCorrelations();
    const matrix=correlation.matrix;
    const mapping=correlation.mapping;
    const index1=this.getFieldIndex(fieldName1);
    const index2=this.getFieldIndex(fieldName2);

    if (index1 >=0 & index2 >= 0) {
      const col=mapping[index1];
      const row=mapping[index2];
      if (col >= 0 & row >= 0) {
        return matrix[col][row];
      }
    }
    return NaN;
  }

  // return a list of correlation dicts {f1, f2, cor} without duplicatioon
  getCorrelationList() {
    const measures=this.getCorrelationFields();
    const correlation=this.getCorrelations();
    const matrix=correlation.matrix;
    let n=measures.length;

    let result=[];
    for (let row=0; row<n-1; row++) {
      for (let col=row+1; col<n; col++) {
        const v=matrix[row][col];
        if (isFinite(v)) {
          result.push({ f1: measures[row], f2:measures[col], cor:v});
        }
      }
    }
    return result;
  }


  getFieldIndex(target) {
    const fields=this.getFields();
    const n=fields.length;
    for (let i=0; i<n; i++) {
      if (fields[i].name===target) {
        return i;
      }
    }
    return -1;
  }

  getCorrelationFields() {
    const fields=this.getFields();
    const correlation=this.getCorrelations();
    const mapping = correlation.mapping;

    let result=[];
    for (let col=0; col < fields.length; col++) {
      if (mapping[col] >=0 ) {
        result.push(fields[col].name);
      }
    }
    return result;
  }

  findLatitude() {
    const fields=this.getFields();
    const match=fields.filter((field) => {
      const pattern=field.name.toLowerCase().trim();
      return (pattern==="latitude" || pattern==="lat")
    });
    if (match.length>=1) {
      return match[0].name;
    }
    return "";
  }
  
  
  findLongitude() {
    const fields=this.getFields();
    const match=fields.filter((field) => {
      const pattern=field.name.toLowerCase().trim();
      return (pattern==="longitude" || pattern==="lng" || pattern==="lon")
    });
    if (match.length>=1) {
      return match[0].name;
    }
    return "";
  }

  hasCoordinates() {
    if (this.md != null) {
      return (this.findLatitude() !== "") && (this.findLongitude() !== "");
    }
    return false;
  }

  hasTimeSeries() {
    if (this.md != null) {
      const dates=this.getDateTimes(null);
      return dates.length > 0;
    }
    return false;
  }

  hasCorrelation() {
    if (this.md != null) {
      const measures=this.getMeasures(null);
      return measures.length > 1;
    }
    return false;
  }
}