class Configuration {
  
  constructor() {
    console.log("Configuring Application")
    this.appContext = "/why"; // Tomcat
    //this.appContext = ""; // Helidon
    const localHost="http://localhost:8080";
    //const publicHost="https://why-server-7lu6buhaea-uw.a.run.app";
    //const publicHost="https://qg-server-wc7iaqv42a-uw.a.run.app";
    const devHost="https://server.dev.upgrata.com";
    const prodHost="https://server.upgrata.com";
    const devClient="application.dev.upgrata.com";
    const prodClient="application.upgrata.com"

    // Derive host URL based on client
    const clientHost=window.location.hostname.toLowerCase();
    console.log("Called by: ["+clientHost+"]");
    //const port=window.location.port
    //this.host= port==="3000" ? localHost : devHost
    switch (clientHost) {
      case devClient:
        this.host=devHost;
        break;
      case prodClient:
        this.host=prodHost;
        break;
      default:
        this.host=localHost;
    }
    this.permissions=new Set();
    this.token = "unauthorized";
    this.apiKey= "";
    this.squareAppId = "sandbox-sq0idb-2iEf9weKQcsb_4St5GYn7w";
    this.env="test";
    this.chartColor = '#45716b';
    console.log("Calling: ["+this.host+"]")
  }

  getAppCtx() {
    //console.log("get host called: "+this.host)
    return this.host+this.appContext;
  }

  getToken() {
    return this.token;
  }

  getApiKey() {
    return this.apiKey;
  }

  getEnv() {
    return this.env;
  }

  setToken(value) {
    this.token=value;
    console.log("Set token: "+this.token);
  }

  setEnv(value) {
    this.env=value;
    console.log("Environment: "+this.env);
  }

  setApiKey(value) {
    this.apiKey=value;
  }

  setSquareAppId(id) {
    this.squareAppId = id;
  }

  getSquareAppId() {
    return this.squareAppId;
  }

  setPermissions(text) {
    this.permissions=new Set()
    if (text !== null) {
      const words=text.split(',');;
      for (const word of words) {
        let w = word.trim();
        if (w.length > 0) {
          this.permissions.add(w);
        }
      }
    }
  }

  getPermisions() {
    return this.permissions;
  }

  setChartColor(color) {
    this.chartColor = color;
  }

  getChartColor() {

    return this.chartColor;
  }


  getRequestSettingsJson(data) {
    console.log("Configuration.getRequestSettings1 called")
    const options = {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'include', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': 'Bearer ' + this.token,
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    }
    return options;
  }


  getRequestSettings(data, ctype) {
    console.log("Configuration.getRequestSettings2 called")
    const options = {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'include', // include, *same-origin, omit
      headers: {
        'Content-Type': ctype,
        'Authorization': 'Bearer ' + this.token,
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    }
    return options;
  }


  // No header
  getUploadSettings(formData) {
    //console.log("Configuration.getUploadSettings called")
    const options = {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'include', // include, *same-origin, omit
      headers: {
        'Authorization': 'Bearer ' + this.token
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: formData // body data type must match "Content-Type" header
    }
    return options;
  }
}

var config= new Configuration();
export default config;