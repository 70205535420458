import * as React from 'react';
// mui components
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
// rechart
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

// Application
import { scalePercent } from '../Utils';
import config from '../Configuration';
import CategoryDetails  from '../column-view/CategoryDetails';



function HistogramCategory(props) {
  const rows=props.rows;
  const n=Math.min(20, props.data.length);
  const data=props.data.slice(0, n).map((e) => { return {v: e.v, Percent: (e.c*100.0/rows).toFixed(2) } });
  const label=props.column;
  const clickHandler=props.onClick;
  const maxY=scalePercent(props.data, rows);
  return(
    <div style={{ width: '100%' }}>
      <Typography align="center"><b>{label}</b></Typography>
      <ResponsiveContainer width="100%" height={200}>
        <BarChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          isAnimationActive={false}
          onClick={() => clickHandler(label)}>
          <XAxis dataKey="v" type="category" tick={false} />
          <YAxis unit="%" type="number" domain={[0, maxY]} />
          <Tooltip />
          <Bar dataKey="Percent" fill={config.getChartColor()} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}


export default class CategoryFrequencyView extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      "column": null,
    }
  }

  componentDidMount() {
    console.log("CategoryFrequencyView did mount");
  }

  renderSingleChart(md, column, filter, handleFilter) {
    return(
      <div style={{ height: 500, width: '100%' }}>
        <CategoryDetails
          metadata={md}
          category={column}
          filter={filter}
          onUpdateFilter={handleFilter}  
          onClose={() => this.setState({"column": null})}
        />
      </div>
    );
  }


  renderGridCells(categories) {
    return categories.map( (column) => {
      const data=column.dist.frequency;
      const rows=column.dist.count;
      return(
        <Grid item xs={3} key={column.name}>
          <HistogramCategory 
            column={column.name}
            data={data} 
            rows={rows}
            onClick={(col) => this.setState({"column": col})}
           />
        </Grid>
      );});
  }

  render() {
    const md=this.props.metadata;
    const fields=this.props.fields;
    const selected=this.state.column;
    const filter=this.props.filter;
    const handleFilter=this.props.onUpdateFilter;


    if (selected===null) {
      let categories=fields.filter((row) => row.dist.complete)
        .sort((a,b) => (a.name > b.name ? 1 :(a.name < b.name ? -1 :0)));
      
      return (
        <Grid container spacing={2}>
          {this.renderGridCells(categories)}
        </Grid>  
      );
    } else {
      let categories=fields.filter((row) => (row.dist.complete && row.name===selected));
      if (categories.length === 1) {
        return this.renderSingleChart(md, categories[0], filter, handleFilter);
      } else {
        return null;
      }
    }
  }
}