import * as React from 'react';
// Material
import Paper from '@mui/material/Paper';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
// Application
import config from '../Configuration';
import Payment from './Payment';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const PLAN_BASIC = "basic";
const PLAN_PREMIUM = "premium";


export default class PaymentView extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      "payments": [],
      "waiting": false,
      "error": null,
      "success": null,
      "plan": PLAN_BASIC,
      "showPurchaseDialog": false
    }
  }

  componentDidMount() {
    if (this.props.email !== null) {
      console.log("PaymentView did mount - email: "+this.props.email);
      this.getPayments();
    } else {
      console.log("PaymentsView did mount - not logged in");
    } 
  }

  componentDidUpdate(prevProps, prevState) {
    const email=this.props.email;
    console.log("PaymentView did update");
    if (email !== prevProps.email) {
      console.log("Signed in changed to: "+email);
      this.getPayments();
    }
  }

  
  async getPayments() {
    console.log("Calling getPayments ");
    const host=config.getAppCtx()
    const url=host+"/services/payments/get-payments";
    const response = await fetch(url, config.getRequestSettingsJson({})).catch(err => {
      this.setState({"error": "Can't connect to the server. Please check your internet connection"});
      return null;
    });
    console.log("get-payments returned");
    if (response !== null) {
      if (response.ok) {
        const message = await response.json();
        console.log(JSON.stringify(message));
        if (message.status_code===0) {
          const body = message.body;
          const payments = (body.payments != null) ? body.payments : [];
          console.log(JSON.stringify(payments));
          this.setState({
            "payments": payments,
            "waiting": false
          })
        } else {
          this.setState({"error": response.status_text, waiting: false});
        }  
      } else if (response.status===401) {
        console.log("Authentication expired");
        this.props.onLoginClicked("login");
      } else {
        this.setState({"error": "HTTP Response: "+response.status_text, waiting: false});
      }
    } else {
      this.setState({
        "error": "Can't connect to the server. Please check your internet connection",
        waiting: false
      });
    }
  }


  handleClose = (message) => {
    const error = (message !== null && message !== "cancel" ) ? message : null;
    const success = (message === null) ? "Payment completed successfully" : null;
    this.setState({
      "showPurchaseDialog": false,
      "success": success,
      "error": error,
    })
  }
  

  clearMessage = () => {
    this.setState({ 
      "error": null,
      "success": null
    });
  }

  renderToolbar() {
    return(
      <Toolbar
      sx={{
        pl: { sm: 1 },
        pr: { xs: 1, sm: 1 }
      }}
    >
      <Typography component="h2" variant="h6" color="primary"
        sx={{ flex: '1 1 100%' }}
        id="Focus">
        Subscription Plans
      </Typography>
    </Toolbar>
    );
  }

  renderView() {
    return(
      <Stack direction="row" spacing={2}>
        <Item sx={{width: 300}} id="standard">{this.renderBasic()}</Item>
        <Item sx={{width: 300}} id="busines">{this.renderPremium()}</Item>
        <Item sx={{width: 300}} id="busines">{this.renderEnterprise()}</Item>
        <Item sx={{width: 300}} id="busines">{this.renderClimate()}</Item>
      </Stack>
    );
  }

  renderBasic() {
    return(
      <div>
        <Typography component="h3" variant="h7" color="primary">Basic</Typography>
        <Divider orientation="horizontal"/>
        <Typography>
          $9.95/user/month <br/><br/>
          Single User <br/>
          Up to 5GB storage/user <br/>
          Data Analysis <br/>
          No Access to climate data <br/>
          In-app Support<br/>
        </Typography>
        <Button
          sx={{pt: 2}}
          variant="text" 
          component="span" size="small"
          aria-label="Purchase"
          onClick={() => this.setState({"showPurchaseDialog": true, plan: PLAN_BASIC})}>Buy
        </Button>
      </div>
    );
  }

  renderPremium() {
    return(
      <div>
        <Typography component="h3" variant="h7" color="primary">Multi-User</Typography>
        <Divider orientation="horizontal"/>
        <Typography>
          $29.95/user/month<br/><br/>
          Up to 5 Users<br/>
          Up to 20GB storage/user <br/>
          Data Analysis <br/>
          No Access to climate data <br/>
          In-app Support
        </Typography>
        <Button
          sx={{pt: 2}}
          variant="text" 
          component="span" size="small"
          aria-label="Purchase"
          onClick={() => this.setState({"showPurchaseDialog": true, plan: PLAN_PREMIUM})}>Buy
        </Button>
        </div>
    );
  }

  renderEnterprise() {
    return(
      <div>
        <Typography component="h3" variant="h7" color="primary">Enterprise</Typography>
        <Divider orientation="horizontal"/>
        <Typography>
          Contact us for Pricing<br/><br/>
          Users TBD<br/>
          Up to 20GB storage/user <br/>
          Data Analysis <br/>
          No Access to climate data <br/>
          In-app and Phone Support
        </Typography>
      </div>
    );
  }

  renderClimate() {
    return(
      <div>
        <Typography component="h3" variant="h7" color="primary">Climate</Typography>
        <Divider orientation="horizontal"/>
        <Typography>
          Contact us for Pricing<br/><br/>
          Users TBD<br/>
          Up ro 20 GB storage/user <br/>
          Data Analysis <br/>
          Access to climate data <br/>
          In-app and Phone Support
        </Typography>
        </div>
    );
  }

  render() {
    // const email = this.props.email;
    const success = this.state.success;
    const error = this.state.error;
    const payments = this.state.payments;
    const paymentsSorted = payments.slice().sort((a,b) => b.created - a.created);
    const lastPayment = (payments.length > 0) ? paymentsSorted[0] : null;
    return (
      <div>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={this.state.waiting}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column'}}>
          {this.renderToolbar()}
          {this.renderView()}
        </Paper>
        <Payment 
            payment={lastPayment}
            open={this.state.showPurchaseDialog} 
            plan={this.state.plan}
            onClose={this.handleClose}/>
        <Snackbar 
          open={this.state.success !== null} 
          autoHideDuration={30000} 
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          variant="filled"  elevation={6}
          onClose={this.clearMessage}>
          <Alert onClose={this.clearMessage} severity="success" sx={{ width: '100%' }}>
            <AlertTitle>Success</AlertTitle>
            {success}
          </Alert>
        </Snackbar>
        <Snackbar 
          open={error !== null}
          autoHideDuration={30000} 
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          variant="filled"  elevation={6}
          onClose={this.clearMessage}>
          <Alert onClose={this.clearMessage} severity="error" sx={{ width: '100%' }}>
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}