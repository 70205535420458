import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';


export default function SelectAggregation(props) {
  const value=props.value;
  const handleSelect=props.onChange
  return(
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
      <InputLabel id="select-function-label">Aggregation Function</InputLabel>
      <Select
        variant="standard"
        labelId="select-function-label"
        id="select-function"
        value={value}
        onChange={handleSelect}
        label="Column"
      >
        <MenuItem value="sum">Sum</MenuItem>
        <MenuItem value="avg">Average</MenuItem>
        <MenuItem value="median">Median</MenuItem>
        <MenuItem value="count">Count</MenuItem>
        <MenuItem value="distinct">Count distinct</MenuItem>
      </Select> 
    </FormControl>
  );
}
