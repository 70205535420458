import * as React from 'react';
// MUI Components
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

// Icons
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
// Application Components
import FilterMenu from './FilterMenu';


export default class FilterButton extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      "menuAnchor": null,
      "showFilterDialog": false
    }
  }

  handleOpenFilterMenu = (event) => {
    this.setState({
      "menuAnchor": event.currentTarget
    });
  };

  handleCloseFilterMenu = () => {
    this.setState({
      "menuAnchor": null
    });
  };

  addHandler = (event) => {
    this.props.onAddSearch();
  };


  renderFilterIcon(filter, supported) {
    const state=filter.getState();
    if (supported) {
      switch (state) {
        case "empty":
          return(<FilterAltOutlinedIcon/>);
        case "active":
          return(<FilterAltIcon />);
        case "disabled":
          return(<FilterAltOffIcon/>);
        default:
          return null;
        }  
    } else {
      return(<FilterAltOffIcon/>);
    }
  }


  render() {
    const columns=this.props.columns;
    const filter=this.props.filter;
    const search=this.props.search;
    const marginTop = this.props.mt;
    const supported = (this.props.supported !== undefined) ? this.props.supported : true;
    const tooltip = supported ? "Show/Edit Filter" : "This view does not support filters";
    const variant = (filter.getState() !== "empty") ? "contained" : "text";
    return(
      <React.Fragment>
        <Tooltip title={tooltip}>
          <span>
            <Button
              sx={{ ml: 1, mt: marginTop }} 
              aria-label="filter"
              variant={variant}
              size="small"
              disabled={!supported}
              startIcon={this.renderFilterIcon(filter, supported)}
              onClick={this.handleOpenFilterMenu}>Filter
            </Button>
          </span>
        </Tooltip>
        <FilterMenu
          menuAnchor={this.state.menuAnchor}
          columns={columns}
          search={search}
          filter={filter}
          onClose={this.handleCloseFilterMenu}
          onAddSearch={this.addHandler}
          onUpdate={this.props.onUpdateFilter}/>
      </React.Fragment>
    );
  }
}
