//import * as React from 'react';
// Material
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

// Component
import Title from '../components/Title';


function summarize(columns) {
  let message = "No columns found";
  if (columns.length > 0) {
    if (columns.length <= 3) {
      message = columns.join(", ");
    } else {
      message = columns.length+ " columns found";
    }
  }
  return message;
}


function Property(props) {
  return(
    <TableRow
      key={props.name}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell key="1" component="th" scope="row">{props.name}</TableCell>
      <TableCell key="2" align="left">{props.value}</TableCell>
    </TableRow>
  )
}


function EmptyColumns(props) {
  const fields=props.fields;
  let emptyColumns=fields
    .filter((row) => (row.type==='attribute' && row.dataType==='null'))
    .map((row) => row.name).sort();
  
  if (emptyColumns.length > 0) {
    let message = summarize(emptyColumns);
    return(
      <Property key="Empty Columns" name="Empty Columns" 
      value={message}/>
    );
  } else {
    return null;
  }
}


function CompleteColumns(props) {
  const fields=props.fields;
  const n=fields.length;
  const incomplete = fields.filter((row) => row.missing>0).map((row) => row.name+" ("+row.missing+")");
  
  if (incomplete.length === 0) {
    return(
      <Property key="Completeness" name="Complete Columns" 
      value="No column is missing a value"/>
    );
  } else if (incomplete.length < 10) {
    return(
      <Property key="Completeness" name="Incomplete Columns" 
      value={incomplete.sort().join(", ")}/>
    );
  } else if (n-incomplete.length < 10) {
    const complete=fields.filter((row) => row.missing===0).map((row) => row.name);
    return(
      <Property key="Completeness" name="Complete Columns" 
      value={complete.sort().join(", ")}/>
    );
  } else {
    return(
      <Property key="Completeness" name="Incomplete Columns" 
      value={incomplete.length}/>
    );
  }
}


function TimeSeries(props) {
  const stats=props.stats;
  let dateColumns=stats.getDateTimes(null)
    .map((row) => row.name).sort();
  
  if (dateColumns.length > 0) {
    let message = summarize(dateColumns);
    return(
      <Property key="Time Series" name="Time Series" 
      value={message}/>
    );  
  } else {
    return null;
  }
}


function Map(props) {
  const stats=props.stats;
  if (stats.hasCoordinates()) {
    return(<Property key="Map" name="Map Coordinates" value={stats.findLatitude()+", "+stats.findLongitude()}/>);
  } else {
    return null;
  }
} 


function Correlation(props) {
  const stats=props.stats;
  const result=stats.getCorrelationList();
  const high=result.filter((row) => Math.abs(row.cor) > 0.50);
  const ratio = high.length + " / " + result.length; 

  if (high.length > 0) {
    return(<Property key="Correlation" name="High Correlations" value={ratio}/>);
  } else {
    return null;
  }
} 


export default function SummaryView(props) {
  console.log("Rendering summary view");
  const stats=props.stats;
  const md= stats.getMetadata();
  if (md != null) {
    //console.log(JSON.stringify(md));
    var locale = 'en';
    var options = {style: 'decimal', maximumFractionDigits: 0};
    var formatInt = new Intl.NumberFormat(locale, options);

    return(
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column'}}>
        <Title>Summary</Title>
        <TableContainer>
          <Table sx={{ minWidth: 300, maxWidth:600 }} size="small" aria-label="Dataset properties">
            <TableBody>
              <Property name="Rows" value={formatInt.format(md.rowCount)}/>
              <Property name="Columns" value={formatInt.format(md.fields.length)}/>
              <CompleteColumns fields={md.fields} />
              <EmptyColumns fields={md.fields} />
              <Map stats={stats} />
              <TimeSeries  stats={stats} />
              <Correlation stats={stats} />
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  }
}