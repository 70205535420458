import * as React from 'react';
// mui components
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
// rechart
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
// Application
import config from '../Configuration';
import Condition from '../model/Condition';
import BackTitle from '../components/BackTitle';
import ChartDialog from '../components/ChartDialog';
import { scalePercent } from '../Utils';


function HistogramCategory(props) {
  const rows=props.rows;
  const clickHandler=props.onClick;
  const maxY=scalePercent(props.data, rows);
  const n=Math.min(50, props.data.length);
  const data=props.data.slice(0, n).map((e) => { 
    return {v: e.v, Percent: (e.c*100.0/rows).toFixed(2)} 
  });
  return(
    <ResponsiveContainer width="100%" height={480}>
      <BarChart  
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        isAnimationActive={true}
        onClick={clickHandler}>
        <XAxis dataKey="v" type="category" />
        <YAxis unit="%" type="number" domain={[0, maxY]}/>
        <Tooltip />
        <Bar dataKey="Percent" fill={config.getChartColor()} />
      </BarChart>
    </ResponsiveContainer>
  );
}


export default class CategoryDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      "graph": null
    }
  }

  componentDidMount() {
    console.log("CategoryDetailView did mount");
  }

  addHandler = (graph) => {
    const label=graph.activeLabel;
    const labels = [ label ];
    const column=this.props.category.name;
    const filter=this.props.filter.copy();
    const condition = new Condition(column, "eq", labels);
    filter.addConditon(condition);
    this.props.onUpdateFilter(filter);
    this.setState({
      "graph": null
    });
  }


  handleOpenFilterMenu = (graph, event) => {
    if (this.props.filter) {
      this.setState({
        "graph": graph
      });
    }
  };

  handleCloseFilterMenu = () => {
    this.setState({
      "graph": null
    });
  };

  render() {
    // properties
    const column=this.props.category;
    const closeHandler=this.props.onClose;
    const filter=this.props.filter;
    // convenience
    const data=column.dist.frequency;
    return(
      <div>
        <BackTitle onClick={closeHandler}>
          Details {column.name}
        </BackTitle>
        <Grid container spacing={2}>
          <Grid item xs={9} key={column.name}>
            <HistogramCategory 
              column={column.name} 
              data={data} 
              rows={column.dist.count} 
              onClick={this.handleOpenFilterMenu}/>
          <ChartDialog
            anchor={this.state.anchor}
            graph={this.state.graph}
            filter={filter}
            onClose={this.handleCloseFilterMenu}
            onAddFilter={this.addHandler}/>
          </Grid>
          <Grid item xs={3} key={"Info"}>
            <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              <ListItem>
                <ListItemText primary={column.dist.count} secondary="Rows" />
              </ListItem>
              <ListItem>
                <ListItemText primary={column.missing} secondary="Missing Values" />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </div>
    );
  }
}