import * as React from 'react';
// Material
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Typography } from '@mui/material';
// Material Icons
import RefreshIcon from '@mui/icons-material/Refresh';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import JoinLeftIcon from '@mui/icons-material/JoinLeft';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

// Application Components
import config from '../Configuration';
import JoinDialog from './JoinDialog';
import ImportDialog from './ImportDialog';
import DatasetTable from './DatasetTable';
import UploadDialog from './UploadDialog';
import EditDialog from './EditDialog';


const MAX_UPLOAD_SIZE=300*1024*1024;


// Standard tab copied from mui
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`column-type-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}


export default class DatasetView extends React.Component {
  constructor(props) {
    super(props);
    this.state={ 
      "data": null,
      "waiting": false,
      "error": null,
      "success": null,
      "orderBy": "Dataset Name",
      "direction": "asc",
      "action": null,
      "showJoinDialog": false,
      "showImportDialog": false,
      "showUploadDialog": false,
      "showEditDialog": false,
      "tab": 1
    }
  }

  componentDidMount() {
    if (this.props.email !== null) {
      console.log("DatasetView did mount - email: "+this.props.email);
      this.setState({
        "tab": 0
      });  
    } else {
      console.log("DatasetView did mount - email: null");
    } 
  }

  componentDidUpdate(prevProps, prevState) {
    const email=this.props.email;
    console.log("DatasetView update");
    if (email !== prevProps.email) {
      console.log("Signed in changed to: "+email);
      this.setState({
        "tab": (email != null) ? 0 : 1
      });  
    }
  }


  async upload(file, sep, locale) {
    console.log("Calling upload ");
    this.setState({"waiting": true,})

    const host=config.getAppCtx()
    const url=host+"/services/upload/dataset";
    const formData = new FormData();
    formData.append('file', file);
    formData.append('separator', sep);
    formData.append('locale', locale)
    const response = await fetch(url, config.getUploadSettings(formData)).catch(err => {
      return null;
    });
    console.log("upload returned")
    if (response != null) {
      if (response.ok) {
        const message= await response.json();
        if (message.status_code === 0) {
          this.setState({
            "success": "Upload succeeded!",
            "waiting": false,
            "error": null
          })
          this.props.refresh();
          return;
        }
      } else if (response.status === 401) {
        this.setState({"waiting": false})
        this.props.onAuth();
        return;
      } else {
        console.log("Error: "+response.status)
      }
    } else {
      console.log("Null Response");
    }
    this.setState({
      "waiting": false, "error": "Upload failed! Please check your Internet connection."
    })
  }

  
  async updateFile(update) {
    console.log("Calling update-file: "+update.name);
    this.setState({"waiting": true});
    const host=config.getAppCtx();
    const url=host+"/services/data/update-file";
    const response = await fetch(url, config.getRequestSettingsJson(update)).catch(err => {
      return null;
    });
    console.log("updatefile returned");
    if (response !== null) {
      if (response.ok) {
        const message= await response.json();
        if (message.status_code===0) {
          this.setState({
            "success": "File "+update.displayName+" has been updated",
            "waiting": false,
            "error": null
          })
          this.props.refresh();
        } else {
          this.setState({"error": response.status_text, waiting: false});
        }
      } else if (response.status===401) {
        this.setState({"waiting": false})
        this.props.onAuth();
      } else {
        this.setState({"error": "HTTP Response: "+response.status_text, waiting: false});
      }
    } else {
      this.setState({
        "error": "Can't connect to the server. Please check your internet connection", waiting: false});
    }
  }

  
  async deleteFile(info) {
    const id = info.id;
    const name = info.displayName;
    const host=config.getAppCtx()
    const url=host+"/services/data/delete-file";
    console.log("Calling delete-file: "+name);
    this.setState({"waiting": true})
    const data = {
      "id": id
    }
    const response = await fetch(url, config.getRequestSettingsJson(data)).catch(err => {
      return null;
    });
    console.log("delete file returned");
    if (response !== null) {
      if (response.ok) {
        const message= await response.json();
        if (message.status_code===0) {
          this.setState({
            "success": "File " + name + " has been deleted",
            "waiting": false,
            "error": null
          })
          this.props.refresh();
        } else {
          this.setState({"error": response.status_text, waiting: false});
        }
      } else if (response.status===401) {
        this.setState({"waiting": false})
        this.props.onAuth();
      } else {
        this.setState({"error": "HTTP Response: "+response.status_text, waiting: false});
      }
    } else {
      this.setState({
        "error": "Can't connect to the server. Please check your internet connection",
         waiting: false});
    }
  }

  
  async join(data) {
    console.log("Calling join-files");
    this.setState({"waiting": true})

    const host=config.getAppCtx()
    const url=host+"/services/data/join-files";
    const response = await fetch(url, config.getRequestSettingsJson(data)).catch(err => {
      return null;
    });
    console.log("join-files returned");
    if (response !== null) {
      if (response.ok) {
        const message= await response.json();
        if (message.status_code===0) {
          this.setState({
            "success": "Join created succesfully!",
            "error": null,
            "waiting": false
          });
          this.props.refresh();
        } else {
          this.setState({"error": response.status_text, waiting: false});
        }
      } else if (response.status===401) {
        this.setState({"waiting": false})
        this.props.onAuth();
      } else {
        this.setState({"error": "HTTP Response: "+response.status_text, waiting: false});
      }
    } else {
      this.setState({
        "error": "Can't connect to the server. Please check your internet connection", waiting: false});
    }
  }

  
  async import(data) {
    console.log("Calling import");
    this.setState({"waiting": true})

    const host=config.getAppCtx()
    const url=host+"/services/batch/query-climate";
    const response = await fetch(url, config.getRequestSettingsJson(data)).catch(err => {
      return null;
    });
    console.log("import returned");
    if (response !== null) {
      if (response.ok) {
        const message= await response.json();
        console.log(JSON.stringify(message))

        if (message.status_code===0) {
          const waitMinutes = Math.ceil(message.wait/60);
          this.setState({
            "success": "Import process started, it will complete approximately in "+waitMinutes+" minute(s). Click Refresh to check for result.",
            "error": null,
            "waiting": false
          });
        } else {
          this.setState({"error": response.status_text, waiting: false});
        }
      } else if (response.status===401) {
        console.log("Authentication expired");
        this.setState({"waiting": false})
        this.props.onAuth();
      } else {
        this.setState({"error": "HTTP Response: "+response.status_text, waiting: false});
      }
    } else {
      this.setState({
        "error": "Can't connect to the server. Please check your internet connection", waiting: false});
    }
  }


  handleUploadDialog = (file, sep, locale) => {
    console.log("handleUploadDialog called");
    if (file !== null) {
      console.log("File size: "+file.size);
      if (file.size <= MAX_UPLOAD_SIZE) {
        console.log("Uploading: "+file.name);
        this.setState({ 
          "showUploadDialog": false,
          "waiting": true,
        })    
        this.upload(file, sep, locale);
        return;
      } else {
        this.setState({ 
          "showUploadDialog": false,
          "error": "The file size exceeds the upload limit!",
        })
      }  
    } else {
      console.log("Cancel Upload Dialog");
      this.setState({ 
        "showUploadDialog": false,
      })
    }
  }

  handleJoin = (query) => {
    if (query !== null) {
      console.log("Joining Datasets");
      this.setState({
        "showJoinDialog": false,
      });
      this.join(query);  
    } else {
      console.log("Cancel Join Dialog");
      this.setState({
        "showJoinDialog": false,
        "waiting": false,
        "success": null,
        "error": null
      });
    }
  }

  handleImport = (query) => {
    console.log("handleImport called")
    if (query !== null) {
      console.log("Importing Climate Dataset");
      this.setState({
        "showImportDialog": false,
      });
      this.import(query);  
    } else {
      console.log("Cancel Import Dialog");
      this.setState({
        "showImportDialog": false,
        "waiting": false,
        "success": null,
        "error": null
      });
    }
  }

  handleDelete = (row) => {
    this.deleteFile(row)
  }

  handleShare = (id, name, shared) => {
    // toggle update status
    const permission = (shared==="READ") ? "NONE" : "READ";
    const update = {
      "datasetId": id,
      "dataset": name,
      "shared": permission
    }
    console.log("handleShare called: "+shared+"->"+update);
    this.updateFile(update);
  }

  handleEdit = (id,name) => {
    const datasets=this.props.datasets.datasets;
    const matchAction=datasets.filter(row => (row.id === id));
    const displayName= (matchAction.length === 1) ? matchAction[0].displayName : "";
    const action = { 
      "id": id, 
      "name": name,
      "displayName": displayName
    }
    console.log(JSON.stringify(action));
    this.setState({
      "showEditDialog": true,
      "action": action
    });
  }

  handleEditDialog = (update) => {
    if (update !== null) {
      console.log("Edit Dialog Save "+JSON.stringify(update));
      this.setState({
        "showEditDialog": false,
        "waiting": true,
        "success": null,
        "error": null
      });
      this.updateFile(update);
    } else {
      console.log("Edit Dialog Canceled");
      this.setState({
        "showEditDialog": false,
      });
    }
  }

  handleChangeTab = (event, selected) => {
    this.setState({
      "tab": selected
    })
  }

  clearMessage = () => {
    this.setState({ 
      "error": null,
      "success": null
    });
  }

  renderUploadText() {
    return(
      <Alert severity="info">
        An account is required to upload files.
        Save EXCEL worksheet as <b>CSV UTF-8 (Comma Delivered File) (*.csv)</b> before uploading.
        The file size is limited to 100 MB. 
      </Alert>
    );
  }

  renderTitle() {
    return(
      <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 }}}>
        <Typography component="h2" variant="h6" color="primary"
          sx={{ flex: '1 1 100%' }}
          id="tableTitle">
          Datasets
        </Typography>
      </Toolbar>
    );
  }

  renderToolbar(signedIn) {
    return(
      <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 }}}>
      <Typography component="h2" variant="h6" color="primary"
        sx={{ flex: '1 1 100%' }}
        id="tableTitle">
        Datasets
      </Typography>
      <Stack spacing={1} direction="row">
        <Tooltip title={"Refresh Datasets"}>
          <span>
            <Button
              variant="text" 
              component="span" size="small"
              aria-label="Refresh"
              startIcon={<RefreshIcon/>}
              onClick={() => this.props.refresh()}>Refresh
            </Button>
          </span>
        </Tooltip>
        <Tooltip title={signedIn ? "Upload CSV File" : "Login required to upload files"}>
          <span>
            <Button
              disabled={!signedIn}
              variant="text" 
              component="span" size="small"
              aria-label="Upload File"
              startIcon={<FileUploadIcon/>}
              onClick={() => this.setState({"showUploadDialog": true})}>Upload
            </Button>
          </span>
        </Tooltip>
        <Tooltip title={signedIn ? "Create a dataset by joining 2 datasets" : "Login required to join files"}>
          <span>
            <Button
              disabled={!signedIn}
              variant="text" 
              component="span" size="small"
              aria-label="Join"
              startIcon={<JoinLeftIcon/>}
              onClick={() => this.setState({"showJoinDialog": true})}>Join
            </Button>
          </span>
        </Tooltip>
        <Tooltip title={signedIn ? "Import Climate Dataset" : "Login required to import"}>
          <span>
            <Button
              disabled={!signedIn}
              variant="text" 
              component="span" size="small"
              aria-label="Climate"
              startIcon={<LibraryBooksIcon/>}
              onClick={() => this.setState({"showImportDialog": true})}>Climate
            </Button>
          </span>
        </Tooltip>
      </Stack>
    </Toolbar>
    );
  }

  render() {
    const email=this.props.email;
    const datasets=this.props.datasets.datasets;
    const onClick=this.props.onClick;
    const selected=this.props.selected;
    const success=this.state.success;
    const error=this.state.error;
    const tab=this.state.tab;
    const locale = 'en';
    const signedIn= (email !== null);
    const login = (email !== null) ? email.toLowerCase() : null;

    if (datasets !== null) {
      const myDatasets = datasets.filter(row => (row.owner === login));
      const sharedDatasets = datasets.filter(row => (login === null || row.owner !== login));

      return (
        <div>
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={this.state.waiting}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Tabs value={tab} 
            onChange={this.handleChangeTab} 
            aria-label="Dataset Tabs"
            sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tab label="Private"  disabled={!signedIn} />
            <Tab label="Shared" />
          </Tabs>
          <TabPanel value={tab} index={0}>
            {this.renderToolbar(signedIn)}
            <DatasetTable
              datasets={myDatasets}
              selected={selected}
              locale={locale}
              shared={!signedIn}
              onClick={onClick}
              onShare={this.handleShare}
              onDelete={this.handleDelete}
              onEdit={this.handleEdit}/>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            {this.renderTitle()}
            <DatasetTable
              datasets={sharedDatasets}
              selected={selected}
              locale={locale}
              shared={true}
              onClick={onClick}
              onEdit={() => {console.log("Illegeal Edit on shared dataset")}}              
              onShare={() => {console.log("Illegeal share on shared dataset")}}
              onDelete={() => {console.log("Illegal delete on shared dataset")}}/>
          </TabPanel>
          <UploadDialog
            open={this.state.showUploadDialog}
            maxFileSize={MAX_UPLOAD_SIZE}
            onSubmit={this.handleUploadDialog}/>
          <JoinDialog 
            open={this.state.showJoinDialog}
            datasets={datasets}
            onSubmit={this.handleJoin}/>
          <ImportDialog 
            open={this.state.showImportDialog}
            onSubmit={this.handleImport}
            permissions={config.getPermisions()}/>
          <EditDialog 
            open={this.state.showEditDialog}
            info={this.state.action}
            onSubmit={this.handleEditDialog}/>
          <Snackbar open={this.state.success !== null} 
            autoHideDuration={30000} 
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            variant="filled"  elevation={6}
            onClose={this.clearMessage}>
            <Alert onClose={this.clearMessage} severity="success" sx={{ width: '100%' }}>
              <AlertTitle>Success</AlertTitle>
              {success}
            </Alert>
          </Snackbar>
          <Snackbar open={error !== null}
            autoHideDuration={30000} 
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            variant="filled"  elevation={6}
            onClose={this.clearMessage}>
            <Alert onClose={this.clearMessage} severity="error" sx={{ width: '100%' }}>
              <AlertTitle>Error</AlertTitle>
              {error}
            </Alert>
          </Snackbar>
        </div>
      );
    }
  }
}