
import * as React from 'react';
// MUI Components
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
// Icons
import AddIcon from '@mui/icons-material/Add';
// Application
//import Condition from '../model/Condition';
//import Filter from '../model/Filter';


export default class ChartDialog extends React.Component {

  handleFilter = () => {
    const graph=this.props.graph;
    this.props.onAddFilter(graph);
  } 

  render() {
    const filter=this.props.filter;
    const graph=this.props.graph;
    if (graph !== null && filter !== null) {
      const handleClose=this.props.onClose;
  
      return(
        <Dialog onClose={handleClose} open={graph !== null}>
          <DialogTitle>Chart Options</DialogTitle>
          <List sx={{ pt: 0 }}>
              <ListItem disableGutters key="addFilter">
                <ListItemButton onClick={this.handleFilter}>
                <ListItemIcon><AddIcon/></ListItemIcon>
                <ListItemText primary="Filter" />
                </ListItemButton>
              </ListItem>
          </List>
        </Dialog>
      );   
    } else {
      return null;
    }
  }
}