import * as React from 'react';
// mui components
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
// rechart
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

// Application components
import config from '../Configuration';
import Condition from '../model/Condition';
import { scaleDomain, magnitude, abbrevMag } from '../Utils';
import BackTitle from '../components/BackTitle';
import ChartDialog from '../components/ChartDialog';

// returns formatter function for XAxis
function formatXAxisFunction(subtype) {
  return (value => formatDate(subtype, value));
}


// returns formatter function for label
function formatLabelFunction(subtype, label) {
  return (value => label+" : "+formatDate(subtype, value));
}


function formatDate(subtype, value) {
  if (subtype==="year-month") {
    const factor=100;
    const year=Math.floor(value/factor);
    const month=(value % factor);
    return year.toFixed(0)+"-"+(month<10 ? "0":"")+month.toFixed(0);
  } else {
    return value;
  }
} 


function AggregationChartSmall(props) {
  const measure=props.measure;
  const label=props.column;
  const fnField=props.fnField;
  const subtype=props.subtype;
  const clickHandler=props.onClick;
  const n = Math.min(props.data.length, 20);
  const data=props.data.slice(0,n)
    .map(x => ({
      l: x.l,
      [measure]: x[fnField]}))
    .sort((a,b) => b.v-a.v);
  const values=data.map(f => f[measure]);

  const domain=scaleDomain(values);
  const extreme=Math.max(Math.abs(domain[0]), Math.abs(domain[1]))
  const digits = (extreme > 1000) ? 0 : 3;
  const options = {style: 'decimal', maximumFractionDigits: digits, useGrouping:true};
  const locale = 'en';
  const formatDouble = new Intl.NumberFormat(locale, options);
  const mag=magnitude(extreme);
  const unit=abbrevMag(mag);  

  return(
    <ResponsiveContainer width="100%" height={200}>
      <LineChart
        data={data}
        margin={{ top: 5, right: 30, left: 20,bottom: 5 }}
        onClick={() => clickHandler(label)}>
        <XAxis dataKey="l" type="category" tickFormatter={formatXAxisFunction(subtype)} />
        <YAxis type="number" domain={domain} tickFormatter={(x) => (x/mag)+unit} />
        <Tooltip 
          formatter={(value) => formatDouble.format(value)}
          labelFormatter={formatLabelFunction(subtype, label)}/>
        <Line dataKey={measure} stroke={config.getChartColor()} />
      </LineChart>
    </ResponsiveContainer>
  );
}


function AggregationChartBig(props) {
  const measure=props.measure;
  const dimension=props.column;
  const fnField=props.fnField;
  const subtype=props.subtype;
  const clickHandler=props.onClick;
  const data=props.data
    .map(x => ({
      l: x.l,
      [measure]: x[fnField]}))
    .sort((a,b) => b.v-a.v);
  const values=data.map(f => f[measure]);

  const domain=scaleDomain(values);
  const extreme=Math.max(Math.abs(domain[0]), Math.abs(domain[1]))
  const digits = (extreme > 1000) ? 0 : 3;
  const options = {style: 'decimal', maximumFractionDigits: digits, useGrouping:true};
  const locale = 'en';
  const formatDouble = new Intl.NumberFormat(locale, options);
  const mag=magnitude(extreme);
  const unit=abbrevMag(mag);  

  return(
    <ResponsiveContainer width="100%" height={500}>
      <LineChart
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        onClick={clickHandler}>
        <XAxis dataKey="l" type="category" tickFormatter={formatXAxisFunction(subtype)} />
        <YAxis type="number" domain={domain} tickFormatter={(x) => (x/mag)+unit} />
        <Tooltip 
          formatter={(value) => formatDouble.format(value)}
          labelFormatter={formatLabelFunction(subtype, dimension)}/>
        <Line dataKey={measure} stroke={config.getChartColor()} />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default class DateAggregation extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      "column": null,
      "graph": null,
    }
  }

  handleCloseSingle = () => {
    this.setState({
      "column": null,
      "graph": null,
    });
  };

  handleOpenFilterMenu = (graph) => {
    if (this.props.filter) {
      this.setState({
        "graph": graph 
      });  
    } 
  };

  handleCloseFilterMenu = () => {
    this.setState({
      "graph": null
    });
  };

  addHandler = (graph) => {
    const column=this.state.column;
    const filter=this.props.filter.copy();

    const label=graph.activeLabel;
    let start = "";
    let stop  = "";
    if (label.startsWith("[")) {
      // Range of years
      const values = label.slice(1,-1).split(",");
      start = values[0]+"-01-01";
      stop = (Number(values[1])-1).toString()+"-12-31";
    } else {
      // single date
      if (label.length === 4) {
        // year
        const nextYear = Number(label)+1;
        start = label + "-01-01";
        stop = nextYear.toString() + "-01-01";
      } else if (label.length === 6) {
        // month
        const year = label.substring(0, 4);
        const month = label.substring(4,6);
        const startDate=new Date(Number(year), Number(month)-1, 1);
        const endDate = new Date(Number(year), Number(month), 1)
        start = startDate.toISOString().substring(0,10);
        stop = endDate.toISOString().substring(0,10);
      }
    }
    const condition1 = new Condition(column, "ge", [start]);
    condition1.setDataType("Date");
    filter.addConditon(condition1);
    const condition2 = new Condition(column, "lt", [stop]);
    condition2.setDataType("Date");
    filter.addConditon(condition2);
    this.props.onUpdateFilter(filter);

    this.setState({
      "graph": null,
    });
  }


  renderGridCells(aggreations, fnField, selectedColumn) {
    return aggreations.map((column) => {
      const measure=column.measure;
      const dimension=column.groupBy;
      const label = (measure === selectedColumn) ? dimension : measure;
      return(
        <Grid item xs={3} key={column.measure+"-"+column.groupBy}>
          <div style={{ width: '100%' }}>
            <Typography align="center"><b>{label}</b></Typography>
            <AggregationChartSmall 
              measure={measure} 
              column={dimension}
              subtype={column.type}
              data={column.totals}
              fnField={fnField}
              onClick={(col) => this.setState({"column": col})}/>
          </div>
        </Grid>
      );
    });
  }


  renderSingleChart(column, fnField, filter) {
    const measure = column.measure;
    const groupBy = column.groupBy;
    return(
      <Grid container spacing={2}>
        <Grid item xs={9} key={column.measure+"-"+column.groupBy}>
          <BackTitle onClick={this.handleCloseSingle}>
            {measure} grouped by {groupBy}
          </BackTitle>
          <AggregationChartBig 
            measure={measure} 
            column={groupBy} 
            subtype={column.type}
            data={column.totals}
            fnField={fnField}
            onClick={this.handleOpenFilterMenu}/>
          <ChartDialog
            graph={this.state.graph}
            filter={filter}
            onClose={this.handleCloseFilterMenu}
            onAddFilter={this.addHandler}/>
        </Grid>
        <Grid item xs={3} key={"Info"}>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <ListItem>
              <ListItemText primary={column.measure} secondary="Y-Axis" />
            </ListItem>
            <ListItem>
              <ListItemText primary={column.groupBy} secondary="X-Axis" />
            </ListItem>
          </List>
        </Grid>
      </Grid>    
    );
  }

  render() {
    console.log("Rendering date aggregations");
    const filter=this.props.filter;
    const selectedColumn=this.props.selectedColumn;
    const fnField=this.props.fnField;
    const allAggregations=this.props.aggregations;
    const aggregations=allAggregations
     .filter((field) => field.type==='year' || field.type==='month' | field.type==='year-month')
      .sort((a,b) => (a.groupBy > b.groupBy ? 1 :(a.groupBy < b.groupBy ? -1 :0)));;
    const selected=this.state.column;
    if (selected === null) {
      return (
        <Grid container spacing={2}>
          {this.renderGridCells(aggregations, fnField, selectedColumn)}
        </Grid>  
      );
    } else {
      const columns=aggregations.filter(field => (field.groupBy===selected));
      if (columns.length >= 1) {
        const column=columns[0];
        return this.renderSingleChart(column, fnField, filter);  
      } else {
        return null;
      }
    }
  }
}