import * as React from 'react';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';


export default function SelectOperator(props) {

  function handleSelect(event) {
    const val=event.target.value;
    console.log("handleSelect called: "+ val);
    const value=(val !== "*") ? val : "";
    props.onChange(value);
  };
  
  const error=props.error;
  const v=props.value;
  const value= (v !== "") ? v : "*";

  return(
    <FormControl variant="standard" sx={{ m: 1 }}
      error={error !== null}>
      <InputLabel id="select-op-label">Relation</InputLabel>
      <Select
        id="op-select"
        labelId="select-op-label"
        value={value}
        onChange={handleSelect}
        size="small"
        fullWidth
      >
        <MenuItem key="contains" value="contains"><i>contains</i></MenuItem>
        <MenuItem key="in" value="in">in</MenuItem>
        <MenuItem key="eq" value="eq">=</MenuItem>
        <MenuItem key="ne" value="ne">&lt;&gt;</MenuItem>
        <MenuItem key="gt" value="gt">&gt;</MenuItem>
        <MenuItem key="lt" value="lt">&lt;</MenuItem>
        <MenuItem key="ge" value="ge">&gt;=</MenuItem>
        <MenuItem key="le" value="le">&lt;=</MenuItem>
        <MenuItem key="null" value="null">missing</MenuItem>
        <MenuItem key="not_null" value="not_null">not missing</MenuItem>
      </Select>
      <FormHelperText id="error-text" disabled={!error}>{error}</FormHelperText>
    </FormControl>
  );
}