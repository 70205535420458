class Cache {
  
  constructor() {
    this.map = new Map();
  }

  clear() {
    console.log("Cache is cleared");
    this.map = new Map();
  }

  entries() {
    let entries = 0;
    for (const requestMap of this.map.values()) {
      entries += requestMap.size;
    }
    return entries;
  }


  async fetch(url, options) {
    console.log("Cache Size: "+this.entries());
    if (url !== null && options.hasOwnProperty("body")) {
      const body = options.body;
      if (this.map.has(url)) {
        const requestMap = this.map.get(url);
        if (requestMap.has(body)) {
          console.log("Cache: response read from cache");
          return requestMap.get(body);
        }
      } else {
        this.map.set(url, new Map());
      }
      console.log("Cache: Fetch response from backend");
      const response = await this.fetchNew(url, options);
      if (response !== null && response.ok) {
        const requestMap = this.map.get(url);
        requestMap.set(body, response);
      }
      return response;
    }
  }

  async fetchNew(url, options) {
    const response = await fetch(url, options).catch(err => {
      return null;
    });
    if ((response !== null) && response.ok) {
      const message= await response.json();
      return {
        ok: response.ok,
        status: response.status,
        status_text: response.status_text,
        data: message
      };
    }
    return null;
  }
}

const requestCache = new Cache(); 
export default requestCache;
