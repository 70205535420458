import * as React from 'react';
// Material 
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
// Material Icons
import CloseIcon from '@mui/icons-material/Close';
// Application
import config from '../Configuration';
import SquareForm from './SquareForm';

const PLAN_BASIC = "basic";
const PLAN_PREMIUM = "premium";


export default class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      "email": null,
      "org": null,
      "error": null,
      "quantity": 0,
      "months": 0,
      "total": 0,
      "familyName": "",
      "givenName": "",
      "addressLines": "",
      "city": "",
      "region": "",
      "country": "",
    };
    // Fix bug in Square SDK
    this.originalLog = console.log;
  }

  componentDidMount() {
    console.log("Payment did mount");
    const plan = this.props.plan;
    const payment = this.props.payment;
    this.updatePayment(payment, plan);
  }

  // Need to update total
  componentDidUpdate(prevProps, prevState) {
    console.log("Payment did update");
    const plan = this.props.plan;
    const payment = this.props.payment;
    if (payment !== prevProps.payment) {
      this.updatePayment(payment, plan);
    } 
    else if (plan !== prevProps.plan) {
      const total = this.calcTotal(plan, this.state.quantity, this.state.months);
      console.log(total);
        this.setState({
        "total": total
        });  
    }
  }

  updatePayment(payment, plan) {
    const def = (payment !== null) ? payment : this.getBlankPayment(plan);
    const address = def.billingContact;
    const total = this.calcTotal(plan, def.quantity, def.months);
    this.setState({
      "quantity": def.quantity,
      "months": def.months,
      "total": total,
      "familyName": address.familyName,
      "givenName": address.givenName,
      "addressLines": address.addressLines,
      "city": address.city,
      "region": address.region,
      "country": address.country,
    });  
  }

  getBlankPayment(plan) {
    const months = 12;
    const quantity = 1;
    const total = this.calcTotal(plan, quantity, months);
    return ({
      "amount": total,
      "quantity": quantity,
      "months": months,
      "billingContact": {
        "givenName": "",
        "familyName": "",
        "addressLines": ["",""],
          "city": "",
          "region": "",
          "country": "US"
      }
    });
  }

  getAddress() {
    const address = {
      "addressLines": this.state.addressLines,
      "familyName": this.state.familyName,
      "givenName": this.state.givenName,
      "city": this.state.city,
      "region": this.state.region,
      "country": this.state.country,
    }
    return address;
  }

  
  async createPayment(token, verifiedBuyer) {
    const host=config.getAppCtx();
    const plan = this.props.plan;
    const amount = this.state.total;
    const quantity = parseInt(this.state.quantity);
    const months = parseInt(this.state.months);
    const billingContact = this.getAddress();
    const url=host+"/services/payments/create-payment";
    console.log("Calling create-payment");
    this.setState({"waiting": true})
    const data = {
      "token": token,
      "verified": verifiedBuyer,
      "amount": amount,
      "product": plan,
      "quantity": quantity,
      "months": months,
      "billingContact": billingContact
    }
    const response = await fetch(url, config.getRequestSettingsJson(data)).catch(err => {
      return null;
    });
    console.log("create-payment returned");
    if (response !== null) {
      if (response.ok) {
        const message= await response.json();
        if (message.status_code===0) {
          this.setState({
            "success": "Payment successful",
            "waiting": false,
            "error": null
          })
          this.props.onClose(null);
        } else {
          this.setState({"error": response.status_text, waiting: false});
        }
      } else if (response.status===401) {
        this.props.onClose("Time out");
        this.setState({"waiting": false})
        this.props.onAuth();
      } else {
        this.setState({"error": "HTTP Response: "+response.status_text, waiting: false});
        this.props.onClose("An unxpected error occured");
      }
    } else {
      this.setState({
        "error": "Can't connect to the server. Please check your internet connection",
         waiting: false});
    }
  }

  getAmount(plan) {
    console.log(plan)
    switch (plan) {
      case PLAN_BASIC:
        return 9.95;
      case PLAN_PREMIUM:
        return 29.95;
      default:
        return 0;
    }
  }

  calcTotal(plan, quantity, months) {
    return this.getAmount(plan) * quantity * months;
  }


  purchaseHandler = (info, verifiedBuyer) => {
    console.log = this.originalLog;
    if (info !== undefined) {
      const token = info.token;
      console.log(token)
      this.createPayment(token, verifiedBuyer);
    }
  }

  closeHandler = () => {
    this.props.onClose("cancel");
  }

  changeValue = (name, value) => {
    this.setState({[name]: value});
  }

  changeLicenses = (value) => {
    console.log("Change licenses: " + value);
    const plan = this.props.plan;
    const total = this.calcTotal(plan, value, this.state.months);
    console.log("Total:" +total)
    this.setState({
      "quantity": value,
      "total": total
    });
  }

  changeMonths = (value) => {
    console.log("Change months: " + value);
    const plan = this.props.plan;
    const total = this.calcTotal(plan, this.state.quantity, value);
    console.log("Total:" +total)
    this.setState({
      "months": value,
      "total": total
    });
  }

  changeBilling = (field, value) => {
    let state = {};
    state[field] = value;
    this.setState(state);
  }

  changeAddressLine = (line, value) => {
    let lines = this.state.addressLines;
    lines[line] = value;
    this.setState({
      "addressLines": lines
    });
  }

  renderMonths() {
    return(
      <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
        <InputLabel id="months-label">Months</InputLabel>
        <Select
          id="months-select"
          labelId="months-label"
          value={this.state.months}
          onChange={(event) => this.changeMonths(event.target.value)}
          label="Column"
          sx={{ width: 100 }}>
          <MenuItem value="1">1</MenuItem>
          <MenuItem value="3">3</MenuItem>
          <MenuItem value="12">12</MenuItem>
        </Select> 
      </FormControl>
    );
  }

  renderCountry() {
    return(
      <FormControl variant="standard" sx={{ m: 1 }}>
        <InputLabel id="country-label">Country</InputLabel>
        <Select
          id="country-select"
          labelId="country-label"
          value={this.state.country}
          onChange={(event) => this.changeBilling("country", event.target.value)}
          label="Column"
          fullWidth>
          <MenuItem value="JP">Japan</MenuItem>
          <MenuItem value="DE">Germany</MenuItem>
          <MenuItem value="GB">Greate Britain</MenuItem>
          <MenuItem value="US">United States of America</MenuItem>
        </Select> 
      </FormControl>
    );
  }

  render() {
    const open=this.props.open;
    const plan = this.props.plan;
    const planName = (plan===PLAN_PREMIUM) ? "Multi-User" : "Basic";
    const address = this.getAddress();
    if (open) {
      return (
        <div>
          <Dialog open={open} onClose={this.closeHandler}>
            <DialogTitle>Purchase Subscription
              <IconButton
                aria-label="close"
                onClick={this.closeHandler}
                sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item xs={12}>
                  {(config.getEnv()!=="prod") ? <div style={{color: "red"}}>No payments are processed in the development environment</div> : null }
                </Grid> 
                <Grid item xs={6}>
                  <TextField
                    label="Plan"
                    id="plan"
                    value={planName}
                    margin="dense"
                    variant="standard"
                    InputProps={{ readOnly: true }}/>
                </Grid> 
                <Grid item xs={6}>
                  <TextField
                    label="Cost/user/month (USD)"
                    id="plan_amount"
                    value={this.getAmount(plan)}
                    margin="dense"
                    variant="standard"
                    InputProps={{ readOnly: true }}/>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Licenses"
                    id="licenses"
                    value={this.state.quantity}
                    margin="dense"
                    variant="standard"
                    onChange={(event) => this.changeLicenses(event.currentTarget.value)}/>
                </Grid>
                <Grid item xs={6}>
                  {this.renderMonths()}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Payment (USD)"
                    id="annualTotal"
                    value={this.state.total.toFixed(2)}
                    margin="dense"
                    variant="standard"
                    InputProps={{ readOnly: true }}/>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="First Name"
                    id="firstName"
                    value={this.state.givenName}
                    margin="dense"
                    variant="standard"
                    onChange={(event) => this.changeBilling("givenName", event.currentTarget.value)}/>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Last Name"
                    id="lastName"
                    value={this.state.familyName}
                    margin="dense"
                    variant="standard"
                    onChange={(event) => this.changeBilling("familyName", event.currentTarget.value)}/>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Address 1"
                    id="address1"
                    value={this.state.addressLines[0]}
                    margin="dense"
                    variant="standard"
                    fullWidth
                    onChange={(event) => this.changeAddressLine(0, event.currentTarget.value)}/>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ gridColumn: '1' }}
                    label="Address 2"
                    id="address2"
                    value={this.state.addressLines[1]}
                    margin="dense"
                    variant="standard"
                    fullWidth
                    onChange={(event) => this.changeAddressLine(1, event.currentTarget.value)}/>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    label="City"
                    id="city"
                    value={this.state.city}
                    margin="dense"
                    variant="standard"
                    onChange={(event) => this.changeBilling("city", event.currentTarget.value)}/>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="State/Region/Province"
                    id="state"
                    value={this.state.region}
                    margin="dense"
                    variant="standard"
                    onChange={(event) => this.changeBilling("region", event.currentTarget.value)}/>
                </Grid>
                <Grid item xs={12}>
                  {this.renderCountry()}
                </Grid>
                <Grid item xs={12}>
                </Grid>
              </Grid> 
              <SquareForm
                    fullWidth
                    sx={{ p: 16 }}
                    amount={this.state.total}
                    address={address}
                    onPay={this.purchaseHandler}/>
            </DialogContent>
          </Dialog>        
        </div>
      ); 
    } else {
      return null;
    }
  }
}