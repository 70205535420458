import * as React from 'react';
// mui components
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
// rechart
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

// application
import config from '../Configuration';
import MeasureDetails  from '../column-view/MeasureDetails';
import { scalePercent } from '../Utils';


function Histogram(props) {
  const width=props.width;
  const rows=props.rows;
  const clickHandler=props.onClick;

  const data=props.data.map((e) => {
    const label = (props.width===1) ? e.start.toFixed(0) : e.start;
    const formattedValue = (e.c*100.0/rows).toFixed(2);
    return {v: label, Percent: formattedValue } 
  });
  const label=props.column;
  const maxY=scalePercent(props.data, rows);

  return(
    <div style={{ width: '100%' }}>
      <Typography align="center"><b>{label}</b></Typography>
      <ResponsiveContainer width="100%" height={200}>
        <BarChart
          width={300}
          height={200}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          data={data}
          onClick={() => clickHandler(label)}>
          <XAxis dataKey="v" type="category"/>
          <YAxis unit="%" domain={[0, maxY]}/>
          <Tooltip labelFormatter={(start) => {
              // start is passed as String
              if (width === 1) {
                return start;
              } else {
                const top=parseFloat(start)+width;
                return "[" + start + "," + top + ")";
              }
          }} />
          <Bar 
            dataKey="Percent" 
            fill={config.getChartColor()} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}


export default class HistogramView extends React.Component {
  // Constructor initialize state
  constructor(props) {
    super(props);
    this.state={
      "column": null,
    }
  }

  componentDidMount() {
    console.log("HistogramView did mount");
  }


  clickHandler = (parms, event, details) => {
    if (parms!== null) {
      const columnId=parms.id;
      console.log(columnId);
      this.setState({
        "column": columnId
      })  
    }
  }

  closeHandler = () => {
    console.log("MeasureDetails closehandler called");
    this.setState({
      "column": null
    })  
  }

  renderGridCells(categories, rows) {
    return categories.map( (column) => {
      if (column.histogram) {
        const data=column.histogram.bins;
        const width=column.histogram.binWidth;
        const rows=column.histogram.count;
        //console.log(JSON.stringify(column));
        return(
          <Grid item xs={3} key={column.name}>
            <Histogram 
              column={column.name}
              data={data}
              rows={rows}
              width={width} 
              onClick={(col) => this.setState({"column": col})}
            />
          </Grid>
        );  
      } else {
        return null;
      }
    });
  }

  render() {
    const md=this.props.metadata;
    let fields=this.props.fields;
    const filter=this.props.filter;
    const handleFilter=this.props.onUpdateFilter;

    const rows=md.rowCount;

    if (this.state.column===null) {
      let measures=fields.sort((a,b) => (a.name > b.name ? 1 :(a.name < b.name ? -1 :0)));
      return (
        <Grid container spacing={2}>
          {this.renderGridCells(measures, rows)}
        </Grid>  
      );
    } else {
      const column=this.state.column;
      const measures=fields.filter((row) => row.name===column);
      if (measures.length===1) {
        const measure=measures[0];
        return(
          <div style={{ height: 500, width: '100%' }}>
            <MeasureDetails
              metadata={md}
              measure={measure}
              filter={filter}
              onUpdateFilter={handleFilter}  
              onClick={this.closeHandler}
            />
          </div>
        );
      } else {
        return null;
      }
    }
  }
}