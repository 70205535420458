// Utiltiy fucntions for fields

export function listValues(column: any): string[] | null {
  if ((column !==null)) {
    const dist: any = column.dist;
    if ((dist !== undefined) && dist.complete) {
      // Need to copy otherwise we modify the original fields
      const frequency = dist.frequency.slice();
      const values = frequency.sort(
        (a: any, b: any): number => a.v.localeCompare(b.v)
      );
      return values;
    }
  }
  return null;
}