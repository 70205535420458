import * as React from 'react';
// Material
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


// Application Components
//import Stats from '../model/Stats';
import config from '../Configuration';
import requestCache from '../Cache';
import FilterButton from '../filter/FilterButton';
import SelectAggregation from '../aggregation-view/SelectAggregation';
import PivotTable from './PivotTable';
import PivotBarChart from './PivotBarChart';
import PivotLineChart from './PivotLineChart';


export default class PivotView extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      "view": "table",
      "function": "sum",
      "data": null,
      "waiting": false,
      "message": null,
      "error": null,
      "measure": "",
      "row": "",
      "column": ""
    }
  }

  componentDidMount() {
    console.log("PivotView did mount");
  }


  componentDidUpdate(prevProps, prevState) {
    const stats=this.props.stats;
    const filter=this.props.filter;
    const measure = this.state.measure;
    const row = this.state.row;
    const column = this.state.column;
    const fn = this.state.function;

    if (!filter.isEqual(prevProps.filter)) {
      console.log(this.props.column + " != " + prevProps.column);
      if ((measure !== "") && (row !== "")) {
        console.log("Fetch on component update");
        this.setState({"waiting": true});
        this.getPivot(stats.getDatasetId(), stats.getDataset(),  measure, row, column, fn, filter);
      }
    }
  }

  
  async getPivot(datasetId, dataset, measure, row, column, fn, filter) {
    const conditions=filter.getActiveConditions();
    console.log("get-pivot");
    const host=config.getAppCtx()
    const url=host+"/services/analytics/get-pivot";
    const data = {
      "measure": measure,
      "groupBy": [ row, column],
      "function": fn,
      "query": {
        "datasetId": datasetId,
        "dataset": dataset,
        "filter": {
          "conditions": conditions
        }
      }
    }
    const response = await requestCache.fetch(url, config.getRequestSettingsJson(data));
    console.log("get-pivot returned");
    if (response !== null) {
      if (response.ok) {
        console.log("get-pivot response ok");
        const message=response.data;
        if (message.status_code===0) {
          const body=message.body;
          //console.log(JSON.stringify(body))      
          if (body.isValid) {
            console.log("Pivot Table is valid");
            this.setState({
              "data": body,
              "error": null,
              "message": null,
              "waiting": false
            })
          } else {
            console.log("Pivot Table is invalid");
            this.setState({
              "data": null,
              "message": body.error,
              "waiting": false
            })  
          }
        } else {
          this.props.onSelectColumn("");
          this.setState({"error": response.status_text, waiting: false});
        }
      } else if (response.status===401) {
        console.log("Authentication expired");
        this.setState({"waiting": false})
        this.props.onAuth();
      } else {
        this.setState({"waiting": false, "error": "HTTP Response: "+response.status_text});
      }
    } else {
      this.props.onSelectColumn("");
      this.setState({"waiting": false, "error": "Can't connect to the server. Please check your internet connection",});
    }
  }


  selectColumn(parameter, value) {
    console.log(parameter + "=" + value);
    const stats = this.props.stats;  
    const filter = this.props.filter;
    const fn = this.state.function;
    const measure = (parameter === "measure") ? value : this.state.measure;
    const row = (parameter === "row") ? value : this.state.row;
    const column = (parameter === "column") ? value : this.state.column;
    this.setState({
      "measure": measure,
      "row": row,
      "column": column
    });
    if ((measure !== "") && (row !== "")) {
      this.setState({
        "waiting": true
      });
      this.getPivot(stats.getDatasetId(), stats.getDataset(),  measure, row, column, fn, filter);
    } else {
      this.setState({
        "data": null
      });
    }
  };

  
  selectFunction = (event) => {
    let fn=event.target.value;
    const stats=this.props.stats; 
    const filter = this.props.filter;
    const row = this.state.row;
    const column = this.state.column;

    // Check if we need to change measure
    let measure = this.state.measure;
    const fields=stats.getFields()
    const measures=this.measureNames(fields, fn);
    const found=measures.filter((field) => (field === measure));
    measure = (found.length === 1) ? measure : "";

    this.setState({
      "function": fn,
      "measure": measure
    });
    if ((measure !== "") && (row !== "")) {
      this.setState({
        "waiting": true,
        "issue:": null
      });
      this.getPivot(stats.getDatasetId(), stats.getDataset(),  measure, row, column, fn, filter);
    }
  };

  handleAddSearch() {
    // Dummmy should not happen.
    console.log("Error: addSearch called in aggregation view");
  }

  isDimensions(field) {
    return (field.type !== 'attribute');
  }

  measureNames(fields, fn) {
    const measures= (fn==="count" || fn==="distinct") 
      ? fields : fields.filter((field) => field.type==='measure');
    const measureNames= measures.map(field => field.name).sort();
    return measureNames;
  }


  handleViewChange = () => {
    const view = this.state.view;
    const next = (view==="table") ? "bar-chart" : "table";
    this.setState({
      "view": next
    })
  }


  renderSelectColumn(columns, selected, label, parameter) {
    return(
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="select-column-label">{label}</InputLabel>
        <Select
          variant="standard"
          labelId="select-column-label"
          id="column-select"
          value={selected}
          onChange={(event) => this.selectColumn(parameter, event.target.value)}>
          <MenuItem value=""><em>None</em></MenuItem>
          { columns.map(name => <MenuItem key={name} value={name}>{name}</MenuItem>) }
        </Select> 
      </FormControl>
    );
  }

  renderSelectView() {
    return(
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="select-column-label">Visualization</InputLabel>
        <Select
          variant="standard"
          labelId="select-column-label"
          id="column-select"
          value={this.state.view}
          onChange={(event) => this.setState({"view": event.target.value})}>
          <MenuItem value="table">Table</MenuItem>
          <MenuItem value="bar-chart">Column Chart</MenuItem>
          <MenuItem value="line-chart">Line Chart</MenuItem>
        </Select> 
      </FormControl>
    );
  }


  renderView(md, data) {
    const message=this.state.message;
    if (md !== null && data !== null) {
      const view = this.state.view;
      console.log(view)
      switch (view) {
        case "table":
          return(
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column'}}>
              <PivotTable data={this.state.data} />
            </Paper>
          );
        case "bar-chart":
          return(
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column'}}>
              <PivotBarChart data={this.state.data} />
            </Paper>
          );
        case "line-chart":
          return(
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column'}}>
              <PivotLineChart data={this.state.data} />
            </Paper>
          );
          default:
          return null;
    
      }
    }
    if (message === null) {
      return(
        <Alert severity="info" color="primary">
          <AlertTitle>Info</AlertTitle>
          Select a measure, a row and a column to begin your analysis.
        </Alert>
      );  
    } else {
      return(
        <Alert severity="warning">
          <AlertTitle>Warning</AlertTitle>
          {message}
        </Alert>
      );  
    }
  }


  renderToolbar(stats, showColumns, filter) {
    const fields=stats.getFields().filter((field) => showColumns.has(field.name));
    const columns=stats.getFields();
    const fn = this.state.function;
    // Select columns - functions
    const measures=this.measureNames(fields, fn);
    const dimensions=fields.filter((field) =>this.isDimensions(field)).map(field => field.name).sort();

    return(
      <Toolbar sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 }
      }}>
      <Typography component="h2" variant="h6" color="primary"
        sx={{ flex: '1 1 100%' }}
        id="Focus">
        Pivot Table
      </Typography>
      <SelectAggregation 
        value={fn}
        onChange={this.selectFunction}
        />
      {this.renderSelectColumn(measures, this.state.measure, "Measure Field", "measure")}
      {this.renderSelectColumn(dimensions, this.state.row, "Row Field", "row")}
      {this.renderSelectColumn(dimensions, this.state.column, "Column Field", "column")}
      {this.renderSelectView()}
      <FilterButton mt={2}
        columns={columns}
        search={""}
        filter={filter}
        onUpdateFilter={this.props.onUpdateFilter}
        onAddSearch={this.handleAddSearch}/>
    </Toolbar>
    );
  }


  render() {
    const stats=this.props.stats;
    const showColumns=this.props.showColumns;
    const filter=this.props.filter;
    if (stats.valid()) {
      const md=stats.getMetadata();
      return (
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column'}}>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={this.state.waiting}>
            <CircularProgress color="inherit" />
          </Backdrop>
          {this.renderToolbar(stats, showColumns, filter)}
          {this.renderView(md, this.state.data)}
          <Snackbar open={this.state.error !== null}
            autoHideDuration={6000} 
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            variant="filled"  elevation={6}
            onClose={() => { this.setState({ "error": null});}}>
            <Alert severity="error" sx={{ width: '100%' }}
              onClose={() => { this.setState({ "error": null});}}
            >
              <AlertTitle>Error</AlertTitle>
              {this.state.error}
            </Alert>
          </Snackbar>
        </Paper>
      );
    }
  }
}