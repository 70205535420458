import * as React from 'react';
// Material
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';

// Application Components
import config from '../Configuration';
import requestCache from '../Cache';
import FilterButton from '../filter/FilterButton';
import DateAggregation from './DateAggregation';
import SelectAggregation from './SelectAggregation';


// Translate function name into field name - replicated in AggregationView
const functionMap = {
  "sum": "s",
  "avg": "a",
  "count": "c",
  "median": "m",
  "distinct": "d"
}


export default class TimeSeriesView extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      "tab": 0,
      "column": "",
      "function": "sum",
      "data": null,
      "waiting": false,
      "error": null
    }
  }

  componentDidMount() {
    console.log("Time Series View did mount");
    // Default date/time if there is only 1 date time column
    const stats=this.props.stats;  
    const filter=this.props.filter;
    const showColumns=this.props.showColumns;

    if (stats.valid()) {
      const fields=stats.getFields().filter((field) => showColumns.has(field.name));
      const dateTime=fields.filter((field) => field.type==='datetime');
      if (dateTime.length === 1) {
        const column=dateTime[0].name;
        console.log("defaulting dateTime: "+column);
        this.setState({"waiting": true})
        this.getTimeSeries(stats.getDatasetId(), stats.getDataset(), column, filter);    
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("Time Series did update");
    const stats=this.props.stats;  
    const column=this.state.column;
    const filter=this.props.filter;
    if (!filter.isEqual(prevProps.filter)) {
      if (column !== "") {
        this.setState({"waiting": true});
        this.getTimeSeries(stats.getDatasetId(), stats.getDataset(), column, filter);    
      }
    }
  }

  
  async getTimeSeries(datasetId, dataset, column, filter) {
    const conditions=filter.getActiveConditions();
    console.log("Calling get-time-series");
    const host=config.getAppCtx()
    const url=host+"/services/analytics/get-time-series";
    const data = {
      "dataset": dataset,
      "column": column,
      "query": {
        "datasetId": datasetId,
        "dataset": dataset,
        "filter": {
          "conditions": conditions
        }
      }
    }
    const response = await requestCache.fetch(url, config.getRequestSettingsJson(data));
    console.log("get-time-series returned");
    if (response !== null) {
      if (response.ok) {
        const message=response.data;
        if (message.status_code===0) {
          this.setState({
            "data": message.body,
            "column": column,
            "waiting": false,
          })
        } else {
          this.setState({"error": response.status_text, waiting: false});
        }
      } else if (response.status===401) {
        console.log("Authentication expired");
        this.setState({"waiting": false})
        this.props.onAuth();
      } else {
        this.setState({"error": "HTTP Response: "+response.status_text, waiting: false});
      }
    } else {
      this.setState({
        "error": "Can't connect to the server. Please check your internet connection",
         waiting: false});
    }
  }

  selectColumn = (event) => {
    const column=event.target.value;
    const filter=this.props.filter;
    const stats=this.props.stats; 
    if (column != null && column.length > 0) {
      this.setState({"waiting": true})
      this.getTimeSeries(stats.getDatasetId(), stats.getDataset(), column, filter);  
    } 
  };

  handleAddSearch() {
    // Dummmy should not happen.
    console.log("Error: addSearch called in aggregation view");
  }


  renderSelectColumn(names) {
    return(
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="select-column-label">Date Column</InputLabel>
        <Select
          variant="standard"
          labelId="select-column-label"
          id="column-select"
          value={this.state.column}
          onChange={this.selectColumn}
          label="Column"
        >
          <MenuItem value=""><em>None</em></MenuItem>
          { names.map(name => <MenuItem key={name} value={name}>{name}</MenuItem>) }
        </Select> 
      </FormControl>
    );
  }

  selectFunction = (event) => {
    let fn=event.target.value;
    this.setState({"function": fn});
  };

  changeTab = (event, tabIndex) => {
    this.setState({
      "tab": tabIndex
    })
  };


  renderView(md, showColumns, selectedColumn, data, func) {
    const filter = this.props.filter;
    const handleUpdate = this.props.onUpdateFilter

    if (data !== null) {
      const fnField=functionMap[func];
      const aggregations=data.aggregations.filter((agg) => showColumns.has(agg.measure));
      const fields=md.fields;
      let fieldArray=fields.filter(field => field.name===selectedColumn);
      if (fieldArray.length === 1) {
        return(
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column'}}>
          <DateAggregation
            selectedColumn={selectedColumn} 
            aggregations={aggregations} 
            fnField={fnField}
            filter={filter}
            onUpdateFilter={handleUpdate}/>
        </Box>
        );
      }
    }
    return(
      <Alert severity="info" color="primary">
        Select a column in the toolbar to begin your analysis
      </Alert>
    );
  }


  renderToolbar(fields, filter) {
    const dates=fields.filter((field) => field.type==='datetime')
      .map(field => field.name).sort();
    return(
      <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 }
      }}>
      <Typography component="h2" variant="h6" color="primary"
        sx={{ flex: '1 1 100%' }}
        id="Focus">
        Time Series
      </Typography>
      <SelectAggregation 
        value={this.state.function}
        onChange={this.selectFunction}/>
      {this.renderSelectColumn(dates)}
      <FilterButton mt={2}
        columns={fields}
        search={""}
        filter={filter}
        onUpdateFilter={this.props.onUpdateFilter}
        onAddSearch={this.handleAddSearch}/>
    </Toolbar>
    );
  }


  render() {
    const stats=this.props.stats;
    const filter=this.props.filter;
    const showColumns=this.props.showColumns;

    if (stats.valid()) {
      const md=stats.getMetadata();
      const fields=stats.getFields().filter((field) => showColumns.has(field.name));
      return (
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column'}}>
          {this.renderToolbar(fields, filter)}
          {this.renderView(md, showColumns, this.state.column, this.state.data, this.state.function)}
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={this.state.waiting}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Snackbar open={this.state.error !== null}
            autoHideDuration={6000} 
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            variant="filled"  elevation={6}
            onClose={() => { this.setState({ "error": null});}}>
            <Alert severity="error" sx={{ width: '100%' }}
              onClose={() => { this.setState({ "error": null});}}>
              <AlertTitle>Error</AlertTitle>
              {this.state.error}
            </Alert>
          </Snackbar>
        </Box>
      );
    }
  }
}