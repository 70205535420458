//import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';

// Icons
import StorageIcon from '@mui/icons-material/Storage';
import SearchIcon from '@mui/icons-material/Search';
import SummarizeIcon from '@mui/icons-material/Summarize';
import BarChartIcon from '@mui/icons-material/BarChart';
import InfoIcon from '@mui/icons-material/Info';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import FunctionsIcon from '@mui/icons-material/Functions';
import TimelineIcon from '@mui/icons-material/Timeline';
import PivotTableChartIcon from '@mui/icons-material/PivotTableChart';
import PaymentIcon from '@mui/icons-material/Payment';
//import ViewColumnIcon from '@mui/icons-material/ViewColumn';
//import FileUploadIcon from '@mui/icons-material/FileUpload';
//import MapIcon from '@mui/icons-material/Map';

//Applicaiton
//import Stats from './model/Stats';


function MyListItem(props) {
  // Properties
  const value = props.value;
  const label = props.label;
  const current = props.selected;
  const handler = props.handler;
  const disabled = props.disabled;
  // Calculated values
  const selected = (current === value);
  const theme = useTheme();
  const color = selected ? theme.palette.primary.main : null;
  return(
    <ListItemButton onClick={() => handler(value)} selected={selected} disabled={disabled}>
      <ListItemIcon sx={{color: color}}>
        {props.children}
      </ListItemIcon>
      <ListItemText primary={label} sx={{color: color}}/>
    </ListItemButton >
  );
}

export default function mainTabs(current, handler, stats, email) {
  return(
    <List>
      <MyListItem value="datasets" label="Datasets" selected={current} handler={handler} disabled={false}>
        <StorageIcon />
      </MyListItem>
      <MyListItem value="summary" label="Summary" selected={current} handler={handler} disabled={stats.invalid()}>
        <SummarizeIcon />
      </MyListItem>
      <MyListItem value="search" label="Browse" selected={current} handler={handler} disabled={stats.invalid()}>
        <SearchIcon />
      </MyListItem>
      <MyListItem value="histogram" label="Distribution" selected={current} handler={handler} disabled={stats.invalid()}>
        <BarChartIcon />
      </MyListItem>
      <MyListItem value="aggregation" label="Group Totals" selected={current} handler={handler} disabled={stats.invalid()}>
        <FunctionsIcon />
      </MyListItem>
      <MyListItem value="timeseries" label="Time Series" selected={current} handler={handler} disabled={stats.invalid()}>
        <TimelineIcon />
      </MyListItem>
      <MyListItem value="correlation" label="Correlation" selected={current} handler={handler} disabled={stats.invalid()}>
        <AccountTreeIcon />
      </MyListItem>
      <MyListItem value="pivot" label="Pivot Table" selected={current} handler={handler} disabled={stats.invalid()}>
        <PivotTableChartIcon />
      </MyListItem>
      <MyListItem value="purchase" label="Subscription" selected={current} handler={handler} disabled={email===null}>
        <PaymentIcon/>
      </MyListItem>
      <MyListItem value="about" label="About" selected={current} handler={handler} disabled={false}>
        <InfoIcon />
      </MyListItem>
    </List>
  );
}
