import * as React from 'react';
// mui components
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
// recharts
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
// Application
import config from '../Configuration';
import { scalePercent } from '../Utils';
import DateDetails from '../column-view/DateDetails';


function DateHistogram(props) {
  const clickHandler=props.onClick;
  const rows=props.rows;
  const maxY=scalePercent(props.data, rows);

  const data=props.data.map((e) => {
    const label = (props.width===1) ? e.start.toFixed(0) : "["+e.start+","+e.end+")";
    const formattedValue = (e.c*100.0/rows).toFixed(2);
    return {v: label, Percent: formattedValue } 
  });
  const label=props.column;
  return(
    <div style={{ width: '100%' }}>
      <Typography align="center"><b>{label}</b></Typography>
      <ResponsiveContainer width="100%" height={200}>
        <BarChart
          data={data}
          margin={{ top: 5,right: 30, left: 20, bottom: 5, }}
          isAnimationActive={false}
          onClick={() => clickHandler(label)}
        >
          <XAxis dataKey="v" type="category"  />
          <YAxis unit="%" domain={[0, maxY]} />
          <Tooltip />
          <Bar dataKey="Percent" fill={config.getChartColor()} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}


export default class DateHistogramView extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      "column": null,
    }
  }

  componentDidMount() {
    console.log("DateHistogramView did mount");
  }

  renderGridCells(categories, rowCount) {
    return categories.map( (column) => {
      const data=column.histogram.bins;
      const width=column.histogram.binWidth;
      //console.log(JSON.stringify(column));
      return(
        <Grid item xs={3} key={column.name}>
          <DateHistogram 
            column={column.name} 
            data={data} 
            rows={rowCount-column.missing} width={width} 
            onClick={(col) => this.setState({"column": col})}/>
        </Grid>
      );});
  }

  render() {
    const md=this.props.metadata;
    const fields=this.props.fields;
    const rowCount=md.rowCount;
    const selected=this.state.column;
    const filter=this.props.filter;
    const handleFilter=this.props.onUpdateFilter;

    if (selected===null) {
      let dates=fields.filter((row) => (row.type==='datetime'))
        .sort((a,b) => (a.name > b.name ? 1 :(a.name < b.name ? -1 :0)));
      return (
        <Grid container spacing={2}>
          {this.renderGridCells(dates, rowCount)}
        </Grid>  
      );
    } else {
      let categories=fields.filter((row) => (row.type==='datetime' && row.name===selected));
      if (categories.length === 1) {
        return (
          <DateDetails
            metadata={md}
            column={categories[0]}
            filter={filter}
            onUpdateFilter={handleFilter}  
            onClick={() => this.setState({"column": null})}/>
        );
      } else {
        console.log("Category not found: "+selected);
        return null;
      }
    }
  }
}