import * as React from 'react';
// mui components
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
// rechart
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
// Application components
import config from '../Configuration';
import Condition from '../model/Condition';
import { scaleDomain, magnitude, abbrevMag } from '../Utils';
import BackTitle from '../components/BackTitle';
import ChartDialog from '../components/ChartDialog';

function AggregationSmall(props) {
  const measure=props.measure;
  const dimension=props.column;
  const fnField=props.fnField;
  const width=props.width;
  const clickHandler=props.onClick;
  const n = Math.min(props.data.length, 20);
  const data=props.data.slice(0, n)
    .map(x => ({
      l: x.l,
      [measure]: x[fnField]}))
    .sort((a,b) => b.v-a.v);
  const values=data.map(f => f[measure]);
  const domain=scaleDomain(values);
  const extreme=Math.max(Math.abs(domain[0]), Math.abs(domain[1]))
  const digits = (extreme > 1000) ? 0 : 3;

  const options = {style: 'decimal', maximumFractionDigits: digits, useGrouping:true};
  const locale = 'en';
  const formatDouble = new Intl.NumberFormat(locale, options);
  const mag=magnitude(extreme);
  const unit=abbrevMag(mag);
  return(
    <ResponsiveContainer width="100%" height={200}>
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        onClick={() => clickHandler(dimension)}>
        <XAxis dataKey="l" type="category" tick={false} />
        <YAxis type="number" domain={domain} tickFormatter={(x) => (x/mag)+unit}  />
        <Tooltip 
          formatter={(value) => formatDouble.format(value)}
          labelFormatter={(start) => {
            // start is passed as String
            if (width === 1) {
              return dimension + " : "+ start;
            } else {
              const top=parseFloat(start)+width;
              return dimension + " : [" + start + "," + top + ")";
            }
          }}/>
        <Bar dataKey={measure} fill={config.getChartColor()} />
      </BarChart>
    </ResponsiveContainer>
  );
}


function AggregationBig(props) {
  const measure=props.measure;
  const dimension=props.column;
  const fnField=props.fnField;
  const width=props.width;
  const clickHandler=props.onClick;
  const data=props.data.map(x => ({
      l: x.l,
      [measure]: x[fnField]
    })).sort((a,b) => b.v-a.v);
  const values=data.map(f => f[measure]);
  const domain=scaleDomain(values);
  const extreme=Math.max(Math.abs(domain[0]), Math.abs(domain[1]))
  const digits = (extreme > 1000) ? 0 : 3;

  const options = {style: 'decimal', maximumFractionDigits: digits, useGrouping:true};
  const locale = 'en';
  const formatDouble = new Intl.NumberFormat(locale, options);
  const mag=magnitude(extreme);
  const unit=abbrevMag(mag);
  return(
    <ResponsiveContainer width="100%" height={500}>
      <BarChart
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5}}
        isAnimationActive={true}
        onClick={clickHandler}>
        <XAxis dataKey="l" type="category"  />
        <YAxis type="number" domain={domain} tickFormatter={(x) => (x/mag)+unit}  />
        <Tooltip 
          formatter={(value) => formatDouble.format(value)}
          labelFormatter={(start) => {
            // start is passed as String
            if (width === 1) {
              return dimension + " : " + start;
            } else {
              const top=parseFloat(start)+width;
              return dimension + " : [" + start + "," + top + ")";
            }
          }}/>
        <Bar dataKey={measure} fill={config.getChartColor()} />
      </BarChart>
    </ResponsiveContainer>
  );
}


export default class MeasureAggregation extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      "column": null,
      "graph": null,
    }
  }

  handleSelectColumn = (selected) => {
    this.setState({
      "column": selected
    });
  }

  handleOpenFilterMenu = (graph) => {
    if (this.props.filter) {
      this.setState({
        "graph": graph 
      });
    }
  };

  handleCloseFilterMenu = () => {
    this.setState({
      "graph": null
    });
  };

  handleCloseSingle = () => {
    this.setState({
      "graph": null,
      "column": null
    });
  };

  addHandler = (graph) => {
    const label=graph.activeLabel;
    const filter=this.props.filter.copy();
    const selected=this.state.column;

    const aggregations=this.props.aggregations;
    const matching=aggregations.filter(field => (field.groupBy===selected));
    if (matching.length >= 1) {
      const column=matching[0];
      const dataType = "Fractional";
      const width=column.binWidth;

      if (width === 1)  {
        // Categorical
        const labels = [ label ];
        const condition = new Condition(selected, "eq", labels);
        condition.setDataType(dataType);
        filter.addConditon(condition);
      } else {
        // Continous
        const stop_value = Number(label)+width;
        const start = [ label.toString() ];
        const stop = [ stop_value.toString() ];
        const condition1 = new Condition(selected, "ge", start);
        condition1.setDataType(dataType);
        filter.addConditon(condition1);
        const condition2 = new Condition(selected, "lt", stop);
        condition2.setDataType(dataType);
        filter.addConditon(condition2);
      }
      this.props.onUpdateFilter(filter);
      this.setState({
        "graph": null
      });
    }
  }

  renderGridCells(aggreations, fnField) {
    return aggreations.map( (column) => {
      const label = column.groupBy;
      return(
        <Grid item xs={3} key={column.measure+"-"+column.groupBy}>
          <div style={{ width: '100%' }}>
            <Typography align="center"><b>{label}</b></Typography>
            <AggregationSmall 
              measure={column.measure} 
              column={column.groupBy} 
              data={column.totals}
              fnField={fnField}
              width={column.binWidth}
              onClick={this.handleSelectColumn}/>
            </div>
        </Grid>
      );
    });
  }

  renderSingleChart(column, fnField, filter) {
    const measure = column.measure;
    const dimension = column.groupBy;
    return(
      <Grid container spacing={2}>
        <Grid item xs={9} key={column.measure+"-"+column.groupBy}>
          <BackTitle onClick={this.handleCloseSingle}>
            {measure} grouped by {dimension}
          </BackTitle>
          <AggregationBig 
            measure={measure} 
            column={dimension} 
            data={column.totals}
            fnField={fnField}
            width={column.binWidth}
            onClick={this.handleOpenFilterMenu}/>
          <ChartDialog
            graph={this.state.graph}
            filter={filter}
            onClose={this.handleCloseFilterMenu}
            onAddFilter={this.addHandler}/>
        </Grid>
        <Grid item xs={3} key={"Info"}>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <ListItem>
              <ListItemText primary={column.measure} secondary="Y-Axis" />
            </ListItem>
            <ListItem>
              <ListItemText primary={column.groupBy} secondary="X-Axis" />
            </ListItem>
          </List>
        </Grid>
      </Grid>    
    );
  }


  render() {
    console.log("Rendering Measure Aggreations");
    const fnField=this.props.fnField;
    const filter=this.props.filter;
    const aggregations=this.props.aggregations
      .filter((field) => (field.type === 'Fractional' || field.type === 'Integer'))
      .sort((a,b) => (a.groupBy > b.groupBy ? 1 :(a.groupBy < b.groupBy ? -1 :0)));

    const selected=this.state.column;
    if (selected===null) {
      return (
        <Grid container spacing={2}>
          {this.renderGridCells(aggregations, fnField)}
        </Grid>  
      );
    } else {
      const aggregations=this.props.aggregations;
      const matching=aggregations.filter(field => (field.groupBy===selected));
      if (matching.length >= 1) {  
        return this.renderSingleChart(matching[0], fnField, filter);
      } 
    }
  }
}