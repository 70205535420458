// Geo utilities

export function findLatitude(fields) {
  const match=fields.filter((field) => {
    const pattern=field.name.toLowerCase().trim();
    return (pattern==="latitude" || pattern==="lat")
  });
  if (match.length>=1) {
    return match[0].name;
  }
  return "";
}


export function findLongitude(fields) {
  const match=fields.filter((field) => {
    const pattern=field.name.toLowerCase().trim();
    return (pattern==="longitude" || pattern==="lng" || pattern==="lon")
  });
  if (match.length>=1) {
    return match[0].name;
  }
  return "";
}


export function getCenter(data) {
  if (data !== null) {
    console.log("Get Center called with data");
    const columns=data.cols;
    const latCol=findLatitude(columns);
    const lngCol=findLongitude(columns);
    const rows= data.rows.filter(row => (row[latCol] != null & row[lngCol] != null));
    return findCenter(rows, latCol, lngCol);  
  } else {
    return { lat: 50.7753, lng:  6.0839};
  }
}


function findCenter(rows, latCol, lngCol) {
  const location = getGeoLocation();
  if (location !== null) {
    console.log("Browser supports Geolocation");
    return location;
  }
  if ((latCol.length>0) && (lngCol.length > 0)) {
    const latitude=calcCenter(rows, latCol);
    const longitude=calcCenter(rows, lngCol);
    return { lat: latitude, lng:  longitude}
  } else {
    return { lat: 50.7753, lng:  6.0839}
  }
}


function calcCenter(rows, col) {
  const n=rows.length;
  let sum=0.0;
  let count=0;
  for (let i=0; i<n; i++) {
    let val=rows[i][col];
    if (val !== null) {
      sum += val;
      count++;
    }
  }
  return (count !==0) ? sum/count : 0;
}

function getGeoLocation() {
  let result=null;

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;
      result = { lat: lat, lng: lng };   
      console.log("GeoLocation: "+JSON.stringify(result));
    }, 
    (error) => {
      console.log("Error in geolocation:" + error.code);
    });
  } else {
    console.log("Browser does not support geolocation");
  } 
  return result;
}
