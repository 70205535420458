import * as React from 'react';
// Material
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';


// Component
import ColumnSearch from './ColumnSearch';
import SummaryView from './SummaryView';
import MeasureView from './MeasureView';
import CategoryView from './CategoryView';
import DateView from './DateView';
import AttributeView from './AttributeView';


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`column-type-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default class ColumnnView extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      "tab": 0,
      "search": ""
    }
  }

  componentDidMount() {
    // Open in summary tab
    //const stats=this.props.stats;
    //let tab= (stats.valid()) ? this.selectTab(stats) : 0;
    const tab=0;
    this.setState({
      "tab": tab,
      "search": ""
    })
  }
  
  handleChange = (event, newValue) => {
    this.setState({"tab": newValue});
  };


  handleSearch = (text) => {
    this.setState({
      "search": text,
    });
  }


  render() {
    const stats=this.props.stats;
    const showColumns=this.props.showColumns;
    const onShowChange=this.props.onShowChange;
    const search=this.state.search;
    if (stats.valid()) {
      console.log("Column View");
      const md=stats.getMetadata();
      // Group by type
      const measure_rows=stats.getMeasures(search).sort((a,b) => (a.name > b.name ? 1 :(a.name < b.name ? -1 :0)));
      const category_rows=stats.getCategories(search).sort((a,b) => (a.name > b.name ? 1 :(a.name < b.name ? -1 :0)));
      const datetime_rows=stats.getDateTimes(search).sort((a,b) => (a.name > b.name ? 1 :(a.name < b.name ? -1 :0)));
      const attribute_rows=stats.getAttributes(search).sort((a,b) => (a.name > b.name ? 1 :(a.name < b.name ? -1 :0)));
      
      const tab=this.state.tab;
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column'}}>
          <ColumnSearch 
            search={search}
            onChange={this.handleSearch}/>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tab} onChange={this.handleChange} aria-label="Column Function Tab">
              <Tab label="Summary"/>
              <Tab label={"Category ("+category_rows.length+")"}  disabled={category_rows.length===0} />
              <Tab label={"Date/Time ("+datetime_rows.length+")"} disabled={datetime_rows.length===0} />
              <Tab label={"Measure ("+measure_rows.length+")"} disabled={measure_rows.length===0} />
              <Tab label={"Attributes ("+attribute_rows.length+")"} disabled={attribute_rows.length===0} />
            </Tabs>
          </Box>
          <TabPanel value={tab} index={0}>
            <SummaryView stats={stats} />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <CategoryView 
              metadata={md} 
              rows={category_rows} 
              showColumns={showColumns}
              filter={this.props.filter}
              onUpdateFilter={this.props.onUpdateFilter}
              onShowChange={onShowChange}/>
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <DateView 
              metadata={md} 
              rows={datetime_rows}
              filter={this.props.filter}
              onUpdateFilter={this.props.onUpdateFilter}
              showColumns={showColumns}
              onShowChange={onShowChange}/>
          </TabPanel>
          <TabPanel value={tab} index={3}>
            <MeasureView 
              metadata={md} 
              rows={measure_rows}
              filter={this.props.filter}
              onUpdateFilter={this.props.onUpdateFilter}
              showColumns={showColumns}
              onShowChange={onShowChange}/>
          </TabPanel>
          <TabPanel value={tab} index={4}>
            <AttributeView rows={attribute_rows}
              showColumns={showColumns}
              onShowChange={onShowChange}/>
          </TabPanel>
       </Box>
      );
    }
  }
}