import * as React from 'react';
// Material
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
// Material Icons
import FileOpenIcon from '@mui/icons-material/FileOpen';


export default class UploadDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state={ 
      "error": "",
      "separator": "*",
      "locale": "*",
      "file": null
    }
  }

  separators() {
    return([
      {value: "*", label: "Default" },
      {value: "tab", label: "Tab" },
      {value: ",", label: "Comma" },
      {value: ";", label: "Semicolon" }
    ])
  }

  locals() {
    return([
      {value: "*", label: "Browser Locale" },
      {value: "en-US", label: "English / United States" },
      {value: "de-DE", label: "German / Germany" },
      {value: "jp-JP", label: "Japanese / Japan" }
    ])
  }

  handleSubmit= () => {
    console.log("Upload Dialog handleSubmit called")
    this.props.onSubmit(this.state.file, this.state.separator, this.state.locale);  
  };


  handleUpload = (event) => {
    const MAX_UPLOAD_SIZE=this.props.maxFileSize;
    const target=event.target;
    const file=target.files[0];
    console.log("File size: "+file.size);
    const error = (file.size >= MAX_UPLOAD_SIZE) ? "The file size exceeds the upload limit!" : null;
    this.setState({            
      "file": file,
      "error": error,
    })
  }

  handleCancel= () => {
    this.props.onSubmit(null);
  };

  handleSepSelect = (event) => {
    const value=event.target.value;
    console.log("Change Seperator: "+value);
      this.setState({
        "separator": value
      })  
  };

  handleLocaleSelect = (event) => {
    const value=event.target.value;
    console.log("Change Locale: "+value);
      this.setState({
        "locale": value
      })  
  };

  validate() {
    return this.state.error===null;
  }

  render() {
    const open=this.props.open;
    const file=this.state.file;
    const error=this.state.error;
    const fileName = (file != null) ? file.name : "Please select file";

    return (
      <Dialog open={open} onClose={this.handleCancel}>
        <DialogTitle>Upload CSV File</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ m: 1 }}>
            Save EXCEL worksheet as <b>CSV UTF-8</b> (Comma Delimitd File) (*.csv) before uploading.
            The file size is limited to <b>200 MB</b>.
          </DialogContentText>
          <Stack>
            <FormControl sx={{ m: 1 }} variant="standard">
              <InputLabel id="sep-label">Separator</InputLabel>
              <Select 
                labelId="sep-label"
                id="sep"
                label="sep"
                value={this.state.separator}
                variant="standard"
                sx={{ width: '15ch' }}
                onChange={this.handleSepSelect}>
                {this.separators().map((item) =>
                  <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                )}
              </Select>
            </FormControl>

            <FormControl sx={{ m: 1 }} variant="standard">
              <InputLabel id="locale_label">Data Locale</InputLabel>
              <Select 
                labelId="locale-label"
                id="locale"
                label="locale"
                value={this.state.locale}
                variant="standard"
                sx={{ width: '30ch' }}
                onChange={this.handleLocaleSelect}>
                {this.locals().map((item) =>
                  <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                )}
              </Select>
            </FormControl>

            <FormControl 
              sx={{ m: 1 }} variant="standard" error={error !== null}>
              <InputLabel htmlFor="file-field">CSV File</InputLabel>
              <Input
                id="file-field"
                type="text"
                readOnly
                value={fileName}
                autoFocus
                sx={{ width: '55ch' }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton color="primary" aria-label="upload picture" component="label">
                      <input 
                        hidden 
                        accept=".csv"
                        type="file"                     
                        onChange={this.handleUpload}/>
                      <FileOpenIcon />
                    </IconButton>
                  </InputAdornment>
              }/>
              <FormHelperText>{error}</FormHelperText>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel}>Cancel</Button>
          <Button onClick={this.handleSubmit} disabled={!this.validate()} >Upload</Button>
        </DialogActions>
      </Dialog>
    );  
  }
}