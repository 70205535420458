import * as React from 'react';
// mui components
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// Application components
import config from '../Configuration';
import requestCache from '../Cache';
import BackTitle from '../components/BackTitle';
import RowDetails from '../components/RowDetails';
import FilterButton from '../filter/FilterButton';
import { calcMax } from '../Utils';

// import Stats from '../model/Stats';

// rechart
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';


function ScatterPlot(props) {
  const xAxis=props.xAxis;
  const yAxis=props.yAxis;
  const data=props.data;
  const clickHandler=props.onClick;
  const col=config.getChartColor();
  const locale = 'en';
  // Format Y
  const maxY = calcMax(data);
  const digitsY= (maxY > 1000) ? 0 : 3;
  const optionsY = {style: 'decimal', maximumFractionDigits: digitsY};
  const formatY = new Intl.NumberFormat(locale, optionsY);
  // Format X
  const maxX = calcMax(data);
  const digitsX= (maxX > 1000) ? 0 : 3;
  const optionsX = {style: 'decimal', maximumFractionDigits: digitsX};
  const formatX = new Intl.NumberFormat(locale, optionsX);
  // Render chart
  return(
    <div>
      <ResponsiveContainer width="100%" height={500}>
        <ScatterChart
          isAnimationActive={true}
          margin={{ top: 20, right: 20, bottom: 10, left: 10 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="x" type="number"  name={xAxis} 
            domain={['dataMin', 'dataMax']}
            tickFormatter={(v) => formatX.format(v)}/>
          <YAxis dataKey="y" type="number" name={yAxis}
            domain={['dataMin', 'dataMax']}
            tickFormatter={(v) => formatY.format(v)}/>   
          <Tooltip cursor={{ strokeDasharray: '3 3' }} />
          <Scatter 
            name={xAxis} 
            data={data}
            fill={col} 
            onClick={(g, p) => { console.log(g); clickHandler(data[p])}}/>
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
}


export default class ScatterPlotView extends React.Component {

  // Constructor initialize state
  constructor(props) {
    super(props);
    const plot=this.props.plot;
    const plotData= (plot != null) ? plot.data : null;
    this.state={
      "plotData": plotData,
      "data": null,
      "detailView": false,
      "selected": null,
    }
  }
  
  componentDidMount() {
    console.log("ScatterPlot did mount");
    const stats=this.props.stats;
    const plot=this.props.plot;
    this.getPlot(stats.getDatasetId(), stats.getDataset(), plot.xAxis, plot.yAxis, this.props.filter);
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("ScatterplotView did update");
    const filter=this.props.filter;
    if (!filter.isEqual(prevProps.filter)) {
      console.log("ScatterplotView filter change");
      const stats=this.props.stats;
      const plot=this.props.plot;
      this.getPlot(stats.getDatasetId(), stats.getDataset(), plot.xAxis, plot.yAxis, this.props.filter);
    }
  }


  async getPlot(datasetId, dataset, measureX, measureY, filter) {
    console.log("Calling get-plots ");
    const host=config.getAppCtx();
    const url=host+"/services/analytics/get-plot";
    const data = {
      "datasetId": datasetId,
      "dataset": dataset,
      "measureX": measureX,
      "measureY": measureY,
      "filter": {
        "conditions": filter.getActiveConditions()
      }
    }
    const response = await requestCache.fetch(url, config.getRequestSettingsJson(data));
    console.log("get-plot returned");
    if (response !== null) {
      if (response.ok) {
        const message=response.data;
        if (message.status_code===0) {
          const body=message.body;
          const plotData = this.convertToPlot(body.data.rows, measureX, measureY)
          this.setState({
            "data": body.data,
            "plotData": plotData,
            "waiting": false,
          })
        } else {
          this.setState({"error": response.status_text, waiting: false});
        }
      } else if (response.status===401) {
        console.log("Authentication expired");
        this.setState({"waiting": false})
        this.props.onAuth();
      } else {
        this.setState({"error": "HTTP Response: "+response.status_text, waiting: false});
      }
    } else {
      this.setState({
        "error": "Can't connect to the server. Please check your internet connection",
         waiting: false});
    }
  }


  convertToPlot(rows,  measureX, measureY) {
    let plotData = rows.map( (row) => ({ "x": row[measureX], "y": row[measureY]}))
    return plotData
  }

  clickHandler = (values) => {
    console.log("Click")
    const x = values.x;
    const y = values.y;
    const plot=this.props.plot;
    const xAxis=plot.xAxis;
    const yAxis=plot.yAxis;
    const selected = this.state.selected;

    if ((selected !== null) && selected[xAxis]===x && selected[yAxis]===y) {
      this.setState({"selected": null })  
    } else {
      const rows = this.state.data.rows;
      const match = rows.filter((row) => {
        return (row[xAxis]===x && row[yAxis]===y)
      });
      console.log("Matching: "+match.length);
      if (match.length > 0) {
        this.setState({"selected": match[0] })  
      }    
    }
  }

  renderDetails() {
    const showColumns=this.props.showColumns;
    const data=this.state.data;
    const selected = this.state.selected;
    if (selected != null) {
      return(
        <div>
          <BackTitle onClick={() => this.setState({"selected": null})}>
            Selection
          </BackTitle>
          <Box sx={{ width: '100%', height: 500, overflow: 'scroll'}}>
            <RowDetails data={data} selection={selected} showColumns={showColumns}/>
          </Box>
        </div>
      );  
    } else {
      return null;
    }
  }


  renderInfo(stats, plot, plotData, filter) {
    const fields=stats.getFields();
    var locale = 'en';
    var options = {style: 'decimal', maximumFractionDigits: 3};
    var format3 = new Intl.NumberFormat(locale, options);
    const cor=stats.getCorrelation(plot.xAxis, plot.yAxis);
    return(
      <Box>
        <Toolbar>
          <Typography component="h2" variant="h6" color="primary"
            sx={{ flex: '1 1 100%' }}
            id="Focus">Info
          </Typography>
          <Box
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'top', width: "100%" }}>
          
            <FilterButton mt={0}
              columns={fields}
              search={""}
              filter={filter}
              onUpdateFilter={this.props.onUpdateFilter}
              onAddSearch={()=> console.log("addSearch")}/>
          </Box>
        </Toolbar>
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          <ListItem>
            <ListItemText primary={plot.yAxis} secondary="Y-Axis" />
          </ListItem>
          <ListItem>
            <ListItemText primary={plot.xAxis} secondary="X-Axis" />
          </ListItem>
          <ListItem>
            <ListItemText hidden={cor===null} primary={format3.format(cor)} secondary="Correlation" />
          </ListItem>
          <ListItem>
            <ListItemText primary={plotData.length} secondary="Display Sample Size" />
          </ListItem>
        </List>
      </Box>
    );
  }


  renderPane(stats, plot, plotData, filter) {
    const selected = this.state.selected;
    if (selected !== null) {
      console.log("Rendering details");
      return this.renderDetails();
    } else {
      console.log("Rendering Info");
      return this.renderInfo(stats, plot, plotData, filter);
    }
  }


  renderPage(stats, plot, plotData, filter, closeHandler) {
    console.log("renderPage called");
    const xAxis=plot.xAxis;
    const yAxis=plot.yAxis;
    const cor=stats.getCorrelation(plot.xAxis, plot.yAxis);
    return(
      <div>
        <Grid container spacing={2}>
          <Grid item xs={9} key={plot.measure+"-"+plot.groupBy}>
            <BackTitle onClick={closeHandler}>
              {yAxis} by {xAxis}
            </BackTitle>
            <ScatterPlot 
              xAxis={plot.xAxis} 
              yAxis={plot.yAxis}
              data={plotData}
              correlation={cor}
              onClick={this.clickHandler}
              onClose={closeHandler}/>
          </Grid>
          <Grid item xs={3} key={"Info"}>
            {this.renderPane(stats, plot, plotData, filter)}
          </Grid>
        </Grid>
      </div>
    );
  }


  render() {
    console.log("Rendering ScatterPlotView");
    const stats=this.props.stats;
    const plot=this.props.plot;
    const closeHandler=this.props.onClose;
    const filter = this.props.filter;
    //const showColumns=this.props.showColumns;

    if (stats.valid() && this.state.plotData !== null) {
      return this.renderPage(stats, plot, this.state.plotData, filter, closeHandler); 
    } else {
      console.log("ScatterplotView: md or data is null")
      return null;
    }
  }
}